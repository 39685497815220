import React, { useState, useEffect } from 'react';
import LeftSidebar from '../include/left_sidebar';
import PlacementSignuForm from '../../front/pages/forms_temp/PlacementSignuForm';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import axios from "axios";

const MyPlacementsPost = () => {

    var storedNames = JSON.parse(localStorage.getItem("userlogin"));
    const [allPlacementList, setAllPlacementList] = useState([]);

    const getALLPost =  () => {
        (async () => {
            try {
                const getdata = await axios.get(`https://jobchatbox.com/api/placement/training_and_placements/${storedNames.login_token}`);
               // console.log(getdata.data.data)
                if (getdata.data.status) {
                    
                    setAllPlacementList(getdata.data.data);
                }
            } catch (error) { console.log(error); }
        })();
    }
    
    useEffect(() => {
        getALLPost()
    }, []);



    /*status changes*/ 
    const handleStatusChange = (id,status) => {
        // Display a confirmation dialog
        const confirmed = window.confirm(`Are you sure you want to ${ status == 1 ? 'disable' : 'enable'} this item?`);
    
        if (confirmed) {
            (async () => {
                try {
                    const getdata = await axios.get(`https://jobchatbox.com/api/placement/training_and_status_action/${storedNames.login_token}/${id}/${status}`);
                    //console.log(getdata.data.data)
                    if (getdata.data.status) {
                        toast.success(getdata.data.message)
                        setTimeout(function () { 
                            getALLPost()
                         }, 2000);
                        //setAllPlacementList(getdata.data.data);
                    }
                } catch (error) { console.log(error); }
            })();
        } else {
          // User canceled the delete action
          console.log('Delete canceled');
        }
    };


    /*status changes*/ 
    const handleDelete = (id) => {
        // Display a confirmation dialog
        const confirmed = window.confirm(`Are you sure you want to delete this row?`);
    
        if (confirmed) {
            (async () => {
                try {
                    const getdata = await axios.get(`https://jobchatbox.com/api/placement/training_and_placement_delete/${storedNames.login_token}/${id}`);
                    //console.log(getdata.data.data)
                    if (getdata.data.status) {
                        toast.success(getdata.data.message)
                        setTimeout(function () { 
                            getALLPost()
                         }, 2000);
                        //setAllPlacementList(getdata.data.data);
                    }
                } catch (error) { console.log(error); }
            })();
        } else {
          // User canceled the delete action
          console.log('Delete canceled');
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="dashboardsec pt-70 pb-70">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-3">
                            <LeftSidebar />
                        </div>

                        <div className="col-lg-9">
                            <div className="notificationwraper">
                                <div class="head-border mb-30 d-flex justify-content-between align-items-center">
                                    <h5>All Placements</h5>


                                    <Link class="btn btn-default btn-small mr-10 mb-5" to={"/add_new_placement"}>
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        Add new
                                    </Link>
                                </div>
                                <div class="grayborderbox1 mb-30">
                                    <div class="box-padding1">
                                        <div class="row">
                                            {/* ----------- */}
                                    
                                            {
                                                (allPlacementList) &&
                                                allPlacementList.map((item, index) => (
                                                    <>

                                                        <div class="col-xl-12 col-12">
                                                            <div class="card-grid-2 hover-up">
                                                                <span class="flash"></span>
                                                                <div class="row">
                                                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                                                        <div class="card-grid-2-image-left pb-5">
                                                                            <div class="image-box">
                                                                                <img class="profile_pic" 
                                                                                src = {item.logo ? item.logo : 'https://jobchatbox.com/api/uploads/users/1691234086.png'}  
                                                                                alt="jcbapp" 
                                                                                /></div>
                                                                            <div class="right-info">
                                                                                <Link to={`/training-and-placement-details?id=${item.id}`}  class="name-job text-capitalize" >
                                                                                    {item.title}
                                                                                </Link>
                                                                                <span class="location-small">{item.location}</span>
                                                                                </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12 text-start text-md-end pr-60 col-md-12 col-sm-12">
                                                                        <div class="pl-10 mb-1 mt-1 float_left text-left overflowelips">
                                                                            {
                                                                                (item.courses_included_array.length > 0) && 
                                                                                item.courses_included_array.map((item,index) => (
                                                                                    <Link class="btn btn-grey-small mr-5 text-left text-capitalize1 mb-1" >{item}</Link>
                                                                                ))

                                                                            }
                                                                            {/* <a class="btn btn-grey-small mr-5 text-left text-capitalize mb-1" href="/jobs-search?skills_tag=es6">es6</a>
                                                                            <a class="btn btn-grey-small mr-5 text-left text-capitalize mb-1" href="/jobs-search?skills_tag=mongo">mongo</a> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="card-block-info">
                                                                    {/* <div class="mt-5 mb-10">
                                                                        <span class="card-briefcase">Location :  {item.location}</span> 
                                                                    </div> */}
                                                                    <div class="row">
                                                                        <div class="col-md-6 mb-5">
                                                                            <p class="font-sm color-text-paragraph"><strong>Job Status :</strong>{item.status == 1 ?  'Enabled' : 'Disabled' }</p>
                                                                        </div>
                                                                        <div class="col-md-6 mb-5">
                                                                            <p class="font-sm color-text-paragraph"><strong>Members Placed :</strong> {item.members_placed}</p>
                                                                        </div>
                                                                        <div class="col-md-6 mb-5">
                                                                            <p class="font-sm color-text-paragraph"><strong>Price :</strong> <del>Rs.{item.original_price}</del> Rs.{item.final_price}</p>
                                                                        </div>
                                                                        <div class="col-md-6 mb-5">
                                                                            <p class="font-sm color-text-paragraph"><strong>Category :</strong> {item.category_name} </p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="d-flex mt-20 myjobbtns flex-wrap work-experiencebtn">
                                                                            <Link onClick={() =>handleStatusChange(item.id ,item.status)} class="btn btn-default btn-small mr-10 mb-5" href="/my-job-post">
                                                                                <i class="fa fa-wrench" aria-hidden="true"></i>Make It {(item.status == 1) ? 'Disable' : 'Enable'} </Link>
                                                                            <Link to={`/training-and-placement-details?id=${item.id}`} class="btn btn-default btn-small mr-10 mb-5" href="/job-details?jobid=20" target="_blank">
                                                                                <i class="fa fa-file-text-o" aria-hidden="true"></i>View Detail</Link>
                                                                            <Link to={`/add_new_placement?id=${item.id}`}  class="btn btn-default btn-small mr-10 mb-5" href="/post-create?backpage=mypost&amp;jobid=20">
                                                                                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>Edit Job</Link>
                                                                            <Link onClick={() =>handleDelete(item.id)} class="btn btn-default btn-small mb-5" href="/my-job-post">
                                                                                <i class="fa fa-trash-o" aria-hidden="true"></i>Delete Job</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))
                                            }
                                            {/* ----------- */}
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};
export default MyPlacementsPost;