import React  , {useState ,useEffect} from 'react';
import SubscribeModule from './template_parts/SubscribeModule';
import PageTopHeaderSec from './template_parts/PageTopHeaderSec';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Modal, Button } from 'react-bootstrap' ;

import {useNavigate} from 'react-router-dom';
//import { useHistory } from 'react-router-dom';

import axios from "axios";
import { Link } from "react-router-dom";

const JobDetail = () => {
  const [getjobId, setSetJobId] = useState([]);    
  const [jobDetails, setJobDetails] = useState([]);    
  const [userDetails, setUserDetails] = useState([]); 
  const [similerJobs, setSimilerJobs] = useState([]); 
  const [similerJobsAppliedId, setSimilerJobsAppliedId] = useState([]); 
  const [isResumeModalShow, setIsResumeModalShow] = useState(false); 
  const [shareResumeJobId, setShareResumeJobId] = useState(false); 

  const navigate = useNavigate();
  const setJobDetailsFun = (jobid) => {
    window.scrollTo(0, 0)
    let userdata  = JSON.parse(localStorage.getItem("userloginSession"));
      let user_id  =  userdata ? userdata.id : 0  ;
       (async () => {
          try {
            const responceData = await axios.get("https://jobchatbox.com/api/home/api/job_details/null/"+jobid+"?user_id="+user_id);
              if(responceData.data.status == 1){
                //console.log(responceData.data.data)
                setUserDetails(responceData.data.data.user_details)
                setJobDetails(responceData.data.data.job_detail)
                setSimilerJobs(responceData.data.data.similer_jobs)
                setSimilerJobsAppliedId(responceData.data.data.applied_job_id)
              }
            } catch (error) {
              console.log(error);
            }
      })();
  }
  //const history = useHistory();
    useEffect(() => {
      window.scrollTo(0, 0);
      const queryParameters = new URLSearchParams(window.location.search)
      const jobid = queryParameters.get("jobid");
      setJobDetailsFun(jobid);
      //const url = `http://localhost:3000/job-details?jobid=${jobid}`;
     // history.push(url);
    }, [getjobId]);

    const getnewJobDetails = (jobid) =>{
      setJobDetailsFun(jobid);
      console.log(jobid);
      //alert(jobid)
    }

    //shareResumeToRec
    const closeResumeModal = () => {
      setIsResumeModalShow(false)
    };
    

    const shareResumeToRec = async (jobid) => {
        //alert(jobid)
        let userdata  = JSON.parse(localStorage.getItem("userloginSession"));
        //console.log(userdata)
        setShareResumeJobId(jobid);
        if(userdata && userdata.usertype == 3){
          const  API =  'https://jobchatbox.com/api/user/api/shared_resume_to_job';
          const formData = new FormData();
          formData.append('job_id', jobid );
          formData.append('login_token', userdata.login_token );
          formData.append('user_id', userdata.id );
          (async () => {
              try {
                  let res = await fetch(API , { method: "POST", body: formData ,});
                  let resJson = await res.json();
                  (resJson.status == 1) ? toast.success(resJson.message) : toast.warning(resJson.message) ;
                  if(resJson.status == 0){
                    setIsResumeModalShow(true)
                  } else if(resJson.status == 1) {
                    setSimilerJobsAppliedId([...similerJobsAppliedId, jobid]);
                  }
              } catch (err) { console.log(err);}
          })();
        } else if(userdata && userdata.usertype == 6){
           toast.warning('Recruiter Can\'t Share Resume to Jobs')
        } else {
          toast.warning('Please Login for share resume')
          setTimeout( function() {
            navigate('/login')
          } ,2000)
        }
    }  

    const postResumeSubmit = async (event) => {
      event.preventDefault();
      const formData = new FormData();
      var storedNames = JSON.parse(localStorage.getItem("userlogin"));
      
      formData.append('upload_resume', event.target.upload_resume.files[0]);
      formData.append('login_token', storedNames.login_token);
      formData.append('post_id', shareResumeJobId );
      formData.append('post_type', 'job');
      try {
          let res = await fetch("https://jobchatbox.com/api/user/api/post_upload_resume", {
              method: "POST",
              body: formData ,
          });
          let resJson = await res.json();
          if (res.status === 200) {
              if(resJson.status){ setSimilerJobsAppliedId([...similerJobsAppliedId, shareResumeJobId]);  toast.success('Resume Shared Successfully!') ;setIsResumeModalShow(false)}  
              else {   toast.warning(resJson.message) ;  }
          } else { }
      } catch (err) { console.log(err);}
  }

  const handleCheckJob = (jobid) => {
      return  similerJobsAppliedId.includes(jobid) ? true : false ;
  }


    return (

      
          <>
          <ToastContainer />
          {
            jobDetails ?
            <>
            <section className="section-box-2">
              <div className="container">
                <div className="banner-hero banner-image-single smallbanner"><img src="front/assets/imgs/thumb.png" alt="jcbapp" /></div>
                <div className="row mt-10">
                  <div className="col-lg-12 col-md-12 border-bottom ">
                    <h3>{jobDetails.job_title}</h3>
                    <div className="mt-0 mb-15">
                      <span className="card-briefcase">{jobDetails.jobtype}</span>
                      <span className="card-time">{jobDetails.createDate}</span></div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section-box mt-30">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                    <div className="job-overview">
                      <div className="d-flex align-items-center"><h5 className="border-bottom pb-15 mb-30 d-flex align-items-center w-100">Employment Information 
                      {/* <div className="d-flex align-items-center"><h5 className="border-bottom pb-15 mb-30 d-flex align-items-center w-100">Employment Information <div className="btn btn-apply-icon btn-apply btn-apply-big hover-up ml-auto" data-bs-toggle="modal" data-bs-target="#ModalApplyJobForm">Share Resume</div></h5></div>
                      */}
                      <button className="btn btn-apply-icon btn-apply btn-apply-big hover-up ml-auto"  disabled={handleCheckJob(jobDetails.id)}  onClick={(e) => shareResumeToRec(jobDetails.id) }  >{ handleCheckJob(jobDetails.id) ? 'Shared' : 'Share Resume' }</button></h5></div>
                      <div className="row">
                        <div className="col-md-6 d-flex">
                          <div className="sidebar-icon-item"><img src="front/assets/imgs/industry.svg" alt="jcbapp" /></div>
                          <div className="sidebar-text-info ml-10">
                            <span className="text-description industry-icon mb-10">Industry</span>
                            <strong className="small-heading">{jobDetails.job_industry}</strong>
                          </div>
                        </div>
                        <div className="col-md-6 d-flex mt-sm-15">
                          <div className="sidebar-icon-item"><img src="front/assets/imgs/job-level.svg" alt="jcbapp" /></div>
                          <div className="sidebar-text-info ml-10">
                            <span className="text-description joblevel-icon mb-10">Job level</span>
                            <strong className="small-heading">{jobDetails.seniority_level}</strong>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-10">
                        <div className="col-md-6 d-flex mt-sm-15">
                          <div className="sidebar-icon-item"><img src="front/assets/imgs/salary.svg" alt="jcbapp" /></div>
                          <div className="sidebar-text-info ml-10">
                            <span className="text-description salary-icon mb-10">Salary</span>
                            <strong className="small-heading">{jobDetails.salary_range}</strong>
                          </div>
                        </div>
                        <div className="col-md-6 d-flex">
                          <div className="sidebar-icon-item"><img src="front/assets/imgs/experience.svg" alt="jcbapp" /></div>
                          <div className="sidebar-text-info ml-10">
                            <span className="text-description experience-icon mb-10">No. of Vacancies</span>
                            <strong className="small-heading">{jobDetails.no_of_posts}</strong>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-10">
                        <div className="col-md-6 d-flex mt-sm-15">
                          <div className="sidebar-icon-item"><img src="front/assets/imgs/job-type.svg" alt="jcbapp" /></div>
                          <div className="sidebar-text-info ml-10">
                            <span className="text-description jobtype-icon mb-10">Job type</span>
                            <strong className="small-heading">{jobDetails.jobtype}</strong>
                          </div>
                        </div>
                        <div className="col-md-6 d-flex mt-sm-15">
                          <div className="sidebar-icon-item"><img src="front/assets/imgs/deadline.svg" alt="jcbapp" /></div>
                          <div className="sidebar-text-info ml-10">
                            <span className="text-description mb-10">Deadline</span>
                            <strong className="small-heading">{jobDetails.deadLineDate}</strong> 
                          </div>
                        </div>
                      </div>
                      <div className="row mt-10">
                        <div className="col-md-6 d-flex mt-sm-15">
                          <div className="sidebar-icon-item"><img src="front/assets/imgs/updated.svg" alt="jcbapp" /></div>
                          <div className="sidebar-text-info ml-10">
                            <span className="text-description jobtype-icon mb-10">Created</span>
                            <strong className="small-heading">{jobDetails.createDate}</strong>
                          </div>
                        </div>
                        <div className="col-md-6 d-flex mt-sm-15">
                          <div className="sidebar-icon-item"><img src="front/assets/imgs/updated.svg" alt="jcbapp" /></div>
                          <div className="sidebar-text-info ml-10">
                            <span className="text-description jobtype-icon mb-10">Updated</span>
                            <strong className="small-heading">{jobDetails.updateDate}</strong>
                          </div>
                        </div>
                        <div className="col-md-12 d-flex mt-sm-15">
                          <div className="sidebar-icon-item"><img src="front/assets/imgs/location.svg" alt="jcbapp" /></div>
                          <div className="sidebar-text-info ml-10">
                            <span className="text-description mb-10">Location</span> 
                            <strong className="small-heading">{jobDetails.prefered_state_location}</strong>
                          </div>
                        </div>

                        <div className="col-md-12 d-flex mt-sm-15">
                          <div className="sidebar-icon-item"><img src="front/assets/imgs/location.svg" alt="jcbapp" /></div>
                          <div className="sidebar-text-info ml-10">
                            <span className="text-description mb-10">Skills</span> 
                            <strong className="small-heading">{jobDetails.job_skills}</strong>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="content-single">
                      <h4>Job Description</h4>
                      <div dangerouslySetInnerHTML={{__html: jobDetails.description}} ></div>
                    </div>
                   
                    {/* <div className="single-apply-jobs">
                      <div className="row align-items-center">
                        
                        <div className="col-md-12 text-lg-end social-share">
                          <h6 className="color-text-paragraph-2 d-inline-block d-baseline mr-10">Share this</h6><a className="mr-5 d-inline-block d-middle" href="#"><img alt="jcbapp" src="front/assets/imgs/share-fb.svg" /></a><a className="mr-5 d-inline-block d-middle" href="#"><img alt="jcbapp" src="front/assets/imgs/share-tw.svg" /></a><a className="mr-5 d-inline-block d-middle" href="#"><img alt="jcbapp" src="front/assets/imgs/share-red.svg" /></a><a className="d-inline-block d-middle" href="#"><img alt="jcbapp" src="front/assets/imgs/share-whatsapp.svg" /></a>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12 pl-0 pl-lg-15 mt-lg-30">
                    <div className="sidebar-border">
                      <div className="sidebar-heading">
                        <div className="avatar-sidebar">
                          {
                            (typeof(userDetails.userdata) != 'undefined')  ?
                            <>
                              <figure><img alt="jcbapp" src={userDetails.userdata.profile_pic_url} /></figure>
                              <div className="sidebar-info">
                                <span className="sidebar-company">{userDetails.userdata.company_name}</span>
                                <span className="card-location">{userDetails.userdata.user_city+' '+userDetails.userdata.user_state}</span>
                                {/* <a className="link-underline mt-15" href="#">02 Open Jobs</a> */}
                              </div>
                            </>
                            : ''
                          }
                          
                        </div>
                      </div>
              
                    </div>
                    <div className="sidebar-border">
                      <h6 className="f-18">Similar jobs</h6>
                      <div className="sidebar-list-job">
                        <ul>
                        {
                            similerJobs.length ?
                            <>
                              {
                                  similerJobs.map((item,index) => (
                                    <li>
                                        <div className="card-list-4 hover-up" key={item.id} >
                                          <div className="image">
                                              <Link to={`/job-details?jobid=${item.id}`}>
                                                <img src={item.company_data.profile_pic_url} alt="jcbapp" className="similer_jobsImage" />
                                              </Link>
                                          </div>
                                          <div className="info-text">
                                              <h5 className="font-md font-bold color-brand-1 m-0">
                                                {/* <Link to={`/job-details?jobid=${item.id}`}>{item.job_title}</Link>  */}
                                                <Link onClick={(e) => getnewJobDetails(item.id)}>{item.job_title}</Link> 
                                                <div className="simijoblocationl">
                                                    {
                                                      item.location_id ?
                                                      <><i className="fa fa-map-marker mr-10" aria-hidden="true"></i><span className="location-small">{item.location_id}</span></>
                                                      : ''
                                                    }
                                                </div>
                                              </h5>
                                              <div className="mt-0"><span className="card-briefcase">{item.jobtype}</span><span className="card-time"><span>{item.timeago_duration}</span></span></div>
                                              <div className="mt-5">
                                                <div className="row">
                                                    <div className="col-612text-end">
                                                      <button className="btn btn-default mr-10 btn-small"  disabled={ (handleCheckJob(item.id)) ? handleCheckJob(item.id) :  similerJobsAppliedId.includes(item.id) } onClick={(e) => shareResumeToRec(item.id) } >{ handleCheckJob(item.id) ? 'Shared' : 'Share Resume' }</button>
                                                    </div>
                                                </div>
                                              </div>
                                          </div>
                                        </div>
                                    </li>
                                  ))
                              }
                            </>
                            : ''
                        }
                          
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            </>
            :''
          } 
      
          <SubscribeModule />
          <Modal show={isResumeModalShow} onHide={closeResumeModal}>
              <Modal.Header closeButton>
                  <Modal.Title>Resume Upload</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <>
                  <form onSubmit={postResumeSubmit} className="form-horizontal login-frm" name="resetForm" id="resetForm" method="POST" autocomplete="off" enctype="multipart/form-data" novalidate="novalidate">
                      <div className="col-md-12">
                          <div className="form-group login-grp">
                              <label>Upload Resume</label>
                              <input type="file" required={true} className="form-control login-ctrl" id="upload_resume" name="upload_resume" accept=".doc, .docx,.pdf"  />
                          </div>
                      </div>
                      <div className="col-md-12">
                          <div className="form-group mt-2">
                              <input type="hidden"  id="ru_post_id" name="ru_post_id" value="0" />
                              <input type="hidden"  id="ru_post_type" name="ru_post_type" value="0" />
                              <input type="hidden"  id="ru_user_id" name="ru_user_id" value="0" />
                              <button type="submit" className="btn btn-brand-1 hover-up w-100">Submit</button>
                          </div>
                      </div>

                          
                  </form>
                  </>
              </Modal.Body>
          </Modal>
          </>
    );

};
export default JobDetail;