import React,{useState,useEffect} from "react";
import axios from "axios";
import SubscribeModule from './template_parts/SubscribeModule';
import PageTopHeaderSec from './template_parts/PageTopHeaderSec';

const CookiesPolicy  = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const [allPostData, setAllPostData] = useState([]);
  const [baseUrl, setBaseurl] = useState('');
  useEffect(() => { 
    (async () => {
       try {
             const getdata = await axios.get("https://jobchatbox.com/api/cms/pages/key/cookies_policy");
             setAllPostData(getdata.data.data.pages_data);
             setBaseurl(getdata.data.data.base_url);
       } catch (error) { console.log(error); }
    })();
 }, []);

 
    return (
        <>
      <PageTopHeaderSec pageTitle='Cookies Policy' PageLink='terms-and-conditions'  />
       
         <>
         <section className="privacypolicysec">
            <div className="container">
         <div className="mb-20" dangerouslySetInnerHTML={{__html: allPostData.page_description}} ></div>
         </div>
         </section>
         {/* <section className="privacypolicysec">
            <div className="container">
                  <div className="">
                    <h4>Cookies Policy of www.careerhamster.com</h4>
                    <p>This website, www.careerhamster.com, is operated by Career Hamster.</p>
                    <p><strong>What are cookies?</strong></p>
                    <p>Cookies are a small text files that are stored in your web browser that allows Career Hamster or a third party to recognize you. Cookies can be used to collect, store and share bits of information about your activities across websites, including on Career Hamster website. This Cookies Policy was created by www.careerhamster.com</p>
                    <p><strong>Cookies might be used for the following purposes:</strong></p>
                    <ul>
                    <li>To enable certain functions</li>
                    <li>To provide analytics</li>
                    <li>To store your preferences</li>
                    <li>To enable ad delivery and behavioural advertising</li>
                    </ul>

                    <p>Career Hamster uses both session cookies and persistent cookies.</p>

                    <p>A session cookie is used to identify a particular visit to our Website. These cookies expire after a short time, or when you close your web browser after using our Website. We use these cookies to identify you during a single browsing session, such as when you log into our Website.</p>

                    <p>A persistent cookie will remain on your devices for a set period of time specified in the cookie. We use these cookies where we need to identify you over a longer period of time. For example, we would use a persistent cookie if you asked that we keep you signed in.</p>
                    <p><strong>How do third parties use cookies on the Career Hamster Website?</strong></p>
                    <p>Third party companies like analytics companies and ad networks generally use cookies to collect user information on an anonymous basis. They may use that information to build a profile of your activities on the Career Hamster Website and other websites that you've visited.</p>

                    <p><strong>What are your cookies options?</strong></p>

                    <p>If you don't like the idea of cookies or certain types of cookies, you can change your browser's settings to delete cookies that have already been set and to not accept new cookies. To learn more about how to do this, visit the help pages of your browser.</p>

                    <p>Please note, however, that if you delete cookies or do not accept them, you might not be able to use all of the features we offer, you may not be able to store your prefe</p>

                    <h4>Cookies Policy</h4>
                    <p>When you use and access the Service, we may place a number of cookies files your web browser.</p>

                    <p>We use cookies for the following purposes: to enable certain functions of the Service, to provide analytics, to store your preferences, to enable advertisements delivery, including behavioral advertising.</p>

                    <p>We use both session and persistent cookies on the Service and we use different types of cookies to run the Service:</p>

                    <p>Essential cookies. We may use essential cookies to authenticate users and prevent fraudulent use of user accounts.</p>

                    <h4>What are Cookies ?</h4>
                    <p>Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive.</p>
                    <p>Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.</p>
                  </div>
              </div>
          </section> */}
         </>

      <SubscribeModule />
        </>
    );
  };
export default CookiesPolicy;