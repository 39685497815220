import React,{useState,useEffect} from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';
import { Link } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from "axios";

const Resume = () => {

   var storedNames = JSON.parse(localStorage.getItem("userlogin"));
   const [resumeList, setResumeList] = useState([]);
   const [resumeFolders, setResumeFolders] = useState([]);
   const [resumeListId, setResumeListId] = useState([]); 


   const resumelistfun =  (search='')   => {
      (async () => {
         try {
           const responceData = await axios.get("https://jobchatbox.com/api/user/api/resume_list/"+storedNames.login_token+"?search="+search);
              if(responceData.data.status == 1){  setResumeList(responceData.data.data.data_value); setResumeFolders(responceData.data.data.resume_folders);  }
           } catch (error) {  console.log(error);  }
     })();
   }

   useEffect(() => {

      const queryParameters = new URLSearchParams(window.location.search)
       setResumeListId(queryParameters.get("id"));
       resumelistfun('')
   }, []);


   const moveResumeinFolder  = async (folderId,resumeId) => {
      const formData = new FormData();
      formData.append('logintoken', storedNames.login_token );
      formData.append('folder_id', folderId );
      formData.append('resume_id', resumeId );
      try {
         let res = await fetch("https://jobchatbox.com/api/user/api/move_resume_to_folder", {
            method: "POST",
            body: formData ,
         });
         let resJson = await res.json();
         if (res.status === 200) {
            if(resJson.status  == 1){
                toast.success(resJson.message);
            } else {
               toast.warning(resJson.message);
            }
         } 
      } catch (err) {
         console.log(err);
      }
   }


   /*---------------------------------------*/
   const [searchText, setSearchText] = useState('');
   const handleSearchChange = (event) => {
      setSearchText(event.target.value)
     // resumelistfun(event.target.value)
   };

   /*------------------------------------------*/
  
  const filteredData = resumeList.filter((item) => {
   return (
     item.candidate_details_firstname.toLowerCase().includes(searchText.toLowerCase()) ||
     item.candidate_details_email.toLowerCase().includes(searchText.toLowerCase()) ||
     item.candidate_details_job_title.toString().includes(searchText.toLowerCase())  ||
     item.candidate_details_mobile.toString().includes(searchText) 
   );
 });

   return (
      <>
         <ToastContainer />
          <div className="dashboardsec pt-30 pb-30">
           <div className="container">
            
            <div className="row">
              <div className="col-lg-3">
               <LeftSidebar />
              </div>
              <div className="col-lg-9">
                  <div className="section-box jobpost-sec">
                     <div className="container">
                        <div className="head-border mb-20 d-flex justify-content-between align-items-center">
                           <h5>Resumes</h5>

                           <input
                              type="text"
                              className='form-control width250'
                              value={searchText}
                              onChange={handleSearchChange}
                              placeholder="Search...."
                              />
                        </div>
                        <div className="table-responsive resume_table">
                        

                           <table className="table table-striped" id="employeeData">
                              <thead>
                                 <tr>
                                    <th >S.No</th>
                                    <th>Position Applied</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile Number</th>
                                    <th>Move To</th>
                                    <th>Posted Date</th>
                                    <th>Action</th>
                                 </tr>
                                 
                              </thead>
                              <tbody>

                                 {
                                    filteredData.length  ? 
                                    <>
                                       {
                                          filteredData.map((item,index) => (
                                             <>
                                             <tr  className={ (item.id == resumeListId) ? 'activeRow' : 'notActiveRow'}>
                                                <td><p>{index+1}</p></td>
                                                <td ><p  className='text-capitalize'>{item.candidate_details_job_title}</p></td>
                                                <td><p  className='text-capitalize'>{item.candidate_details_firstname}</p></td>
                                                <td><p> {item.candidate_details_email}</p></td>
                                                <td><p>{item.candidate_details_mobile}</p></td>
                                                <td>
                                                   <select onChange={(e) => moveResumeinFolder(e.target.value,item.id)} className="form-control login-ctrl moveToFolder">
                                                      <option value="">Select Folder</option>
                                                      
                                                      {
                                                         resumeFolders.length  ? 
                                                         <>
                                                            {
                                                               resumeFolders.map((item_res,index2) => (
                                                                  <option  selected={item.folder_id == item_res.id ? true : false} value={`${item_res.id}`}>{item_res.folder_name}</option>
                                                               ))
                                                            }
                                                         </>
                                                         : ''
                                                      }
                                                   </select>
                                                </td>
                                                <td><p>{item.created_date}</p></td>
                                                <td>
                                                   <p>
                                                      <a href={item.candidate_details_user_resume_url ? item.candidate_details_user_resume_url : '#'} download target="_blank"> 
                                                         <i className="fa fa-download mr-5" aria-hidden="true"></i>
                                                      </a>
                                                      <a href="#"><i className="fa fa-trash" aria-hidden="true"></i></a>
                                                   </p>
                                                </td>
                                             </tr>
                                             </>
                                          ))
                                       }
                                    </>
                                    : <></>
                                 }
                                 
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
          </div>
        </div>
      </>
    );
  };
export default Resume;