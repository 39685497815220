import React, { useEffect, useState } from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from "axios";
const Group_detail = () => {
   const [groupDataLength, setGroupDataLength] = useState(0);
   const [groupDataArr, setGroupDataArr] = useState([]);
   const [groupUserDataArr, setGroupUserDataArr] = useState([]);
   const [groupMembers, setGroupMembers] = useState([]);
   const [requestedMembers, setRequestedMembers] = useState([]);
   const [sharedPostDataArr, setSharedPostDataArr] = useState([]);
   const [totalgGroupMembers, setTotalgGroupMembers] = useState(1);
   const [isThisMygroup, setIsThisMygroup] = useState(true);
   const [isRequestedGroup, setIsRequestedGroup] = useState(true); 
   var storedNames = JSON.parse(localStorage.getItem("userlogin"));

   const groupdata = (groupid) => {
      (async () => {
         try {
            const group_data = await axios.get(`https://jobchatbox.com/api/user/api/group_details/${storedNames.login_token}/${groupid}`);
            if (group_data.data.status == 1) {
               setGroupDataArr(group_data.data.data.groupdata);
               setGroupUserDataArr(group_data.data.data.userdata);
               setGroupMembers(group_data.data.data.group_members);
               setRequestedMembers(group_data.data.data.newrequest);
               setTotalgGroupMembers(group_data.data.data.group_members_count);
               setIsRequestedGroup(group_data.data.data.check_request);
               setSharedPostDataArr(group_data.data.data.sharepostdata);
               setGroupDataLength(1);
               setIsThisMygroup(group_data.data.data.group_created == 'created' ? true : false);
               console.log(group_data.data.data)
            }
         } catch (error) { console.log(error); }
      })();
   }
   useEffect(() => {
      var groupID = window.location.search.split('id=')[1];
      groupdata(groupID);
      //group_details
   }, []);


   const acceptGroupRequest = (e) => {
      let notificationID  = e.target.id ;
      (async () => {
         try {
            const resdata = await axios.get("https://jobchatbox.com/api/user/api/accept_group_request/"+storedNames.login_token+"/"+notificationID);
            // console.log(resdata.data.data.notifications);
            if(resdata.data.status == 1){
               //setNewRequestGroups(resdata.data.data.results) ;
              // allGroupsListCall(searchValue)
              var groupID = window.location.search.split('id=')[1];
              groupdata(groupID);
              toast.info(resdata.data.message)
              } else  if(resdata.data.status == 2){
                toast.warning(resdata.data.message)
              } else {
               toast.error(resdata.data.message)
            }
         } catch (err) {
               console.log(err);
         }
      })();
   }

   const declineGroupRequest = (e) => {
      let notificationID  = e.target.id ;
      if(window.confirm("Want to decline group request?")){
        (async () => {
          try {
              const resdata = await axios.get("https://jobchatbox.com/api/user/api/decline_group_request/"+storedNames.login_token+"/"+notificationID);
              if(resdata.data.status == 1){
                 //setNewRequestGroups(resdata.data.data.results) ;
                //allGroupsListCall();
                //allGroupsListCall(searchValue)
                var groupID = window.location.search.split('id=')[1];
                groupdata(groupID)
                toast.info(resdata.data.message)
              } else  if(resdata.data.status == 2){
                toast.warning(resdata.data.message)
              } else {
               toast.error(resdata.data.message)
              }
          } catch (err) {
                console.log(err);
          }
        })();
      }
   }



   const checkGroupRequested = (val) => {
      return requestedMembers.some(item => val === item.groupid);
  }

   const request_to_join  = (e) => {
      (async () => {
          try {
          const states_list = await axios.get(`https://jobchatbox.com/api/user/api/save_group_request/${storedNames.login_token}/${e.target.id}?search=`);
          if(states_list.data.status == 1){
            (states_list.data.status  == 1 ) ? 
            toast.success(states_list.data.message) : 
            toast.warning(states_list.data.message) ;
            var groupID = window.location.search.split('id=')[1];
            groupdata(groupID);
          }
          } catch (error) {
          console.log(error);
          }
      })();
  }

   return (
      <>
       <ToastContainer />
         <div className="dashboardsec pt-30 pb-30">
            <div className="container">

               <div className="row">
                  <div className="col-lg-3">
                     <LeftSidebar />
                  </div>
                  <div className="col-lg-9">
                     <div className="row">
                        <div className="col-12">
                           <div className="head-border mb-30 d-flex justify-content-between align-items-center">
                              <h5>Groups Detail</h5>
                              <Link className="btn btn-default btn-small ml-auto" to="/groups">
                                 <i className="fa fa-arrow-left" aria-hidden="true"></i>Back To Groups
                              </Link>
                           </div>
                        </div>
                     </div>
                     <div className="connectionbockwrap">
                        <div className="row">
                           {
                              groupDataLength ?
                                 <>
                                    <div className="col-xl-12 mb-20">
                                       <div className="groupbox groupdetail">
                                          <div className="groupbox-top d-flex align-item-center">
                                             <div className="groupboximg"><img alt="jcbapp" src={groupDataArr.groupImage} /></div>
                                             <div className="groupname text-center">
                                                <h5 className='text-capitalize' >{groupDataArr.groupTitle}</h5>

                                             </div>
                                          </div>
                                          <div className="groupinfo">
                                             <h6 className="text-left text-capitalize">
                                                <Link className='text-capitalize' to={(groupUserDataArr.usertype == 3) ? `/jobseeker-details?candidate=${groupUserDataArr.unique_id}` : `/recruiter-details?candidate=${groupUserDataArr.unique_id}`}  >
                                                   {groupUserDataArr.firstname}
                                                </Link>
                                             </h6>
                                             <div className="mt-5 mb-20">
                                                <div className="text-left">
                                                   <Link className="btn btn-tags-sm mb-10 mr-5" to={(groupUserDataArr.usertype == 3) ? '/jobseeker' : '/recruiter'} >{(groupUserDataArr.usertype == 3) ? 'Jobseeker' : 'Recruiter'}</Link>
                                                </div>
                                             </div>
                                             <span className="font-sm color-text-mutted">{groupDataArr.groupDesc}</span>
                                             <div className="employers-info align-items-center justify-content-center mt-20">
                                                <div className="row align-items-end d-flex">
                                                   <div className="col-12">
                                                      <span className="d-flex align-items-center"><i className="fa fa-map-marker" aria-hidden="true"></i>
                                                         <span className="font-sm color-text-mutted">{groupUserDataArr.user_state} {groupUserDataArr.user_city}</span>
                                                         
                                                         { isThisMygroup && <Link to={`/group-conversation?groupid=${groupDataArr.id}`} className="ml-auto">
                                                            <span className="d-flex align-items-center">
                                                               <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                               <span className="font-sm color-text-mutted">Write A Conversations In Group</span>
                                                            </span>
                                                         </Link>
                                                         }
                                                      </span>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="groupmemberbtm d-flex">
                                       <div className="groupmemberbtmleft">
                                          <h5 >Group Members </h5>
                                          <span className="font-xs">{totalgGroupMembers} Members</span>
                                       </div>
                                       <div className="groupmemberbtmright ml-auto">
                                          {
                                             isThisMygroup ?
                                             <>
                                                <Link className="btn btn-default btn-small ml-auto" to={`/invite-member?id=${groupDataArr.id}`}>
                                                   <i className="fa fa-user-plus" aria-hidden="true"></i> Invite Members
                                                </Link>
                                             </>
                                             : 
                                             <>
                                                {
                                                   (isRequestedGroup  == 'requested') ?
                                                   <><div className="text-center mt-20"><button  onClick={request_to_join}  id={groupDataArr.id}  className="btn btn-success">Requested</button></div></> 
                                                   : 
                                                   <><div className="text-center mt-20"><button  onClick={request_to_join}  id={groupDataArr.id}  className="btn btn-default">Request to join</button></div></>
                                                }
                                             </>
                                          }
                                          
                                       </div>
                                    </div>

                                    <div className="d-flex flex-wrap mt-15" id="tab-job-1" role="tabpanel" aria-labelledby="tab-job-1">

                                       {
                                          groupMembers.length ?
                                             <>
                                                {
                                                   groupMembers.map((member, index) => (
                                                      <>
                                                         <div className="card-grid-2 connectionblock mb-5">
                                                            <div className="card-grid-2-image-left">
                                                               <div className="card-grid-2-image-rd">
                                                                  <Link className='text-capitalize' to={(member.usertype == 3) ? `/jobseeker-details?candidate=${member.unique_id}` : `/recruiter-details?candidate=${member.unique_id}`}  >
                                                                     <figure><img alt="jcbapp" src={member.profile_pic_url} /></figure>
                                                                  </Link>
                                                               </div>
                                                               <div className="card-profile">
                                                                  <h6 className="text-left">
                                                                     <Link className='text-capitalize' to={(member.usertype == 3) ? `/jobseeker-details?candidate=${member.unique_id}` : `/recruiter-details?candidate=${member.unique_id}`}  >
                                                                        {member.firstname}
                                                                     </Link>
                                                                  </h6>
                                                                  <div className="font-xs color-text-mutted mb-5">
                                                                     {
                                                                        member.usertype == 3 ? 'Jobseeker' : ((member.designation) ? member.designation : 'Not found')
                                                                     }
                                                                  </div>
                                                                  <div className="font-xs"><i className="fa fa-map-marker" aria-hidden="true"></i> {member.user_city + ' ' + member.user_state}</div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </>
                                                   ))
                                                }

                                             </>
                                             : ''
                                       }
                                    </div>
                                    
                                    { isThisMygroup && <>
                                    <div className="groupmemberbtm">
                                       <div className="groupmemberbtmleft">
                                          <h5>Requested Members </h5>
                                          <span className="font-xs">{requestedMembers.length ? `${requestedMembers.length} Members` : ''} </span>
                                       </div>
                                    </div>
                                    <div className="d-flex flex-wrap mt-15" id="tab-job-1" role="tabpanel" aria-labelledby="tab-job-1">
                                       
                                       {
                                          requestedMembers.length  ? 
                                          <>
                                             {
                                                requestedMembers.map((item,index)=> (
                                                   <>
                                                   <div className="card-grid-2 connectionblock mb-5">
                                                      <div className="card-grid-2-image-left">
                                                         <div className="card-grid-2-image-rd">
                                                            <a className="text-capitalize" href={`/jobseeker-details?candidate=${item.userdata.unique_id}`}>
                                                               <figure>
                                                                  <img alt="jcbapp" src={item.userdata.profile_pic_url} />
                                                               </figure>
                                                            </a>
                                                         </div>
                                                         <div className="card-profile">
                                                            <h6 className="text-left">
                                                               <a className="text-capitalize" href={`/jobseeker-details?candidate=${item.userdata.unique_id}`}>{item.userdata.firstname+' '+item.userdata.lastname}</a>
                                                            </h6>
                                                            <div className="font-xs color-text-mutted mb-5">{item.userdata.usertype ? 'Jobseeker' : 'Recruiter' }</div>
                                                            <div className="font-xs"><i className="fa fa-map-marker" aria-hidden="true"></i> Alpine Arizona</div>
                                                            <div className="d-flex requstedbtns mt-2">
                                                              
                                                               <button type="button" onClick={acceptGroupRequest} id={item.notificationData.id} className="btn btn-default btn-small mr-5">Accept</button>
                                                               <button type="button" onClick={declineGroupRequest} id={item.notificationData.id}   className="btn btn-default btn-small">Decline</button>
                                                            </div>
                                                         </div>  
                                                      </div>
                                                   </div>
                                                   </>
                                                ))
                                             }
                                          </>
                                          : 'There is no request for this group'
                                       }
                                       

                                    </div>
                                    </>
                                    }


                                    {
                                        <>
                                        <div className="groupmemberbtm mt-30">
                                          <div className="groupmemberbtmleft">
                                             <h5>Shared Post and Jobs </h5>
                                             <span className="font-xs">{sharedPostDataArr.length ? `${sharedPostDataArr.length} Jobs and Post` : ''} </span>
                                          </div>
                                       </div>
                                       <div className="d-flex flex-wrap mt-15" id="tab-job-1" role="tabpanel" aria-labelledby="tab-job-1">
                                       
                                       {
                                          sharedPostDataArr.length  ? 
                                          <>
                                             {
                                                sharedPostDataArr.map((item,index)=> (
                                                   <>
                                                   <div className="card-grid-2 connectionblock mb-5">
                                                      <div className="card-grid-2-image-left">
                                                         {
                                                            item.data.job_unique_id ? 
                                                            <>
                                                               <div className="card-profile">
                                                                  <h6 className="text-left">
                                                                     <a className="text-capitalize" href={`#`}>{item.data.job_title} Job</a>
                                                                  </h6>
                                                               </div>
                                                            </>
                                                            :
                                                            <>
                                                               <div className="card-profile">
                                                                  <h6 className="text-left">
                                                                     <a className="text-capitalize" href={`#`}>Post</a>
                                                                  </h6>
                                                               </div>
                                                            </>
                                                         }
                                                           
                                                      </div>
                                                   </div>
                                                   </>
                                                ))
                                             }
                                          </>
                                          : 'There are no shared post for this group'
                                          }
                                          </div>
                                        </>
                                    }
                                 </>
                                 : <>
                                    <h5 className='text-center'>Loading...</h5>
                                    </>
                           }
                        </div>
                     </div>
                  </div>

               </div>
            </div>
         </div>
      </>
   );
};
export default Group_detail;