import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
//class ThankYou extends React.Component {
const Training_and_placements = () => {

   const queryParams = new URLSearchParams(window.location.search);
   const thankyoupagetype = queryParams.get('type');
   // alert(thankyoupagetype);

   const [htmlContent, setHtmlContent] = useState(<><h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">ThankYou</h2><h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">You have sucsessfully subscribed!</h2>
      <p className="font-sm color-text-paragraph wow animate__animated animate__fadeInUp w-lg-50 mx-auto">We sent an email on your email please activate your account</p></>);

   useEffect(() => {
      if (thankyoupagetype == 'request_accepted') {
         setHtmlContent(<><h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">ThankYou</h2><p className="font-sm color-text-paragraph wow animate__animated animate__fadeInUp w-lg-50 mx-auto">Now you are a group member of group</p></>)
      }
   }, [])


   const loopcontent = [1, 2, 3, 4, 5, 6, 7, 8];
   const loopcontent2 = [1, 2, 3, 4];
   //request_accepted


   const [allPlacementList, setAllPlacementList] = useState([]);
   const [isLoading, setisLoading] = useState(false);

   const getALLPost = () => {
      setisLoading(true);
      (async () => {
         try {
            const getdata = await axios.get(`https://jobchatbox.com/api/placement/training_and_placements_groupby?searchKey=${searchKey}&locationSearch=${locationSearch}&categorySearch=${categorySearch}`);
            // /console.log(getdata.data.data)
            if (getdata.data.status) {
               setisLoading(false);
               setAllPlacementList(getdata.data.data);
            }
         } catch (error) { console.log(error); }
      })();
   }
   // useEffect(() => {
   //     getALLPost()
   // }, []);







   const [searchKey, setSearchKey] = useState('');
   const [locationSearch, setLocationSearch] = useState('');
   const [categorySearch, setCategorySearch] = useState('');

   const [allPlacementCategory, setAllPlacementCategory] = useState([]);
   const [allPlacementCities, setAllPlacementCities] = useState([]);

   useEffect(() => {
      getALLPost()
   }, [searchKey, locationSearch, categorySearch]);

   useEffect(() => {
      (async () => {
         try {
            const getdata = await axios.get(`https://jobchatbox.com/api/placement/categories_and_cities`);
            if (getdata.data.status) {

               setAllPlacementCategory(getdata.data.data.categories);
               setAllPlacementCities(getdata.data.data.cities);
            }
         } catch (error) { console.log(error); }
      })();
   }, []);
   //setSearchKey
   return (
      <>
         <section className="mt-4p1 top_strips">
            <div className="container">
               <div className="row pl-mrtp">
                  <div className="col-md-3"></div>
                  <div className="col-md-3">
                     <div className="search-container">
                        <input
                           className="form-control form_control custom_placement_search"
                           type="text"
                           placeholder="Search"
                           name="searchby"
                           onChange={(e) => setSearchKey(e.target.value)}
                        />
                     </div>
                  </div>
                  <div className="col-md-3">
                     <select onChange={(e) => setLocationSearch(e.target.value)} className="location_post form-control form_control" id="location_post_select" placeholder="Location By">
                        <option value="">Location By</option>
                        {
                           allPlacementCities.map((item, index) => (
                              <option value={item.city_name}>{item.city_name}</option>
                           ))
                        }
                     </select>
                  </div>
                  <div className="col-md-3">
                     <select onChange={(e) => setCategorySearch(e.target.value)} className="all_placements form-control form_control" id="all_placements_section" placeholder="Location By">
                        <option value="">All Placements</option>
                        {
                           allPlacementCategory.map((item, index) => (
                              <option value={item.id}>{item.category_name}</option>
                           ))
                        }
                     </select>
                  </div>
               </div>
            </div>
         </section>



         <section className="section-box pt-50 pb-50">
            <div className="post-loop-grid">

               <div class="container">

                  {
                     isLoading ?
                        <>
                           <div class="row" >
                              <div className="col-lg-12 padding5percent">
                                 <div class="loader"></div>
                              </div>
                           </div>
                        </>
                        :
                        (allPlacementList.length > 0) ?
                           allPlacementList.map((item, index) => (
                              <>
                                 <div class="row" >
                                    <div class=" col-md-12 mb-20">
                                       <h3 className="font18">{item.category_name}</h3>
                                    </div>
                                    {
                                       item.placements_data.map((placement, index2) => (
                                          <>

                                             <div class="col-xl-3 col-md-6 col-sm-12">
                                                <div class="card-grid-2 hover-up menblock text-center">
                                                   <img className="placement_show_img" alt="jcbapp" src={placement.logo} />
                                                   <div class="card-block-info border-top">
                                                      <h5 class="text-capitalize">{placement.title}</h5>
                                                      <h6><span ><s><span >₹{placement.original_price}</span></s>
                                                      </span> ₹{placement.final_price}</h6>
                                                      <div class="font-xs color-text-mutted overflowelips2">
                                                         {placement.courses_included}
                                                      </div>
                                                      <div class="font-xs color-text-mutted overflowelips2">
                                                         {placement.location}
                                                      </div>
                                                      <Link to={`/training-and-placement-details?id=${placement.id}`} class="btn btn-apply-now"  >Know More</Link>
                                                   </div>
                                                </div>
                                             </div>
                                          </>
                                       ))
                                    }
                                 </div>
                              </>
                           ))
                           :
                           <>
                              <div class="row" >
                                 <div className="col-md-12 text-center">
                                    <div className="alert alert-warning1" role="alert">
                                       <h4 className="alert-heading">No Results Found</h4>
                                       <p>Sorry, but there are no results matching your search criteria.</p>
                                       <p>Please try a different search query or check back later.</p>
                                    </div>
                                 </div>

                              </div>
                           </>
                  }

               </div>
            </div>




         </section>

         <section class="section-box mt-80 mb-30 mb-20 subscriptiosec1">
            <div class="container">
               <div class="box-newsletter box-newsletter-3">
                  <div class="row">
                     <div class="col-xl-12 text-center">
                        <div class="d-inline-block">
                           <h2 class="color-white mt-30">Read, Subscribe, Shine!</h2>
                           <p class="mt-10 font-lg color-white">Jobchatbox newsletter keeps you ahead of the job market.</p>
                           <div class="box-form-newsletter mt-30">
                              <form class="form-newsletter">
                                 <input class="input-newsletter" required="" name="email_id" type="email" placeholder="Enter your email here" />
                                 <button class="btn btn-default font-heading icon-send-letter">Subscribe</button></form>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
}
export default Training_and_placements;