import React,{useState,useEffect} from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

const Connections = () => {
   useEffect(() => {  window.scrollTo(0, 0) }, [])
   var storedNames = JSON.parse(localStorage.getItem("userlogin"));
   const [myConnectionArr, setMyConnectionArr] = useState([]);
   const [suggestedConnArr, setSuggestedConnArr] = useState([]);
   const [requestedConnArr, setRequestedConnArr] = useState([]);
   const [searchValue, setSearchValue] = useState('');

   const allConnectionListCall = (searchItem) => {
         (async () => {
         try {
         const resdata = await axios.get(`https://jobchatbox.com/api/user/api/connections/${storedNames.login_token}/1?search=${searchItem}`);
         if(resdata.data.status == 1){
               
            setMyConnectionArr(resdata.data.data.myconnectionsarr);
            setSuggestedConnArr(resdata.data.data.suggested_connection);
            setRequestedConnArr(resdata.data.data.requestconnections);
         }
         } catch (error) {
         console.log(error);
         }
      })();
   }
   useEffect(() => {
      allConnectionListCall(searchValue)
    },[]); 

    const save_connection = (e) => {
       let   connection_id= e.target.id;
       axios
      .get(`https://jobchatbox.com/api/user/api/save_connection/${storedNames.login_token}/${connection_id}?search=${searchValue}`)
      .then(resData =>{
         (resData.data.status  == 1 ) ? notify('success',resData.data.message) : notify('warning',resData.data.message) ;
         //console.log(resData.data)
         setMyConnectionArr(resData.data.data.myconnectionsarr);
         setSuggestedConnArr(resData.data.data.suggested_connection);
         setRequestedConnArr(resData.data.data.requestconnections);
      })
      .catch(error => console.log(error));
    }
   const accept_connection = (e) => { 
      let   connection_id= e.target.id;
      axios
     .get(`https://jobchatbox.com/api/user/api/accept_connection/${storedNames.login_token}/${connection_id}?search=${searchValue}`)
     .then(resData =>{
        (resData.data.status  == 1 ) ? notify('success',resData.data.message) : notify('warning',resData.data.message) ;

         if(resData.data.status  == 1 ){
           setMyConnectionArr(resData.data.data.myconnectionsarr);
           setSuggestedConnArr(resData.data.data.suggested_connection);
           setRequestedConnArr(resData.data.data.requestconnections);
         }
           
      })
     .catch(error => console.log(error));
   }
   
   const deny_connection = (e) => { 
      let   connection_id= e.target.id;
      deny_connection_fun(connection_id, '','');
      //    axios
      //   .get(`https://jobchatbox.com/api/user/api/deny_connection/${storedNames.login_token}/${connection_id}?search=${searchValue}`)
      //   .then(resData =>{
      //      (resData.data.status  == 1 ) ? notify('success',resData.data.message) : notify('warning',resData.data.message) ;
      //       if(resData.data.status  == 1 ){
      //          setMyConnectionArr(resData.data.data.myconnectionsarr);
      //          setSuggestedConnArr(resData.data.data.suggested_connection);
      //          setRequestedConnArr(resData.data.data.requestconnections);
      //       }
      //   })
      //   .catch(error => console.log(error));
   }

   const deny_connection_fun = (connection_id,searchtype='remove_from_my_connection',rowId=null) => { 
     // let   connection_id= e.target.id;
      if(window.confirm("Do you want to this action?")){
         axios
         .get(`https://jobchatbox.com/api/user/api/deny_connection/${storedNames.login_token}/${connection_id}?search=${searchValue}&searchtype=${searchtype}&rowid=${rowId}`)
         .then(resData =>{
            (resData.data.status  == 1 ) ? notify('success',resData.data.message) : notify('warning',resData.data.message) ;
            if(resData.data.status  == 1 ){
               setMyConnectionArr(resData.data.data.myconnectionsarr);
               setSuggestedConnArr(resData.data.data.suggested_connection);
               setRequestedConnArr(resData.data.data.requestconnections);
            }
         })
         .catch(error => console.log(error));
      }
   }

    

    const searchConnection = (e) => {
      setSearchValue(e.target.value);
      allConnectionListCall(e.target.value)
   }

   const notify = (status_type,message) => {
      switch (status_type) {
          case 'warning':
              toast.warning(message)  
          break;

          case 'success':
              toast.success(message)  
          break;

          case 'info':
              toast.info(message)  
          break;

          case 'error':
              toast.error(message)  
          break;
      }
   };

   const checkRequestedConn = (val) => {
      return requestedConnArr.some(item => val === item.userid);
  }
   
    return (
      <>
      <ToastContainer />
        <div className="dashboardsec pt-30 pb-30">
            <div className="container">
               <div className="row">
                  <div className="col-lg-3">
                        <LeftSidebar />
                  </div>
                  <div className="col-lg-9">
                     {/* <div className="box-filters-job">
                        <div className="row">
                           <div className="col-xl-6 col-lg-5"><span className="text-small text-showing">Showing <strong>41-60 </strong>of <strong>944 </strong>jobs</span></div>
                           <div className="col-xl-6 col-lg-7 text-lg-end mt-sm-15">
                              <div className="display-flex2">
                                 <div className="box-border mr-10">
                                    <span className="text-sortby">Show:</span>
                                    <div className="dropdown dropdown-sort">
                                       <button className="btn dropdown-toggle" id="dropdownSort" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static"><span>12</span><i className="fi-rr-angle-small-down"></i></button>
                                       <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownSort">
                                          <li><a className="dropdown-item active" href="#">10</a></li>
                                          <li><a className="dropdown-item" href="#">12</a></li>
                                          <li><a className="dropdown-item" href="#">20</a></li>
                                       </ul>
                                    </div>
                                 </div>
                                 <div className="box-border">
                                    <span className="text-sortby">Sort by:</span>
                                    <div className="dropdown dropdown-sort">
                                       <button className="btn dropdown-toggle" id="dropdownSort2" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static"><span>Location</span><i className="fi-rr-angle-small-down"></i></button>
                                       <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownSort2">
                                          <li><a className="dropdown-item active" href="#">Location</a></li>
                                          <li><a className="dropdown-item" href="#">Location</a></li>
                                          <li><a className="dropdown-item" href="#">Location</a></li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div> */}

                     <div className="head-border mb-30 d-flex justify-content-between align-items-center">
                        <h5>Connections </h5>
                     </div>

                     <div className="connectionbockwrap">
                        <div className="list-tabs mt-40">
                           <ul className="nav nav-tabs mb-20" role="tablist">
                              <li><a className="active" id="nav-tab-job-1" href="#tab-job-1" data-bs-toggle="tab" role="tab" aria-controls="tab-job-1" aria-selected="true">My Connections</a></li>
                              <li><a id="nav-tab-job-2" href="#tab-job-2" data-bs-toggle="tab" role="tab" aria-controls="tab-job-2" aria-selected="false">More Suggestions</a></li>
                              <li><a id="nav-tab-job-3" href="#tab-job-3" data-bs-toggle="tab" role="tab" aria-controls="tab-job-3" aria-selected="false">Requested Connections <span className="connectioncount">{requestedConnArr.length}</span></a></li>
                           </ul>
                        </div>
                        <div className="tab-content" id="myTabContent-1">
                           {/* myConnectionArr */}
                           <div className="tab-pane fade show active" id="tab-job-1" role="tabpanel" aria-labelledby="tab-job-1">
                           {
                                 myConnectionArr.length ? 
                                 <>
                                    {
                                       myConnectionArr.map((reqItem,index) => (
                                          <>
                                          <div className="card-grid-2 connectionblock mb-5">
                                             <div className="card-grid-2-image-left">
                                                <div className="card-grid-2-image-rd online">
                                                   <Link to="#">
                                                      <figure><img alt="jcbapp" src={reqItem.userdata.profile_pic} /></figure>
                                                   </Link>
                                                </div>
                                                <div className="card-profile">
                                                   <Link to={ reqItem.userdata.usertype == 3 ? `/jobseeker-details?candidate=${reqItem.userdata.unique_id}` : `/recruiter-details?candidate=${reqItem.userdata.unique_id}` } >
                                                      <h6 className='text-capitalize' > {reqItem.userdata.firstname}</h6>
                                                   </Link>
                                                   <div className="font-xs color-text-mutted mb-5">{reqItem.userdata.usertype == 3 ? 'Jobseeker' : 'Recruiter'}</div>
                                                   <div className="font-xs"><i className="fa fa-map-marker" aria-hidden="true"></i> {reqItem.userdata.user_state}, {reqItem.userdata.user_city}</div>
                                                   {/* <div className="font-xs mutualfriend">
                                                      <Link href="#">Robert fox</Link>, 
                                                      <Link href="#">steve jhone</Link> and 
                                                      <Link href="#">35 other mutual friends</Link>
                                                   </div> */}
                                                </div>
                                               
                                                <div className="connectionbtn">
                                                   <Link className="btn btn-tags-sm mb-10 mr-5" to={`/connection-chat?chatid=${reqItem.id}&connectionid=${reqItem.userdata.id}`}>Message</Link>
                                                   <Link onClick={(e) => deny_connection_fun(reqItem.userdata.id , 'remove_from_my_connection' ,reqItem.id)}  className="deleteconnection"><i className="fa fa-trash" aria-hidden="true"></i></Link>
                                                </div>
                                             </div>
                                          </div>
                                          </>
                                       ))
                                    }
                                 </>
                                 :<>There are no connections are found  .</>
                              }
                              
                              
                           </div>
                           {/* suggestedConnArr */}
                           <div className="tab-pane fade" id="tab-job-2" role="tabpanel" aria-labelledby="tab-job-2">
                              
                              
                              {
                                 suggestedConnArr.length ? 
                                 <>
                                    {
                                        suggestedConnArr.map((item,index) => (
                                          <>
                                          <div key={`suggest_${item.userdata.id}`} className="card-grid-2 connectionblock mb-5">
                                             <div className="card-grid-2-image-left">
                                                <div className="card-grid-2-image-rd online">
                                                   <a href="#">
                                                      <figure><img alt={item.userdata.firstname} src={item.userdata.profile_pic} /></figure>
                                                   </a>
                                                </div>
                                                <div className="card-profile">

                                                   <Link to={ item.userdata.usertype == 3 ? `/jobseeker-details?candidate=${item.userdata.unique_id}` : `/recruiter-details?candidate=${item.userdata.unique_id}` } >
                                                      <h6 className='text-capitalize' > {item.userdata.firstname}</h6>
                                                   </Link>

                                                   <div className="font-xs color-text-mutted mb-5">{item.userdata.usertype_title}</div>
                                                   <div className="font-xs"><i className="fa fa-map-marker" aria-hidden="true"></i> {item.userdata.user_state}</div>
                                                   {/* <div className="font-xs mutualfriend"><a href="#">Robert fox</a>, <a href="#">steve jhone</a> and <a href="#">35 other mutual friends</a></div> */}
                                                </div>
                                                <div className="connectionbtn">
                                                    {
                                                      item.userdata.connectedOrNot == 1 ? <><button id="10" className={`btn btn-tags-sm mb-10 mr-5 btn-${(item.userdata.pendinConnections.approve_type == 1) ? 'success' : 'danger'} `}>{item.userdata.pendinConnections.approve_type == 1 ? 'Connected' : 'Pending' } </button></> :
                                                      checkRequestedConn(item.userdata.id) ? <></> : <><button onClick={save_connection} id={item.userdata.id}  className="btn btn-tags-sm mb-10 mr-5" href="">Connect</button></>
                                                    }
                                                   
                                                </div>
                                             </div>
                                          </div>
                                          </>
                                        ))
                                    }
                                 </> 
                                 : <></>
                              }
                           </div>
                           {/* requestedConnArr */}
                           <div className="tab-pane fade" id="tab-job-3" role="tabpanel" aria-labelledby="tab-job-3">
                              {
                                 requestedConnArr.length ? 
                                 <>
                                    {
                                       requestedConnArr.map((reqItem,index) => (
                                          <>
                                          <div className="card-grid-2 connectionblock mb-5">
                                             <div className="card-grid-2-image-left">
                                                <div className="card-grid-2-image-rd online">
                                                   <Link to="#">
                                                      <figure><img alt="jcbapp" src={reqItem.userdata.profile_pic} /></figure>
                                                   </Link>
                                                </div>
                                                <div className="card-profile">
                                                   
                                                   <Link to={ reqItem.userdata.usertype == 3 ? `/jobseeker-details?candidate=${reqItem.userdata.unique_id}` : `/recruiter-details?candidate=${reqItem.userdata.unique_id}` } >
                                                      <h6 className='text-capitalize' > {reqItem.userdata.firstname}</h6>
                                                   </Link>
                                                   <div className="font-xs color-text-mutted mb-5">{ reqItem.userdata.usertype == 3 ? `Jobseeker` : `Recruiter` }</div>
                                                   <div className="font-xs"><i className="fa fa-map-marker" aria-hidden="true"></i> {reqItem.userdata.user_state}, {reqItem.userdata.user_city}</div>
                                                   <div className="font-xs mutualfriend">
                                                      {/* <Link href="#">Robert fox</Link>, 
                                                      <Link href="#">steve jhone</Link> and 
                                                      <Link href="#">35 other mutual friends</Link> */}
                                                   </div>
                                                </div>
                                                <div className="connectionbtn">  
                                                   <Link className="btn btn-tags-sm mb-10 mr-5" onClick={accept_connection} id={reqItem.userdata.id} >Accept</Link>
                                                   <Link className="btn btn-tags-sm mb-10 mr-5" onClick={deny_connection} id={reqItem.userdata.id} >Deny</Link>
                                                </div>
                                             </div>
                                          </div>
                                          </>
                                       ))
                                    }
                                 </>
                                 :<>No connections are requested</>
                              }
                              

                           </div>
                        </div>


                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
    );
  };
export default Connections;