import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route ,  Link , useParams  }  from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import './App.css';

import Header from './front/components/NavBar';
import FrontFooter from './front/components/Footer';


import Home from './front/pages';
import JobSeeker from './front/pages/jobseeker';
import InfiniteScroll from './front/pages/infinite_scroll';
import JobSeekerSearchPage from './front/pages/jobseeker-search';
import JobSeekerDetails from './front/pages/template_parts/jobseeker_details';
import Jobs from './front/pages/Jobs';
import JobDetail from './front/pages/job-detail';
import Recruiters from './front/pages/Recruiters';
import RecruiterDetails from './front/pages/template_parts/recruiter_details';
import ThankYou from './front/pages/ThankYou';
import Pagenot_found from './front/pages/404';


//footer section 
import AboutUs from './front/pages/AboutUs';
import Blogs from './front/pages/Blogs';
import BlogDetail from './front/pages/BlogDetail';
import ContactUs from './front/pages/ContactUs';
import Faq from './front/pages/Faq';
import PrivacyPolicy from './front/pages/PrivacyPolicy';
import RefundPolicy from './front/pages/RefundPolicy';
import Disclaimer from './front/pages/Disclaimer';
import TermsAndConditions from './front/pages/TermsAndConditions';
import EndUserLicenseAgreementEulaOfCareerHamster from './front/pages/end-user-license-agreement-eula-of-jobchatbox';

import CookiesPolicy from './front/pages/CookiesPolicy';
import TermsOfSerive from './front/pages/TermsOfSerive';


import Login from './front/pages/login';
import PlacementLogin from './front/pages/PlacementLogin';
import Forgot_Password from './front/pages/forms_temp/forgot_password';
import Reset_Password from './front/pages/forms_temp/reset_password';
import RegistrationFrom from './front/pages/register_form';
import Placement_signup from './front/pages/Placement_signup';
import PostLoadingContent from './front/pages/loadingteamp';

//Jobseeker
import JobSeekerDashboard from './backend/common/dashboard';
import My_timeline from './backend/common/My_timeline';
import PostCreateForm from './backend/common/post_create_form';
import PostCreateCommonForm from './backend/common/post_create_common_form';
import Notifications from './backend/common/Notifications';
import Connections from './backend/jobseeker/Connections';
import Group_members from './backend/common/Group-member';
import Profile_performance from './backend/jobseeker/Profile_performance';
import Groups from './backend/jobseeker/Groups';
import Group_conversation from './backend/jobseeker/Group_conversation';
import Connection_conversation from './backend/jobseeker/Connection_conversation';
import Group_detail from './backend/jobseeker/Group_detail';
import Invite_member from './backend/jobseeker/Invite_member';

import ResumeUpdate from './backend/jobseeker/ResumeUpdate';
import AppliedJobs from './backend/jobseeker/AppliedJobs';
import View_applied_profile from './backend/recruiter/View_applied_profile';
import FavoriteJobs from './backend/jobseeker/FavoriteJobs';



import Profile_edit from './backend/common/Profile_edit';
import Sector_and_skills from './backend/recruiter/Sector_and_skills';
import Sector_actions from './backend/recruiter/Sector_actions';
import My_work_experience from './backend/recruiter/My_work_experience';
import Add_work_experience from './backend/recruiter/Add_work_experience';
import My_jobs_post from './backend/recruiter/My_jobs_post';
import Add_post_jobs from './backend/recruiter/Add_post_jobs';
import Resume_list from './backend/recruiter/Resume_list';
import Selected_resumes from './backend/recruiter/Selected_resumes';
import Applied_jobs from './backend/recruiter/Applied_jobs';
import Email_templates from './backend/recruiter/Email_templates';
import Job_Seeker_Interview from './backend/recruiter/Job-seeker-interview';
import Downloaded_resumes from './backend/recruiter/Downloaded_resumes';
import Create_recruiter from './backend/recruiter/Create_recruiter';
import Resume_folder from './backend/recruiter/Resume_folder';
import Resume_folder_list from './backend/recruiter/Resume_folder_list';
import TableWithPaginationAndExport from './backend/recruiter/tsbleDemo';

//add-post-jobs Add_post_jobs
//check this login or not  
import PrivateComponent from './front/components/PrivateComponent'; 
import NotFound_404 from './front/components/NotFound_404'; 
import Training_and_placements from './front/pages/Training_and_placements'; 
import Training_and_placements_details from './front/pages/Training_and_placements_details'; 



//placemnt 
import PlcamentMyProfile from './backend/placement/MyProfile';
import MyPlacementsPost from './backend/placement/MyPlacementsPost';
import Add_new_placement from './backend/placement/Add_new_placement';
import Addvertisement from './backend/placement/Addvertisement';

window.appurl = "https://jobchatbox.com/api/";
var storedNames = JSON.parse(localStorage.getItem("userlogin"));

function App() {


  useEffect(() => {
    let timeoutId;

    const clearLocalStorage = () => {
      localStorage.clear();
      // Additional logic for clearing specific keys can be added here
    };

    const resetTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(clearLocalStorage, 30 * 60 * 1000); // 30 minutes in milliseconds
    };

    const handleUserActivity = () => {
      resetTimer();
      // Additional logic for handling user activity can be added here
    };

    resetTimer();

    // Attach event listeners to appropriate elements in your application
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    // Clean up the event listeners when the component is unmounted
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, []);

  return (
    <>
      <Router>
          <Header />
          <main className="main">
            <Routes >
                <Route path='*' element={<NotFound_404 />} />
                <Route exact path='/'  element={<Home />} />
                <Route path='/loading' element={<PostLoadingContent/>} />

                <Route path='/jobseeker' element={<JobSeeker/>} />
                <Route path='/infinitescroll' element={<InfiniteScroll/>} />
                <Route path='/jobs-search'   element={<JobSeekerSearchPage search_type='jobseeker'  />} />
                <Route path='/jobseeker-details' element={<JobSeekerDetails/>} />
                <Route path='/recruiter-details' element={<RecruiterDetails/>} />
                <Route path='/jobs' element={<Jobs  />} />
                <Route path='/job-details' element={<JobDetail  />} />
                <Route path='/recruiters' element={<Recruiters/>} />
                <Route path='/recruiter-search'   element={<JobSeekerSearchPage search_type='recruiter'    />} />
                <Route path='/thank-you' element={<ThankYou/>} />
                <Route path='/404' element={<Pagenot_found/>} />
                


                
                <Route path='/aboutus' element={<AboutUs/>} />
                <Route path='/blogs' element={<Blogs/>} />
                <Route path='/blog-detail' element={<BlogDetail/>} />
                <Route path='/contactus' element={<ContactUs/>} />
                <Route path='/faq' element={<Faq/>} />
                <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
                <Route path='/refund-policy' element={<RefundPolicy/>} />
                <Route path='/disclaimer' element={<Disclaimer/>} />
                <Route path='/terms-and-conditions' element={<TermsAndConditions/>} />
                <Route path='/end-user-license-agreement-eula-of-jobchatbox' element={<EndUserLicenseAgreementEulaOfCareerHamster/>} />
                <Route path='/cookies-policy' element={<CookiesPolicy/>} />
                <Route path='/terms-of-service' element={<TermsOfSerive/>} /> 
                
                
                <Route path='/login' element={<Login />} />
                <Route path='/placement-login' element={<PlacementLogin />} />
                <Route path='/forgot-password' element={<Forgot_Password />} />
                <Route path='/reset-password' element={<Reset_Password />} />
                <Route path='/register' element={<RegistrationFrom />} />
                <Route path='/placement-signup' element={<Placement_signup />} />
                <Route path='/tbldemo' element={<TableWithPaginationAndExport />} />
                <Route path='/training-and-placements' element={<Training_and_placements />} />
                <Route path='/training-and-placement-details' element={<Training_and_placements_details />} />
                <Route path='/placement-signup' element={<Training_and_placements_details />} />


                {/*  <Route path='/view-applied-profile/:id' element={<View_applied_profile/>} />  */}
                {/* <Routes>
                  <Route exact path="/:id" element={<About />} />
                </Routes> */}


                <Route element={<PrivateComponent />}>
                  <Route path='/dashboard' element={<JobSeekerDashboard />} /> 
                  <Route path='/mytimeline' element={<My_timeline />} /> 
                  <Route path='/jobseeker-post-action' element={<PostCreateForm />} /> 
                  <Route path='/profile-performance' element={<Profile_performance />} /> 
                  <Route path='/connections' element={<Connections/>} />
                  <Route path='/group-members' element={<Group_members/>} />
                  <Route path='/notifications' element={<Notifications/>} /> 

                  <Route path='/groups' element={<Groups/>} /> 
                  <Route path='/group-conversation' element={<Group_conversation/>} />
                  <Route path='/connection-chat' element={<Connection_conversation/>} />
                  <Route path='/group-details' element={<Group_detail/>} /> 
                  <Route path='/invite-member' element={<Invite_member/>} /> 
                 

                  <Route path='/resume' element={<ResumeUpdate/>} /> 
                  <Route path='/applied-jobs' element={<AppliedJobs/>} /> 
                  
                  <Route path='/favorite-jobs' element={<FavoriteJobs/>} />
                  <Route path='/post-create' element={<PostCreateCommonForm/>} />
                 
                  {/* <Route exact  path='/view-applied-profile/:userid' element={<View_applied_profile/>} /> */}
                  <Route exact  path='/view-applied-profile' element={<View_applied_profile/>} />

                 

                  <Route path='/profile-edit' element={<Profile_edit/>} /> 
                  <Route path='/sectors' element={<Sector_and_skills/>} /> 
                  <Route path='/add-sectors' element={<Sector_actions/>} /> 
                  <Route path='/work-experience' element={<My_work_experience/>} /> 
                  <Route path='/add-work-experience' element={<Add_work_experience/>} /> 
                  <Route path='/my-job-post' element={<My_jobs_post/>} /> 
                  <Route path='/add-post-jobs' element={<Add_post_jobs/>} />  
                  <Route path='/resume-list' element={<Resume_list/>} /> 
                  <Route path='/selected-resumes' element={<Selected_resumes/>} />
                  <Route path='/appliedjobs' element={<Applied_jobs/>} />
                  <Route path='/email-templates' element={<Email_templates/>} />
                  <Route path='/job-seeker-interview' element={<Job_Seeker_Interview/>} />
                  



                  <Route path='/downloaded-resumes' element={<Downloaded_resumes/>} />
                  <Route path='/create-recruiter' element={<Create_recruiter/>} />
                  <Route path='/resume-folder' element={<Resume_folder/>} /> 
                  <Route path='/resume-folder-list' element={<Resume_folder_list/>} /> 



                  {/* placemnt role */}
                  <Route path='/myprofile-edit' element={<PlcamentMyProfile/>} /> 
                  <Route path='/my-placements' element={<MyPlacementsPost/>} /> 
                  <Route path='/add_new_placement' element={<Add_new_placement/>} /> 
                  <Route path='/addvertisements' element={<Addvertisement/>} /> 


                </Route>
                

                

            </Routes>
          </main>
          <FrontFooter />
      </Router>
    </>
  );
}

export default App;