import React,{useState,useEffect, useRef  } from 'react';
import Select from "react-select";

import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'


import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';


import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

import {useNavigate} from 'react-router-dom';

//notification
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";


import { EditorState,ContentState , convertToRaw , convertFromHTML  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
 

import JobseekerSignup from '../../front/pages/forms_temp/jobseeker_signup';
import RecruiterSignup from '../../front/pages/forms_temp/recruiter_signup';

//import JobseekerSignup from '../../front/pages/forms_temp';



const Profile_edit = () => {

    useEffect(() => {  window.scrollTo(0, 0) }, [])
    const [validated, setValidated] = useState(false);
    const [isUpdatingForm, setIsUpdatingForm] = useState(false);
    const [profileUrl, setProfileUrl] = useState('');
    const [profileBgImageUrl, setProfileBgImageUrl] = useState('');
    const [allStateList, setAllStateList] = useState([]);
    const [allCityList, setAllCityList] = useState([]);
    const [formValue, setFormValue] = useState(JSON.parse(localStorage.getItem("userloginSession")));
    const [selectedOptions, setSelectedOptions] = useState();
    const [jobsSkillKeyArray, setjobsSkillKeyArray] = useState([]);
    const [industryTypeListArray, setIndustryTypeListArray] = useState([]);
    const [recStatusListDataArray, setRecStatusListDataArray] = useState([]);

    
    const navigate = useNavigate();
    
    const profile_pic =  (formValue.profile_pic) ? `https://jobchatbox.com/api/uploads/users/${formValue.profile_pic}` : 'front/assets/imgs/user1.png';
    const [profilePicUrl, setProfilePicUrl] = useState(profile_pic);
    const [brandDetailsArr, setBrandDetailsArr] = useState([]);
    const [profileDescription, setProfileDescription] = useState([]);
    const [editorState, setEditorState] = useState(() => 
                    EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                        convertFromHTML(formValue.short_desc)
                        )
                    )
    );

    const [cityListArray, setCityListArray] = useState([]);
    const [stateListArray, setStateListArray] = useState([]);
    const [profilePhotoerror, setProfilePhotoerror] = useState(false);
    const [required, setRequired] = useState(true);
    const [displayNone, setDisplayNone] = useState(true);
    const [displayNone1, setdisplayNone1] = useState(true);
    const [preferedLocation_1, setPreferedLocation_1] = useState([]);
    const [preferedLocation_2, setPreferedLocation_2] = useState([]);
    const [preferedLocation_3, setPreferedLocation_3] = useState([]);


    const [defPreferedStateCity, setDefPreferedStateCity] = useState({
            'prefered_state_1' : '' ,
            'prefered_city_1' : '' ,
            'prefered_state_2' : '' ,
            'prefered_city_2' : '' ,
            'prefered_state_3' : '' ,
            'prefered_city_3' : '' ,
    });

    useEffect(() => {
        let html = convertToHTML(editorState.getCurrentContent());
        setProfileDescription(html);
    }, [editorState]);
    
    useEffect(() => {
        const  storedata =  JSON.parse(localStorage.getItem("userloginSession")) ;
        setDisplayNone(false)
        console.log(storedata.key_skills);
        if(storedata.key_skills){
            setjobsSkillKeyArray(JSON.parse(storedata.key_skills))
        }
        
        (async () => {
            try {
            const user = await axios.get(
                "https://jobchatbox.com/api/profile/profile_data/"+storedata.login_token
            );
            if(user.data.status == 1){

                setDefPreferedStateCity({
                        'prefered_state_1' : user.data.data.results.prefered_state_1 ,
                        'prefered_city_1' : user.data.data.results.prefered_location_1 ,
                        'prefered_state_2' : user.data.data.results.prefered_state_2 ,
                        'prefered_city_2' : user.data.data.results.prefered_location_2 ,
                        'prefered_state_3' : user.data.data.results.prefered_state_3 ,
                        'prefered_city_3' : user.data.data.results.prefered_location_3 ,
                });
                if(user.data.data.results.prefered_state_1){
                    getCityByStateCodeData(user.data.data.results.prefered_state_1,'usa_city_list','1');
                }

                if(user.data.data.results.prefered_state_2){
                    getCityByStateCodeData(user.data.data.results.prefered_state_2,'usa_city_list','2');
                }

                if(user.data.data.results.prefered_state_3){
                    getCityByStateCodeData(user.data.data.results.prefered_state_3,'usa_city_list','3');
                }

                setFormValue(user.data.data.results);
                getCityByStateCodeData(user.data.data.results.user_state)
                setProfileBgImageUrl(user.data.data.results.profile_bg_img_url)
                profile_pic =  (user.data.data.results.profile_pic) ? `https://jobchatbox.com/api/uploads/users/${user.data.data.results.profile_pic}` : 'front/assets/imgs/user1.png';
                
                let userdata = user.data.data.results;
                console.log(userdata);
                //alert(userdata.prefered_state_1);
                //getCityByStateCodeData(userdata.user_state,'usa_city_list','0');
                


                //profile_bg_img_url
                setProfilePicUrl(profile_pic);
                //setJobSkillsArray
            }
            } catch (error) {
            console.log(error);
            }
        })();

        (async () => {
            try {
                const states_list = await axios.get("https://jobchatbox.com/api/home/api/states_list");
                if(states_list.data.status == 1){ setStateListArray(states_list.data.data.results)}
            } catch (error) { console.log(error); }
        })();

        (async () => {
            try {
                const responceData = await axios.get("https://jobchatbox.com/api/profile/brand_deatils/"+storedNames.login_token);
                if(responceData.data.status == 1){
                    setBrandDetailsArr(responceData.data.data.clients_data)
                }
            } catch (error) {
                console.log(error);
            }
        })();
        
        (async () => {
            try {
                const responceData = await axios.get("https://jobchatbox.com/api//home/api/industry_type_list/");
                if(responceData.data.status == 1){
                    setIndustryTypeListArray(responceData.data.data.results)
                }
            } catch (error) {
                console.log(error);
            }
        })();
        
        (async () => {
            try {
                const responceData = await axios.get("https://jobchatbox.com/api//home/api/recruiter_jobstype");
                if(responceData.data.status == 1){ setRecStatusListDataArray(responceData.data.data.results) }
            } catch (error) {console.log(error); }
        })();

    }, []);

    const teghandleChange =  (tags) => {
        setjobsSkillKeyArray(tags)
    }

    // const getCityByStateCodeData  =  (statecode) => {
    //     (async () => {
    //         try {
    //         const city_list = await axios.get("https://jobchatbox.com/api/home/api/usa_city_list/"+statecode);
    //         if(city_list.data.status == 1){
    //             setAllCityList(city_list.data.data.results)
    //         }
    //         } catch (error) {
    //         console.log(error);
    //         }
    //     })();
    // }
    const fileInputRef = useRef(null);

    const photoChange = async (e) => {
        e.preventDefault();
        let fileName = e.target.files[0].name;
        let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        var ImageExt  = ['jpeg' ,'JPEG' ,'png' ,'PNG'];

        const maxSize = 5 * 1024 * 1024; // 5MB in bytes
        //setProfilePhotoerror('');
       
        if(ImageExt.includes(extension)  &&  fileName.size  > maxSize ){
            setIsUpdatingForm(true);
            const formData = new FormData();
            formData.append('profile_photo', e.target.files[0]);
            try {
                let res = await fetch("https://jobchatbox.com/api/profile/profile_pic_upload", {
                    method: "POST",
                    body: formData ,
                });
                let resJson = await res.json();
                if (res.status === 200) {
                    setIsUpdatingForm(false);
                    if(resJson.status  == 1){
                        setProfileUrl(resJson.data.url);
                        profile_pic =  (resJson.data.url) ? `https://jobchatbox.com/api/uploads/users/${resJson.data.url}` : 'front/assets/imgs/user1.png';
                        setProfilePicUrl(profile_pic);
                    }
                } 
            } catch (err) {
                console.log(err);
            }
        } else {
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            setProfilePhotoerror('You can\'t upload more then 5MB or wrong format file');
            setTimeout(function() { setProfilePhotoerror(''); }, 4000);
        }
    }

    const  notify = (status_type,message) => {
        switch (status_type) {
            case 'warning':
                toast.warning(message)  
            break;
    
            case 'success':
                toast.success(message)  
            break;
    
            case 'info':
                toast.info(message)  
            break;
    
            case 'error':
                toast.error(message)  
            break;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        setIsUpdatingForm(true);
        if (form.checkValidity() === false) {
          event.stopPropagation();
        } else {
            
          const formData = new FormData();
          var storedNames = JSON.parse(localStorage.getItem("userlogin"));
          formData.append('company_name', event.target.company_name.value);
          formData.append('full_name', event.target.firstname.value);
          formData.append('location', event.target.location_id.value);
          formData.append('contact_no', event.target.mobile.value);
          formData.append('date_of_birth', event.target.date_of_birth.value);
          formData.append('area_pin_code', event.target.city_zipcode.value);
          formData.append('permanent_address', event.target.address.value);
          //formData.append('profile_description', event.target.short_desc.value);
          formData.append('profile_description', profileDescription);
          formData.append('designation', event.target.designation.value);

        //jobsSkillKeyArray
          //formData.append('skills_and_roles', event.target.key_skills.value);
          formData.append('skills_and_roles', JSON.stringify(jobsSkillKeyArray));
          
          formData.append('facebook_url', event.target.facebook_url.value);
          formData.append('twitter_url', event.target.twitter_url.value);
          formData.append('google_url', event.target.google_url.value);
          formData.append('linkedin_url', event.target.linkedin_url.value);
          formData.append('user_state', event.target.user_state.value);
          formData.append('user_city', event.target.user_city.value);
          formData.append('profile_pic', profileUrl);
          formData.append('login_token', storedNames.login_token);
          formData.append('usertype', storedNames.usertype);
          
          formData.append('join_status', event.target.join_status.value);
          formData.append('job_industry', event.target.hireing_for.value);

          var input = document.getElementsByName('user_prefered_state[]');
                var user_prefered_array =  [];
                for (var i = 0; i < input.length; i++) {
                    let a = input[i];
                    user_prefered_array[i] = a.value
                }

            var input2 = document.getElementsByName('prefered_location[]');
            var prefered_location_array =  [];
            for (var i = 0; i < input2.length; i++) {
                let a = input2[i];
                prefered_location_array[i] = a.value
            }

            formData.append('user_prefered_state', user_prefered_array);
            formData.append('user_prefered_location', prefered_location_array );

            console.log(storedNames);
          try {
            let res = await fetch("https://jobchatbox.com/api/profile/profile_update", {
                method: "POST",
                body: formData ,
            });
            let resJson = await res.json();
            if (res.status === 200) {
              (resJson.status) ? notify('success',resJson.message) : notify('warning',resJson.message) ;
               setIsUpdatingForm(false);
                if((resJson.status) ==  1){
                    console.log(resJson.data);
                    localStorage.removeItem('userloginSession');
                    setTimeout(function() {
                         localStorage.setItem('userloginSession',JSON.stringify(resJson.data)) ;
                         navigate('/dashboard');
                    }, 1000);
                    
                }

              //setTimeout(function() { navigate('/dashboard');}, 1000);
            } else {
              notify('error','Some error occured.');
            }
          } catch (err) {
            console.log(err);
          }
        }
        setValidated(true);  
    }

    const handleInput = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    }


    const  getCityByStateCodeData  =  (statecode='0',apitype='usa_city_list',argPass) => {
        (async () => {
            try {
            const city_list = await axios.get("https://jobchatbox.com/api/home/api/"+apitype+"/"+statecode);
              if(city_list.data.status == 1){ 
                  if(argPass == 1){ 
                    setPreferedLocation_1(city_list.data.data.results)
                  } else if(argPass == 2){
                    setPreferedLocation_2(city_list.data.data.results)
                  } else if(argPass == 3){
                    setPreferedLocation_3(city_list.data.data.results)
                  } else {
                      setCityListArray(city_list.data.data.results)
                  }
              }
            } catch (error) {
            console.log(error);
            }
        })();
    }

    const getCityByStateCode = (e) => {
        getCityByStateCodeData(e.target.value,'usa_city_list','0');
    } 
  
    const getCityByStateCodeMulti1 = (e) => {
        getCityByStateCodeData(e.target.value,'usa_city_list','1');
    } 

    const getCityByStateCodeMulti2 = (e) => {
        getCityByStateCodeData(e.target.value,'usa_city_list','2');
    } 

    const getCityByStateCodeMulti3 = (e) => {
        getCityByStateCodeData(e.target.value,'usa_city_list','3');
    } 

    const [location_2_hide, set_location_2_hide] = useState(1);
    const handleClickLocation1 = (event) => {
        //location_2_hide
        event.preventDefault();
        let ddd  = (location_2_hide == 1) ? 2 : ((location_2_hide == 2) ? 3 : 1)
        set_location_2_hide(ddd)
    }
    const removeLocation2 = (event) => {
        event.preventDefault();
        set_location_2_hide(1)
    }

    const removeLocation3 = (event) => {
        event.preventDefault();
        set_location_2_hide(2)
    }
    
    //multi search code 
    // Array of all options
    const optionList = [
        { value: "red", label: "Red" },
        { value: "green", label: "Green" },
        { value: "yellow", label: "Yellow" },
        { value: "blue", label: "Blue" },
        { value: "white", label: "White" }
    ];
    function handleSelect(data) {
        setSelectedOptions(data);
    }
    var storedNames = JSON.parse(localStorage.getItem("userlogin"));


    const profileBgUpload =  async (e) => {
        var storedNames = JSON.parse(localStorage.getItem("userlogin"));
        e.preventDefault();
        let fileName = e.target.files[0].name;
        let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        var ImageExt  = ['png','jpeg' , 'PNG' , 'JPEG'];
        if(ImageExt.includes(extension)){
            const formData = new FormData();
            formData.append('profile_bg_img', e.target.files[0]);
            formData.append('logintoken', storedNames.login_token );
            try {
                let res = await fetch("https://jobchatbox.com/api/profile/profile_bg_upload", {
                    method: "POST",
                    body: formData ,
                });
                let resJson = await res.json();
                if (res.status === 200) {
                    if(resJson.status  == 1){
                        setProfileBgImageUrl(resJson.data.url)
                       // this.state.fields.profile_bg_img_url =  resJson.data.url
                    }
                } 
            } catch (err) {
                console.log(err);
            }
        }
    }

    const brandDetailsSubmit =  async (e) => {
        var storedNames = JSON.parse(localStorage.getItem("userlogin"));
        e.preventDefault();
        let fileName = e.target.brand_image.files[0];
        console.log(fileName)
        //let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        var ImageExt  = ['png','jpeg' , 'PNG' , 'JPEG'];
        //if(ImageExt.includes(extension)){
            const formData = new FormData(); 
            formData.append('brand_name', e.target.brand_name.value);
            formData.append('brand_website', e.target.brand_website.value);
            formData.append('brand_description', e.target.brand_description.value);
            formData.append('brand_image', e.target.brand_image.files[0]);
            formData.append('logintoken', storedNames.login_token );
            try {
                let res = await fetch("https://jobchatbox.com/api/profile/add_brand_deatils", {
                    method: "POST",
                    body: formData ,
                });
                let resJson = await res.json();
                if (res.status === 200) {
                    if(resJson.status  == 1){
                        setBrandDetailsArr(resJson.data.clients_data)
                        setBrandDetailsArray(
                            {
                                'brand_name' : '' ,
                                'brand_description' : '' ,
                                'profile_brand_id' : 0 ,
                             }
                        );
                    }
                } 
            } catch (err) {
                console.log(err);
            }
        //}
    }

    const deleteBrandLogo = (id) => {
        if (window.confirm('Do you want to remove?') == true) {
            (async () => {
                try {
                const responceData = await axios.get("https://jobchatbox.com/api/profile/api/remove_brand_deatils/"+storedNames.login_token+'/'+id);
                if(responceData.data.status == 1){
                    toast.success(responceData.data.message);
                    setBrandDetailsArr(responceData.data.data.clients_data)
                }
                } catch (error) {
                console.log(error);
                }
            })();
        }
    }
    
    const [brandDetailsArray, setBrandDetailsArray] = useState({
        'brand_name' : '' ,
        'brand_website' : '' ,
        'brand_description' : '' ,
        'profile_brand_id' : 0 ,
     });

     const updateBrandDetails = (event) => {
        let fields =  event.target.name ;
        if(fields == 'brand_name'){
            brandDetailsArray.brand_name = event.target.value ;
            setBrandDetailsArray({ ...brandDetailsArray })
        }
        if(fields == 'brand_description'){
            brandDetailsArray.brand_description = event.target.value ;
            setBrandDetailsArray({ ...brandDetailsArray })
        }
     }




    //let profile_pic =  (this.state.userdata.profile_pic) ? `https://jobchatbox.com/api/uploads/users/${this.state.userdata.profile_pic}` : 'front/assets/imgs/user1.png';
    const [userloginSession, setUserloginSession] = useState(localStorage.getItem("userloginSession"));

    const handleUpdateProfileData = () => {
        setUserloginSession(localStorage.getItem("userloginSession"))
    };

    return (
      <>
         <ToastContainer />
        <div className="dashboardsec pt-30 pb-30">
           <div className="container">
            
            <div className="row">
                <div className="col-lg-3">
                    <LeftSidebar />
                    {/* <LeftSidebar  profileData={userloginSession} /> */}
                </div>
                <div className="col-lg-9">
                    <div className="section-box jobpost-sec">
                        <div className="container">
                            <div className="head-border mb-30 d-flex justify-content-between align-items-center">
                            <h5>Personal Detail</h5>
                            </div>
                            <div className="grayborderbox mb-30">
                                <div className="box-padding">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            {
                                                console.log(formValue)
                                            }
                                            {
                                                storedNames.usertype == 3 ?  <JobseekerSignup  onUpdateProfilePic={handleUpdateProfileData}  data={formValue} userscrenn='login' />  :
                                                <>
                                                    <Form  noValidate validated={validated} onSubmit={handleSubmit}  className="login-register text-start" autoComplete="off">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="form-group mb-10"> 
                                                                    <label className="font-sm color-text-mutted mb-5">Company Name</label>
                                                                    <input className="form-control" value={formValue.company_name}   onChange={handleInput}   name="company_name" type="text" placeholder="Company Name" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="form-group mb-10 selectdesign">
                                                                    <label className="font-sm color-text-mutted mb-5">Your Name *</label>
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        name="firstname"
                                                                        placeholder="Your Name"
                                                                        onChange={handleInput}
                                                                        value={formValue.firstname}
                                                                    />                                                
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="form-group mb-10 selectdesign">
                                                                    <label className="font-sm color-text-mutted mb-5">State *</label>
                                                                    <Form.Select 
                                                                        required 
                                                                        defaultValue={formValue.user_state}
                                                                        className="form-control no-pad" 
                                                                        onChange={getCityByStateCode} 
                                                                        name="user_state" 
                                                                        id="user_state" 
                                                                        aria-label={formValue.user_state}>
                                                                        <option value="" >Select</option> 
                                                                            {
                                                                            stateListArray.map((item,index) => (
                                                                                <option  selected={(item.state_code == formValue.user_state) ? true : false }   key={index} value={item.state_code}>{item.state_name}</option>
                                                                            ))
                                                                            }
                                                                    </Form.Select>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="form-group mb-10 selectdesign">
                                                                    <label className="font-sm color-text-mutted mb-5">City *</label>
                                                                    <Form.Select 
                                                                        required 
                                                                        className="form-control no-pad" 
                                                                        name="user_city" 
                                                                        id="user_city" 
                                                                        aria-label="Default select example">
                                                                        <option value="" >Select</option> 
                                                                            {
                                                                            cityListArray.map((item,index) => (
                                                                                <option  selected={(item.id == formValue.user_city) ? true : false }  key={index} value={item.id}>{item.city_name}</option>
                                                                            ))
                                                                            }
                                                                    </Form.Select>
            
                                                                </div>
                                                            </div>
                                                            {/* ****************************** */}
                                                             {/* Location for address prefered */}  
                                                            <div  className={ displayNone ? 'display_none' : 'col-md-6 mb-0' } >
                                                                <div className="form-group mb-1">
                                                                    <label className="font-sm color-text-mutted mb-5" htmlFor="input-7">Prefered State 1 { required}*</label>
                                                                    <Form.Select 
                                                                        required={required} 
                                                                        className="form-control no-pad" 
                                                                        onChange={getCityByStateCodeMulti1} 
                                                                        name="user_prefered_state[]" 
                                                                        id="user_prefered_state" >
                                                                        <option  selected={true} value="" >Select</option> 
                                                                        {
                                                                        stateListArray.map((item,index) => (
                                                                            <option selected={(defPreferedStateCity.prefered_state_1 == item.state_code) ? true : false}  key={index} value={item.state_code}>{item.state_name}</option>
                                                                        ))
                                                                        }   
                                                                    </Form.Select>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className={ displayNone ? 'display_none' : 'col-md-6 mb-0' }  >
                                                                <div className="form-group mb-1">
                                                                    <label className="font-sm color-text-mutted mb-5" htmlFor="input-7">Prefered City 1*</label>
                                                                    
                                                                    <Form.Select 
                                                                        required={required}
                                                                        className="form-control no-pad" 
                                                                        name="prefered_location[]" 
                                                                        id="prefered_location" 
                                                                        aria-label="Default select example">
                                                                        <option  selected={true} value="" >Select</option> 
                                                                            {
                                                                            preferedLocation_1.map((item,index) => (
                                                                                <option selected={(defPreferedStateCity.prefered_city_1 == item.id+'__'+item.city_name) ? true : false}   key={index} value={item.id+'__'+item.city_name}>{item.city_name}</option>
                                                                            ))
                                                                            }
                                                                    </Form.Select>
                                                                </div>
                                                            </div>

                                                            {/* *************** */}
                                                            {/* Location for address prefered 2 */}
                                                            
                                                            {/* <div  className={  (displayNone) ? 'display_none' : ''  } >
                                                                <a onClick={removeLocation2} className="remove_location_2 remove_location_button">Remove Location 2</a>
                                                            </div> */}
                                                            <div className={ (displayNone) ? 'display_none' : 'col-md-6 mb-0' }  >
                                                                <div className="form-group mb-1">
                                                                    <label className="font-sm color-text-mutted mb-5" htmlFor="input-7">Prefered State 2</label>
                                                                    <Form.Select 
                                                                        className="form-control no-pad" 
                                                                        onChange={getCityByStateCodeMulti2} 
                                                                        name="user_prefered_state[]" 
                                                                        id="user_prefered_state2" >
                                                                        <option  selected={true} value="" >Select</option> 
                                                                        {
                                                                        stateListArray.map((item,index) => (
                                                                            <option selected={(defPreferedStateCity.prefered_state_2 == item.state_code) ? true : false} key={index} value={item.state_code}>{item.state_name}</option>
                                                                        ))
                                                                        }   
                                                                    </Form.Select>
                                                                </div>
                                                            </div>
                                                            <div className={ (displayNone) ? 'display_none' : 'col-md-6 mb-0' }   >
                                                                <div className="form-group mb-1">
                                                                    <label className="font-sm color-text-mutted mb-5" htmlFor="input-7">Prefered City 2</label>
                                                                    <Form.Select 
                                                                        className="form-control no-pad" 
                                                                        name="prefered_location[]" 
                                                                        id="prefered_location2" 
                                                                        aria-label="Default select example">
                                                                        <option  selected={true} value="" >Select</option> 
                                                                            {
                                                                            preferedLocation_2.map((item,index) => (
                                                                                <option selected={(defPreferedStateCity.prefered_city_2 == item.id+'__'+item.city_name) ? true : false}  key={index} value={item.id+'__'+item.city_name}>{item.city_name}</option>
                                                                            ))
                                                                            }
                                                                    </Form.Select> 
                                                                </div>
                                                            </div>

                                                            {/* Location for address prefered 3 */}
                                                            
                                                            {/* <div className={  (displayNone) ? 'display_none' : ''  }  >
                                                                <a onClick={removeLocation3} className="remove_location_3 remove_location_button">Remove Location 3</a>
                                                            </div> */}

                                                            <div className={ (displayNone) ? 'display_none' : 'col-md-6 mb-0' }  >
                                                                <div className="form-group mb-1">
                                                                    <label className="font-sm color-text-mutted mb-5" htmlFor="input-7">Prefered State 3</label>
                                                                    <Form.Select 
                                                                        className="form-control no-pad" 
                                                                        onChange={getCityByStateCodeMulti3} 
                                                                        name="user_prefered_state[]" 
                                                                        id="user_prefered_state3" >
                                                                        <option  selected={true} value="" >Select</option> 
                                                                        {
                                                                        stateListArray.map((item,index) => (
                                                                            <option selected={(defPreferedStateCity.prefered_state_3 == item.state_code) ? true : false} key={index} value={item.state_code}>{item.state_name}</option>
                                                                        ))
                                                                        }   
                                                                    </Form.Select>
                                                                </div>
                                                            </div>
                                                            <div className={ (displayNone) ? 'display_none' : 'col-md-6 mb-0' }  >
                                                                <div className="form-group mb-1">
                                                                    <label className="font-sm color-text-mutted mb-5" htmlFor="input-7">Prefered City 3</label>
                                                                    <Form.Select 
                                                                        className="form-control no-pad" 
                                                                        name="prefered_location[]" 
                                                                        id="prefered_location3" 
                                                                        aria-label="Default select example">
                                                                        <option  selected={true} value="" >Select</option> 
                                                                            {
                                                                            preferedLocation_3.map((item,index) => (
                                                                                <option  selected={(defPreferedStateCity.prefered_city_3 == item.id+'__'+item.city_name) ? true : false}  key={index} value={item.id+'__'+item.city_name}>{item.city_name}</option>
                                                                            ))
                                                                            }
                                                                    </Form.Select> 
                                                                </div>
                                                            </div>

                                                            {/* <div>
                                                                <button  className={  (displayNone) ? 'display_none' : 'btn btn-primary add_more_location_btn'  } onClick={handleClickLocation1} >Add More Location+</button>
                                                            </div> */}
                                                            {/* end  */}

                                                            {/* ****************************** */}



                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="form-group mb-10 selectdesign">
                                                                    <label className="font-sm color-text-mutted mb-5">Email</label>
                                                                    <Form.Control
                                                                        type="hidden"
                                                                        name="location_id"
                                                                        placeholder="Location"
                                                                        value={formValue.user_location}
                                                                    /> 
                                                                    <Form.Control
                                                                        required
                                                                        type="readonly"
                                                                        name="email"
                                                                        placeholder="Email"
                                                                        className='readonly_text'
                                                                        value={formValue.email}
                                                                    /> 
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="form-group mb-10"> 
                                                                    <label className="font-sm color-text-mutted mb-5">Phone *</label>
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        name="mobile"
                                                                        maxLength= '12'
                                                                        placeholder="Contact No."
                                                                        onChange={handleInput}
                                                                        value={formValue.mobile}
                                                                    /> 
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="form-group mb-10"> 
                                                                    <label className="font-sm color-text-mutted mb-5">Date of Company Register</label>
                                                                    <Form.Control
                                                                        type="date"
                                                                        name="date_of_birth"
                                                                        placeholder="Date of Birth"
                                                                        onChange={handleInput}
                                                                        value={formValue.date_of_birth}
                                                                    /> 
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="form-group mb-10"> 
                                                                    <label className="font-sm color-text-mutted mb-5">Area Pin Code *</label>
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        name="city_zipcode"
                                                                        maxLength= '10'
                                                                        placeholder="Area Pin Code"
                                                                        onChange={handleInput}
                                                                        value={formValue.city_zipcode}
                                                                    /> 
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="form-group mb-10"> 
                                                                    <label className="font-sm color-text-mutted mb-5">Permanent Address *</label>
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        name="address"
                                                                        value={formValue.address}
                                                                        onChange={handleInput}
                                                                        placeholder="Permanent Address"
                                                                    /> 
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="form-group mb-10"> 
                                                                    <label className="font-sm color-text-mutted mb-5">Designation *</label>
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        name="designation"
                                                                        onChange={handleInput}
                                                                        value={formValue.designation}
                                                                        placeholder="Designation"
                                                                    /> 
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="form-group mb-10"> 
                                                                    <label className="font-sm color-text-mutted mb-5">Skills/Roles I hire for *</label>
                                                                    <TagsInput className="inputtypeTagsKey" value={jobsSkillKeyArray} onChange={teghandleChange} />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <div className="form-group mb-10">
                                                                <label className="font-sm color-text-mutted mb-5" htmlFor="input-5">Join Status *</label>
                                                                    <Form.Select 
                                                                        required
                                                                        className="form-control no-pad" 
                                                                        name="join_status" 
                                                                        id="join_status" >
                                                                        <option  value="" >Select</option> 
                                                                        {
                                                                        recStatusListDataArray.map((item,index) => (
                                                                            <option selected={formValue.join_status == item.job_type_value ? true : false}  key={index} value={item.job_type_value}>{item.job_type_value}</option>
                                                                        ))
                                                                        }   
                                                                    </Form.Select>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group selectdesign">
                                                                    <label className="font-sm color-text-mutted mb-5">Hiring for *</label>
                                                                    <Form.Select
                                                                        required
                                                                        className="form-control no-pad" 
                                                                        name="hireing_for" 
                                                                        id="hireing_for" >
                                                                        <option value="0" >Select</option> 
                                                                        {
                                                                        industryTypeListArray.map((item,index) => (
                                                                            <option selected={formValue.job_industry == item.id ? true : false}  key={index} value={item.id}>{item.industry}</option>
                                                                        ))
                                                                        }   
                                                                    </Form.Select>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="form-group mb-10"> 
                                                                    <label className="font-sm color-text-mutted mb-5">Profile Description</label>
                                                                    {
                                                                            <Editor
                                                                            editorStyle={{ height: '150px' }}
                                                                            editorState={editorState}
                                                                            onEditorStateChange={setEditorState}
                                                                            wrapperClassName="wrapper-class"
                                                                            editorClassName="editor-class"
                                                                            toolbarClassName="toolbar-class1"
                                                                            toolbar={{
                                                                            options: ['inline', 'fontSize',  'list', 'textAlign',  'link',  'emoji', 'history'] ,
                                                                            }}
                                                                        />
                                                                    }
                                                                    
                                                                    
                                                                    {/* <textarea className="form-control" rows="5" name='short_desc'  placeholder="Profile Description" value={formValue.short_desc}   onChange={handleInput}>{formValue.short_desc}</textarea> */}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="form-group mb-10"> 
                                                                    <label className="font-sm color-text-mutted mb-5">Fabebook Url</label>
                                                                    <input className="form-control" name='facebook_url' type="text" placeholder="Fabebook Url" value={formValue.facebook_url} onChange={handleInput} />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="form-group mb-10"> 
                                                                    <label className="font-sm color-text-mutted mb-5">Twitter Url</label>
                                                                    <input className="form-control" name='twitter_url' type="text" placeholder="Twitter Url"  value={formValue.twitter_url} onChange={handleInput} />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="form-group mb-10"> 
                                                                    <label className="font-sm color-text-mutted mb-5">Google Url</label>
                                                                    <input className="form-control" type="text" name='google_url' placeholder="Google Url"  value={formValue.google_url} onChange={handleInput} />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="form-group mb-10"> 
                                                                    <label className="font-sm color-text-mutted mb-5">Linkedin Url</label>
                                                                    <input className="form-control" type="text"  name='linkedin_url'  placeholder="Linkedin Url"  value={formValue.linkedin_url} onChange={handleInput} />
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="col-lg-6 col-md-6">
                                                                <div className=" form-group  mb-10" > 
                                                                    <label className="font-sm color-text-mutted mb-5">Profile photo *</label>
                                                                    <Form.Control
                                                                        type="file"
                                                                        onChange={photoChange}
                                                                        ref={fileInputRef}
                                                                        name="profile_photo"
                                                                        accept="image/png, image/gif, image/jpeg"
                                                                        placeholder="Profile pic"
                                                                    />
                                                                    { profilePhotoerror ? <><p className='text-danger'>{profilePhotoerror}</p></> : ''}
                                                                    <figure class="mt-2"><img style={{width:'100px'}} alt="jcbapp" src={profilePicUrl} /></figure>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="form-group mt-10 mb-0">
                                                                    <button  disabled={isUpdatingForm} className="btn btn-default btn-brand icon-tick">Submit</button>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </Form>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* client logo and settings */}
                            { 
                                (storedNames.usertype == 6) ? 
                                <>
                                <div className="grayborderbox mb-30">
                                    <div className="col-md-12 p-3">
                                        <div className="form-group mb-10">
                                            <label className="font-sm color-text-mutted mb-5" htmlFor="profile_background">Profile Backgound</label>
                                            <input className="form-control" onChange={profileBgUpload} id="profile_background" name="profile_background" type="file" accept="image/png, image/gif, image/jpeg"   />
                                        </div>
                                        <img src={profileBgImageUrl} className="bg_imgupload"></img>
                                    </div>
                                </div>
                                <div className="grayborderbox mb-30">
                                    <div className="box-padding">
                                        <h5 className="icon-edu">Brand Details</h5>
                                        <div className="row mt-20">
                                            <div className="col-lg-12">
                                                
                                                <form novalidate="" onSubmit={brandDetailsSubmit} name="addWorkExperienceInProfileForm" method="post" className="">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-10">
                                                                <label className="font-sm color-text-mutted mb-5">Brand name*</label>
                                                                <input required=""  onChange={updateBrandDetails} name="brand_name" placeholder="" type="text" className="form-control" value={brandDetailsArray.brand_name} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-10">
                                                                <label className="font-sm color-text-mutted mb-5"  htmlFor="brand_image">Brand Logo*</label>
                                                                <input  required="" className="form-control" id="brand_image" name="brand_image" type="file" accept="image/png, image/gif, image/jpeg"   />
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-10">
                                                                <label className="font-sm color-text-mutted mb-5">Brand Website</label>
                                                                <input   onChange={updateBrandDetails} name="brand_website" placeholder="" type="text" className="form-control" value={brandDetailsArray.brand_website} />
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-12">
                                                            <div className="form-group mb-10">
                                                                <label className="font-sm color-text-mutted mb-5">Description</label>
                                                                <textarea className="form-control" onChange={updateBrandDetails}  name="brand_description" rows="5" style={{height: '80px;'}} value={brandDetailsArray.brand_description}>{brandDetailsArray.brand_description}</textarea>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="form-group mb-10">
                                                                <input type="hidden" name="workexp_edu_id" value="0" />
                                                                <button type="submit" className="btn btn-default btn-brand icon-tick">Add Client</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="mt-20 d-flex flex-wrap brandsec row">
                                                    {
                                                        brandDetailsArr.length ? 
                                                        <>
                                                        {
                                                            brandDetailsArr.map((item,index) => (
                                                                <>
                                                                <div className="col-md-3 col-sm-6">
                                                                    <div className="timeline-info1">
                                                                        <h6 className="color-brand-1 mb-5">
                                                                            <img src={item.brand_image_url} ></img>
                                                                        </h6>
                                                                        <h6 className="color-text-paragraph-2 mb-15">{item.brand_name}</h6>
                                                                        <p className="color-text-paragraph-2 mb-15">{item.brand_description}</p>
                                                                    </div>
                                                                    <div className="timeline-actions">
                                                                        {/* <a className="btn btn-default btn-small mr-10"><i className="fa fa-pencil-square-o mr-0" aria-hidden="true"></i></a> */}
                                                                        <a  onClick={ (event) => deleteBrandLogo(item.id) }   className="btn btn-default btn-small"><i className="fa fa-trash-o mr-0" aria-hidden="true"></i></a>
                                                                    </div>
                                                                </div>
                                                                </>
                                                            ))
                                                        }
                                                        </> : ''
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </>
                                :''
                            }
                            

                        </div>
                    </div>
                </div>  
            </div>
          </div>
        </div>
      </>
    );
};
export default Profile_edit;