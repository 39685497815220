import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
//class ThankYou extends React.Component {
const Training_and_placements_details = () => {

   const queryParams = new URLSearchParams(window.location.search);
   const placement_id = queryParams.get('id');


   const [editStatus, setEditStatus] = useState(false);
   const [loadingStatus, setLoadingStatus] = useState(0);
   const [pageData, setPageData] = useState(false);
   useEffect(() => {
      if (placement_id) {

         (async () => {
            try {
               const getdata2 = await axios.get("https://jobchatbox.com/api/placement/get_training_and_placements/" + placement_id);
               if (getdata2.data.status) {
                  let getdata = (getdata2.data.data);
                  setEditStatus(getdata.id);
                  setLoadingStatus(1)
                  setPageData(getdata);
               }
            } catch (error) { console.log(error); }
         })();
      }
   }, []);

   const loopcontent2 = [1, 2, 3, 4];
   //request_accepted


   /*-----------------------------------*/
   const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    mobileNumber: '+91',
    location: '',
    placement_id_data: placement_id,
  });
  const [formErrors, setFormErrors] = useState({});
  const [submitMessage, setSubmitMessage] = useState('');

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setFormErrors({});
    setSubmitMessage('');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add validation logic
    const errors = {};
    if (!formData.fullName) {
      errors.fullName = 'Full Name is required';
    }
    if (!formData.email) {
      errors.email = 'Email is required';
    }
   
    if (!formData.mobileNumber) {
      errors.mobileNumber = 'Mobile Number is required';
    } else if (!/^(\+91)[0-9]{10}$/.test(formData.mobileNumber)) {
      errors.mobileNumber = 'Mobile Number should start with +91 and be 10 digits long';
    }
    
    if (!formData.location) {
      errors.location = 'Location is required';
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // Submit the data to your API
    //https://jobchatbox.com/api/placement/get_training_and_placements/
    try {
      const response = await fetch('https://jobchatbox.com/api/placement/request_call_back', {
        method: 'POST',
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmitMessage('Thank you for contacting us, we will get back to you as soon as possible.');
        // You can also close the modal here if needed
        // closeModal();
      } else {
        setSubmitMessage('Error submitting data');
      }
    } catch (error) {
      setSubmitMessage('An error occurred');
    }
  }; 

   return (
      <>
         <section className="section-box">
            <div className="post-loop-grid">
           
               {
                  (loadingStatus > 0) ?
                     <>
                        <div class="container">
                           <div>
                              <section className="details-section">
                                 <div className="container p-5" >

                                    <div className="row">
                                       <div className="col-sm-8 col-md-8 col-xs-12">
                                          <div className="place-details">
                                             <img src="https://careerhamster.com/includes/images/trust_seal.png" className="img-responsive hidden-xs" style={{ width: '250px', height: '100px', float: 'right', marginTop: '-2em' }} />
                                             <h2 className="text-white text-uppercase">{pageData.title}</h2>
                                          </div>
                                          <div className="place-details">
                                             <h3 className="text-white" style={{ color: '#fff', fontFamily: 'OpenSans-Bold', margin: '0' }}>Location: {pageData.location}</h3>
                                          </div>
                                          <div className="course-inc" style={{ marginTop: '3%' }}>
                                             <span className="text-white">Courses or other services:</span>
                                             <h4>
                                                {/* courses_included_array */}
                                                {
                                                   pageData.courses_included_array.map((itme,index) => (
                                                      <span className="placehoder_tags">{itme}</span>
                                                   ))
                                                }
                                               
                                             </h4>
                                          </div>
                                          <div className="row" style={{ marginTop: '5%' }}>
                                             <div className="verified-cour">
                                                <div className="col-sm-12 col-md-12 col-xs-12">
                                                   <div className="place-rating">
                                                      <i className="fa fa-star-o" aria-hidden="true" style={{ color: '#ffd900', fontSize: '20px' }}></i>
                                                      <i className="fa fa-star-o" aria-hidden="true" style={{ color: '#ffd900', fontSize: '20px' }}></i>
                                                      <i className="fa fa-star-o" aria-hidden="true" style={{ color: '#ffd900', fontSize: '20px' }}></i>
                                                      <i className="fa fa-star-o" aria-hidden="true" style={{ color: '#ffd900', fontSize: '20px' }}></i>
                                                      <i className="fa fa-star-o" aria-hidden="true" style={{ color: '#fff', fontSize: '20px' }}></i>
                                                      <span className="total-rating text-white" style={{ marginLeft: '4em' }}>
                                                      {pageData.members_placed} Members Placed in different companies
                                                      </span>
                                                   </div>
                                                </div>
                                                <div className="row">
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-sm-4 col-md-4 col-xs-12">
                                          <div className="main-head">
                                             <div className="prdhh_img contctgrop1 det-img">
                                                <img src={pageData.logo} itemprop="image" />
                                                <div className="verified">
                                                   <p style={{ fontSize: '15px' }}>
                                                      {pageData.user_details.emailAct ==  1 ? 'Verified' : 'Unverified'}
                                                   </p>
                                                </div>
                                             </div>
                                             <div className="per-info">
                                                {pageData.user_details.company_name && <p>
                                                   {pageData.user_details.company_name}
                                                </p> }
                                                <p><span>Email: </span>{pageData.user_details.email}</p>
                                                <p><span>Mobile Number: </span>{pageData.user_details.mobile}</p>
                                                <p>
                                                   <span>Website URL: </span>
                                                   <a href={pageData.user_details.webiste_url} target="_blank">Click Here</a>
                                                </p>

                                                <h6><span ><s><span >₹{pageData.original_price}</span></s></span>
                                                       ₹{pageData.final_price}</h6>
                                                     

                                               
                                                <span>
                                                   <button   className="btn btn-primary" style={{ backgroundColor: '#15ade4', borderColor: '#15ade4' }} onClick={openModal} >Request Call back</button >
                                                </span>
                                                <span>
                                                   {/* <a href="#0" className="btn btn-primary" style={{ backgroundColor: '#fff', color: '#000', borderColor: '#fff' }} data-toggle="modal" data-target="#upcomingContent">Upcoming Demos</a> */}
                                                </span>
                                             </div>

                                            { showModal && <div >
                                                <div className="" role="document">
                                                   <div className="modal-content mt-10">
                                                      <div className="modal-header">
                                                      <h5 className="modal-title">Request Call Back</h5>
                                                      <a type="button" className="close" onClick={closeModal}>
                                                         Hide
                                                      </a>
                                                      </div>
                                                      <div className="modal-body">
                                                      <form onSubmit={handleSubmit}>
                                                         <div className="form-group">
                                                         <label>Full Name</label>
                                                         <input type="text" className={`form-control ${formErrors.fullName ? 'is-invalid' : ''}`} name="fullName" value={formData.fullName} onChange={handleChange} required />
                                                         <div className="invalid-feedback">{formErrors.fullName}</div>
                                                         </div>
                                                         <div className="form-group">
                                                         <label>Email</label>
                                                         <input type="email" className={`form-control ${formErrors.email ? 'is-invalid' : ''}`} name="email" value={formData.email} onChange={handleChange} required />
                                                         <div className="invalid-feedback">{formErrors.email}</div>
                                                         </div>
                                                         <div className="form-group">
                                                         <label>Mobile Number</label>
                                                         <input type="tel" className={`form-control ${formErrors.mobileNumber ? 'is-invalid' : ''}`} name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} required />
                                                         <div className="invalid-feedback">{formErrors.mobileNumber}</div>
                                                         </div>
                                                         <div className="form-group">
                                                         <label>Location</label>
                                                         <input type="text" className={`form-control ${formErrors.location ? 'is-invalid' : ''}`} name="location" value={formData.location} onChange={handleChange} required />
                                                         <div className="invalid-feedback">{formErrors.location}</div>
                                                         </div>
                                                         <input type="hidden" name="uniqueId" value="your-hidden-value" />
                                                         <button type="submit" className="btn btn-primary">Submit</button>
                                                      </form>
                                                      {submitMessage && <div className="mt-3">{submitMessage}</div>}
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             }
                                             {/* <hr /> */}
                                             {/* <div className="placement-asst">
                                                <h5>Placement Assistance</h5>
                                                <p>We provide LIVE on project training</p>
                                                <p>3 projects with Lab Assistance</p>
                                                <p>100% placement assurance</p>
                                             </div> */}
                                             {/* <hr /> */}
                                             {/* <div className="placement-asst" id="why_this_courses_section">
                                                <div className="inst-desc">
                                                   <h5 id="why_this_courses_title">Why This Course</h5>
                                                </div>
                                                <div className="inst-content" style={{ textAlign: 'justify' }}>
                                                   <div style={{ marginBottom: '1.5em' }}>
                                                      <p></p>
                                                      <p><span style2={{ color: 'rgb(32, 33, 36)', fontFamily: 'arial, sans-serif', fontSize: '16px' }}>What is the future scope of Java development? Because of its robust nature and security,&nbsp;</span><b style2={{ color: 'rgb(32, 33, 36)', fontFamily: 'arial, sans-serif', fontSize: '16px' }}>organizations prefer working in the Java environment</b><span style2={{ color: 'rgb(32, 33, 36)', fontFamily: 'arial, sans-serif', fontSize: '16px' }}>. The demand for Java professionals is high, specifically for the ones having certification from reputed institutions.</span><br /></p>
                                                      <p></p>
                                                   </div>
                                                </div>
                                             </div> */}
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </section>
                              <section className="mt-2p">
                                 <div className="container p-0">
                                    <div className="row">
                                       <div className="col-md-8 col-md-offset-4 description">

                                          
                                       <div className="you-learn p-2 bgdd" id="learn_skills_section">
                                             <div className="row">
                                                <div className="col-sm-12 col-md-12 col-xs-12">
                                                   <h5 id="learn_skills_title">Placement assistance</h5>
                                            
                                                     <div dangerouslySetInnerHTML={{ __html: pageData.placement_assistance }} />
                                                </div>
                                             </div>
                                          </div>

                                          <div className="you-learn p-2 bgdd" id="learn_skills_section">
                                             <div className="row">
                                                <div className="col-sm-12 col-md-12 col-xs-12">
                                                   <h5 id="learn_skills_title">You Will Learn</h5>
                                               
                                                    <div dangerouslySetInnerHTML={{ __html: pageData.you_will_learn }} />
                                                </div>
                                             </div>
                                          </div>

                                          {/* <div className="coursead_ex" style={{ height: '150px' }}>
                                             <a href="https://www.primevideo.com/storefront/home/ref=atv_dp_mv_c_9zZ8D2_1_0" target="_blank">
                                                <img src="https://careerhamster.com/admin/uploads/placements/placements-1650189852.png" />
                                             </a>
                                          </div> */}

                                          <div id="short_description_section" className="bgdd p-2">
                                             <div className="inst-desc">
                                                <h4 id="short_description_title" className="pl-1">Description</h4>
                                             </div>
                                             <div className="inst-content white_bg p-1 description" style={{ textAlign: 'justify' }}>
                                                <article style={{ marginBottom: '1.5em', maxHeight: 'none' }}>
                                                   <div dangerouslySetInnerHTML={{ __html: pageData.course_description }} />
                                                </article>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="row">

                                       {
                                          pageData.other_course.map((iteam, index) => (
                                             <div class="col-xl-3 col-md-6 col-sm-12">
                                                <div class="card-grid-2 hover-up menblock text-center">
                                                   <img className="placement_show_img" alt="jcbapp" src={iteam.logo} />
                                                   <div class="card-block-info border-top">
                                                      <h5 class="text-capitalize">{iteam.title}</h5>
                                                      <h6><span ><s><span >₹{iteam.original_price}</span></s></span>
                                                       ₹{iteam.final_price}</h6>
                                                      <div class="font-xs color-text-mutted overflowelips2">
                                                      {iteam.location}
                                                      </div>
                                                      <a class="btn btn-apply-now" href={`/training-and-placement-details?id=${iteam.id}`}>View More</a>
                                                      {/* <Link class="btn btn-apply-now" to={`/training-and-placement-details?id=${iteam.id}`}>View More</Link> */}
                                                   </div>
                                                </div>
                                             </div>
                                          ))
                                       }
                                    </div>


                                 </div>
                              </section>
                           </div>
                        </div>
                     </>
                     :
                     <>
                       <div class="row" >
                           <div className="col-lg-12 padding5percent">
                              <div class="loader"></div>
                           </div>
                        </div>
                     </>
               }
            </div>
         </section>
      {/* <div className={`modal ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Request Call Back</h5>
              <button type="button" className="close" onClick={closeModal}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
            <form onSubmit={handleSubmit}>
               <div className="form-group">
               <label>Full Name</label>
               <input type="text" className={`form-control ${formErrors.fullName ? 'is-invalid' : ''}`} name="fullName" value={formData.fullName} onChange={handleChange} required />
               <div className="invalid-feedback">{formErrors.fullName}</div>
               </div>
               <div className="form-group">
               <label>Email</label>
               <input type="email" className={`form-control ${formErrors.email ? 'is-invalid' : ''}`} name="email" value={formData.email} onChange={handleChange} required />
               <div className="invalid-feedback">{formErrors.email}</div>
               </div>
               <div className="form-group">
               <label>Mobile Number</label>
               <input type="tel" className={`form-control ${formErrors.mobileNumber ? 'is-invalid' : ''}`} name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} required />
               <div className="invalid-feedback">{formErrors.mobileNumber}</div>
               </div>
               <div className="form-group">
               <label>Location</label>
               <input type="text" className={`form-control ${formErrors.location ? 'is-invalid' : ''}`} name="location" value={formData.location} onChange={handleChange} required />
               <div className="invalid-feedback">{formErrors.location}</div>
               </div>
               <input type="hidden" name="uniqueId" value="your-hidden-value" />
               <button type="submit" className="btn btn-primary">Submit</button>
            </form>
            {submitMessage && <div className="mt-3">{submitMessage}</div>}
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className={`modal-backdrop ${showModal ? 'show' : ''}`}></div> */}
      </>
   );
}
export default Training_and_placements_details;