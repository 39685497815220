import React ,{useEffect}  from "react";
import { Nav, NavLink, NavMenu } from "../../components/NavbarElements";
import {useNavigate} from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import Form from 'react-bootstrap/Form';

import axios from "axios";

//import { MultiSelect } from "react-multi-select-component";


import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

class RecruiterSignup extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
                profileImgFile: null,
                profileeErrorMessage: '',
                resumeFileUpload: null,
                resumeErrorMessage: '',
                set_profile_pic_url : ((props.loginscreen == 'profile_update') ? props.logindata.data.profile_pic_url :  '') ,
                set_profile_bg_img_url : ((props.loginscreen == 'profile_update') ? props.logindata.data.profile_bg_img_url :  '') ,
                fields: {
                    full_name : ((props.loginscreen == 'profile_update') ? props.logindata.data.firstname :  '') ,
                    profile_bg_img_url : ((props.loginscreen == 'profile_update') ? props.logindata.data.profile_bg_img_url :  '') ,
                    profile_pic_url : ((props.loginscreen == 'profile_update') ? props.logindata.data.profile_pic_url :  '') ,
                    last_name : ((props.loginscreen == 'profile_update') ? props.logindata.data.lastname :  '') ,
                    job_title : ((props.loginscreen == 'profile_update') ? props.logindata.data.job_title :  '') ,
                    email_address : ((props.loginscreen == 'profile_update') ? props.logindata.data.email :  '') ,
                    phone_number :  ((props.loginscreen == 'profile_update') ? props.logindata.data.mobile :  ''),
                    username :  ((props.loginscreen == 'profile_update') ? props.logindata.data.username :  ''),
                    password : '',
                    re_password : '',
                    enter_skype_id :  ((props.loginscreen == 'profile_update') ? props.logindata.data.skype_id :  '') ,
                    short_desc :  ((props.loginscreen == 'profile_update') ? props.logindata.data.short_desc :  ''),
                    join_status :  ((props.loginscreen == 'profile_update') ? props.logindata.data.join_status :  ''),
                    intrusted_in : '',
                    jobs_skill_key : [], 
                    user_state : ((props.loginscreen == 'profile_update') ? props.logindata.data.user_state :  ''),
                    user_city : ((props.loginscreen == 'profile_update') ? props.logindata.data.user_city :  ''),
                    resume_doc : ((props.loginscreen == 'profile_update') ? props.logindata.data.user_resume_url :  ''),
                    user_prefered_state : ((props.loginscreen == 'profile_update') ? ((props.logindata.data.user_prefered_state) ? props.logindata.data.user_prefered_state : 0) :  0),
                    user_prefered_location :  ((props.loginscreen == 'profile_update') ? ((props.logindata.data.user_prefered_location) ? props.logindata.data.user_prefered_location : 0) :  0),
                    prefered_location_1 :  ((props.loginscreen == 'profile_update') ? props.logindata.data.prefered_location_1 :  ''),
                    prefered_location_2 :  ((props.loginscreen == 'profile_update') ? props.logindata.data.prefered_location_2 :  ''),
                    prefered_location_3 :  ((props.loginscreen == 'profile_update') ? props.logindata.data.prefered_location_3 :  ''),
                    prefered_state_1 :  ((props.loginscreen == 'profile_update') ? props.logindata.data.prefered_state_1 :  ''),
                    prefered_state_2 :  ((props.loginscreen == 'profile_update') ? props.logindata.data.prefered_state_2 :  ''),
                    prefered_state_3 :  ((props.loginscreen == 'profile_update') ? props.logindata.data.prefered_state_3 :  ''),
                    total_salary_hourly :  ((props.loginscreen == 'profile_update') ? props.logindata.data.total_salary_hourly :  ''),
                    total_salary :  ((props.loginscreen == 'profile_update') ? props.logindata.data.total_salary :  ''),
                    total_expyear :  ((props.loginscreen == 'profile_update') ? props.logindata.data.total_expyear :  ''),
                    total_expmonths :  ((props.loginscreen == 'profile_update') ? props.logindata.data.total_expmonths :  ''),
                    accept_agreeiment : ((props.loginscreen == 'profile_update') ? props.logindata.data.accept_agreeiment :'') , 
                    user_id : (props.loginscreen == 'profile_update') ? props.logindata.data.id :  0
                } ,
                error_class : {} ,
                errors : {} ,
                loading : false ,
                showPassword: false,
                submitbtntext : (props.loginscreen == 'profile_update') ? 'Update' : 'Register Now',
                industryTypeList : [] ,
                stateListFetchData : [] ,
                jsStatusListData : [] ,
                cityListFetchData : [] ,
                cityListFetchDataMulti :  [] ,
                cityListFetchDataMulti2 :  [] ,
                cityListFetchDataMulti3 :  [] ,
                multiSelectedCity : [] ,
                multi_input : [] ,
                isValidation : false ,
                experienceYears : [1,2,3,4,5,6,7,8,9,10,10,11,12,13,14,15,16,17,18,19,20] ,
                experienceMonths : [1,2,3,4,5,6,7,8,9,10,10,11,12] ,
                instrustedIn : ((props.loginscreen == 'profile_update') ?  JSON.parse(props.logindata.data.key_skills) : []),
                location_2_hide : (props.loginscreen == 'profile_update') ? 3 :  1 ,
                loginscreen_status : (props.loginscreen == 'profile_update') ? 'login' : 'signup' ,
            };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        
        //console.log(JSON.parse(props.logindata.data.key_skills)) //firstname
        //console.log(props.loginscreen)
    }

    //experienceYears , experienceMonths
    validated  = (toggle = false) => {
        return true
        return this.setState({isValidation: toggle})
    }

    componentDidMount() {
       
        (async () => {
            try {
            const states_list = await axios.get("https://jobchatbox.com/api/home/api/states_list");
            if(states_list.data.status == 1){
                this.setState({
                    stateListFetchData: states_list.data.data.results,
                });
            }
            } catch (error) {
                console.log(error);
            }
        })();

        (async () => {
            try {
            const states_list = await axios.get("https://jobchatbox.com/api//home/api/jobseeker_jobstype");
            if(states_list.data.status == 1){
                this.setState({
                    jsStatusListData: states_list.data.data.results
                });
            }
            } catch (error) {
            console.log(error);
            }
        })();
        
        (async () => {
            try {
            const states_list = await axios.get("https://jobchatbox.com/api//home/api/industry_type_list");
            if(states_list.data.status == 1){
                this.setState({
                    industryTypeList: states_list.data.data.results
                });
            }
            } catch (error) {
            console.log(error);
            }
        })();
        if(this.state.loginscreen_status == 'login'){
           
            if(this.state.fields.user_state){
                //console.log("0")
                this.getCityByStateCodeData(this.state.fields.user_state,'usa_city_list','0');
            }
            
            if(this.state.fields.prefered_state_1){
                //console.log("1")
                this.getCityByStateCodeData(this.state.fields.prefered_state_1,'usa_city_list','1');
            }
            if(this.state.fields.prefered_state_2){
               // console.log("2")
                this.getCityByStateCodeData(this.state.fields.prefered_state_2,'usa_city_list','2');
            }
            if(this.state.fields.prefered_state_3){
                //console.log("3")
               // console.log(this.state.fields.user_state);
                this.getCityByStateCodeData(this.state.fields.prefered_state_3,'usa_city_list','3');
            }
            
        }
    }

    getCityByStateCodeData  =  (statecode='0',apitype='usa_city_list',argPass) => {
        (async () => {
            try {
            const city_list = await axios.get("https://jobchatbox.com/api/home/api/"+apitype+"/"+statecode);
            if(city_list.data.status == 1){ 
               // console.log(argPass);
                //console.log(city_list.data.data.results);
                if(argPass == 1){ 
                    this.setState({cityListFetchDataMulti: city_list.data.data.results});
                } else if(argPass == 2){
                    this.setState({cityListFetchDataMulti2: city_list.data.data.results});
                } else if(argPass == 3){
                    this.setState({cityListFetchDataMulti3: city_list.data.data.results});
                } else {
                    this.setState({cityListFetchData: city_list.data.data.results});
                }
            }
            } catch (error) {
                    console.log(error);
            }
        })();
    }

    getCityByStateCode = (e) => {
        this.getCityByStateCodeData(e.target.value,'usa_city_list','0');
    } 
   
    getCityByStateCodeMulti = (e) => {
        this.getCityByStateCodeData(e.target.value,'usa_city_list','1');
    } 

    getCityByStateCodeMulti2 = (e) => {
        this.getCityByStateCodeData(e.target.value,'usa_city_list','2');
    } 

    getCityByStateCodeMulti3 = (e) => {
        this.getCityByStateCodeData(e.target.value,'usa_city_list','3');
    } 
    
    setCityLocation = (multiSelectedCity) => {
        this.setState({ multiSelectedCity }, () =>
            console.log(`Option selected:`, this.state.multiSelectedCity)
        );
    } 

    handleChange(event) {
        let fields = this.state.fields;
        fields[event.target.name] = event.target.value;
        this.setState({
            fields
        });


        // if((event.target.name  == 'password'  || event.target.name  == 're_password') && (fields.re_password.length >= fields.password.length) ){
            if((event.target.name  == 'password'  || event.target.name  == 're_password') ){
                let errors = {}; let error_class = {}; 
                const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
                if(!strongRegex.test(fields.password)){ error_class["password"] = "error_class" ;  errors["password"] = "*Password must contain at least 8 characters, including uppercase letters, lowercase letters, and numbers.";  this.setState({ errors: errors});this.setState({ error_class: error_class});  }
                else {  errors["password"] = ""; error_class["password"] = "" ; this.setState({ errors: errors});this.setState({ error_class: error_class});  }
                if((fields.re_password.length > 0) && (fields.password != fields.re_password)){ error_class["re_password"] = "error_class" ; errors["re_password"] = "*Confirm-password  should be match with Password"; this.setState({ errors: errors});this.setState({ error_class: error_class}); } 
                else { errors["re_password"] = ""; error_class["re_password"] = "" ; this.setState({ errors: errors});this.setState({ error_class: error_class}); }
            }
        
        if(event.target.name  ==  'profile_image'){
            const file = event.target.files[0];
            this.setState({ set_profile_pic_url: URL.createObjectURL(file) });
        }
        
       

        if(event.target.name == 'accept_agreeiment'){
            if (event.target.checked) {
                //console.log('✅ Checkbox is checked');
                this.state.fields.accept_agreeiment = 'accepted';
            } else {
               // console.log('⛔️ Checkbox is NOT checked');
                this.state.fields.accept_agreeiment = '';
            }
        }
        
        if(event.target.name == 'short_desc'){
            this.state.fields.short_desc = event.target.value;
        }        
    }
     

    handleProfileFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (!selectedFile.type.startsWith('image/')) {
            this.setState({ profileeErrorMessage: 'Please select an image file (JPEG, PNG).' });
            this.resetFileInput();
            return;
            }

            // Check file size (5MB limit)
            const maxSize = 5 * 1024 * 1024; // 5MB in bytes
            if (selectedFile.size > maxSize) {
            this.setState({ profileeErrorMessage: 'File size should be less than 5MB.' });
            this.resetFileInput();
            return;
            }

            // File is valid, update state and do further processing (e.g., upload)

            this.setState({ set_profile_pic_url: URL.createObjectURL(selectedFile) });

            //

             // document.getElementById("sidebar_profile_pic_url").innerHTML = URL.createObjectURL(selectedFile) ;
            this.setState({
                profileImgFile: selectedFile,
                profileeErrorMessage: '',
            });
        }
    };
    resetFileInput = () => {
        this.fileInput.value = '';
    };

    handleDocumentFileChange = (e) => {
        const selectedFile = e.target.files[0];
        //alert();
        if (selectedFile) {
            const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

            if (!allowedTypes.includes(selectedFile.type)) {
                this.setState({ resumeErrorMessage: 'Please select a PDF or Word document.' });
                this.resetFileInput();
                return;
            }
            // Check file size (5MB limit)
            const maxSize = 5 * 1024 * 1024; // 5MB in bytes
            if (selectedFile.size > maxSize) {
            this.setState({ resumeErrorMessage: 'File size should be less than 5MB.' });
            this.resetFileInput();
            return;
            }

            // File is valid, update state and do further processing (e.g., upload)
            this.setState({
                resumeFileUpload: selectedFile,
                resumeErrorMessage: '',
            });
        }
    };

    resetDocFileInput = () => {
        this.fileInput.value = '';
    };

    //handleSubmit(event) {


    handleSubmit2 = async (event) => {
        event.preventDefault();
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
          event.stopPropagation();
        } else {
            const formData = new FormData();
            formData.append('profile_image', event.target.profile_image.files[0]);
            formData.append('resume_doc', event.target.resume_doc.files[0]);
            formData.append('first_name', event.target.full_name.value);
            formData.append('last_name', event.target.last_name.value);
            formData.append('job_title', event.target.job_title.value);
            formData.append('email', event.target.email_address.value);
            formData.append('phone_number', event.target.phone_number.value);
            formData.append('username', event.target.username.value);

            if(this.state.loginscreen_status != 'login'){
            formData.append('password', event.target.password.value);
            }
            formData.append('join_status', event.target.join_status.value);
            formData.append('enter_skype_id', event.target.enter_skype_id.value);
            formData.append('job_industry', event.target.intrusted_in.value);
            formData.append('key_skills', JSON.stringify(this.state.instrustedIn)); 
            formData.append('short_desc', event.target.short_desc.value);
            formData.append('user_state', event.target.user_state.value);
            formData.append('user_city', event.target.user_city.value);
            formData.append('total_expyear', event.target.total_expyear.value);
            formData.append('total_expmonths', event.target.total_expmonths.value);
            formData.append('total_salary', event.target.total_salary.value);
            formData.append('total_salary_hourly', event.target.total_salary_hourly.value);


            let API_URL  =  "https://jobchatbox.com/api/signup/jobseeker";
            if(this.state.loginscreen_status == 'login'){
                //API_URL  =  "https://jobchatbox.com/api/profile/profile_update"; 
                formData.append('user_id', this.state.fields.user_id);
            }

            var input = document.getElementsByName('user_prefered_state[]');
                var user_prefered_array =  [];
                for (var i = 0; i < input.length; i++) {
                    let a = input[i];
                    user_prefered_array[i] = a.value
                }

            var input2 = document.getElementsByName('prefered_location[]');
            var prefered_location_array =  [];
            for (var i = 0; i < input2.length; i++) {
                let a = input2[i];
                prefered_location_array[i] = a.value
            }

            formData.append('user_prefered_state', user_prefered_array);
            formData.append('user_prefered_location', prefered_location_array ); 
            
            formData.append('accept_agreeiment', event.target.accept_agreeiment.value);
            console.log(event.target.short_desc.value);
            if (this.validateForm()) {
                this.state.loading = true ;
                this.state.submitbtntext = 'Please Wait...' ;
                try {
                    let res = await fetch(API_URL , {
                        method: "POST",
                        body: formData ,
                    });
                    let resJson = await res.json();
                    if (res.status === 200) {
                        this.setState({ loading: false});
                        this.setState({ submitbtntext: 'Register Now'});

                        (resJson.status == 1) ?  this.notify('success',resJson.message) : this.notify('warning',resJson.message) ;
                        if(resJson.status == 1){
                            if(this.state.loginscreen_status == 'login'){ localStorage.setItem('userloginSession',JSON.stringify(resJson.data)) ;
                            } else { setTimeout(function() { window.location.href = "/thank-you";}, 1000); }
                        } 
                    } else { this.notify('error','Some error occured.');}
                } catch (err) {
                    console.log(err);
                }
            }
        }

        //this.validated(true)
        //setValidated(true);  
    }

    notify = (status_type,message) => {
        switch (status_type) {
            case 'warning':
                toast.warning(message)  
            break;

            case 'success':
                toast.success(message)  
            break;

            case 'info':
                toast.info(message)  
            break;

            case 'error':
                toast.error(message)  
            break;
        }
    };

    validateForm() {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
  
        if (!fields["full_name"]) {
            formIsValid = false;
            errors["full_name"] = "*Please enter your Full Name.";
        }
        
  
        
        if (!fields["accept_agreeiment"]) {
            formIsValid = false;
            errors["accept_agreeiment"] = "*Please accept agreeiment.";
        }
        
      
  
        if (!fields["email_address"]) {
          formIsValid = false;
          errors["email_address"] = "*Please enter your email-ID.";
        }
  
        if (typeof fields["email_address"] !== "undefined") {
          //regular expression for email validation
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(fields["email_address"])) {
            formIsValid = false;
            errors["email_address"] = "*Please enter valid email-ID.";
          }
        }
  
        // if (!fields["phone_number"]) {
        //   formIsValid = false;
        //   errors["phone_number"] = "*Please enter your mobile no.";
        // }
  
        // if (typeof fields["phone_number"] !== "undefined") {
        //   if (!fields["phone_number"].match(/^[0-9]{10}$/)) {
        //     formIsValid = false;
        //     errors["phone_number"] = "*Please enter valid mobile no.";
        //   }
        // }
  

        if(this.state.loginscreen_status != 'login'){
            if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "*Please enter your password.";
            }
    
            // if (typeof fields["password"] !== "undefined") {
            //   if (!fields["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
            //     formIsValid = false;
            //     errors["password"] = "*Please enter secure and strong password.";
            //   }
            // }
            if (!fields["re_password"]) {
                formIsValid = false;
                errors["re_password"] = "*Please enter your confirm password.";
            } else if(fields["re_password"] != fields["password"]){
                formIsValid = false;
                errors["re_password"] = "*Confirm-password  should be match with Password";
            }
        }
  
        this.setState({
          errors: errors
        });
        return formIsValid;

    }
 
    teghandleChange =  (tags) => {
        this.setState({instrustedIn:tags})
    }
    
    handleClickLocation1 = (event) => {
        event.preventDefault();
        let ddd  = (this.state.location_2_hide == 1) ? 2 : ((this.state.location_2_hide == 2) ? 3 : 1)
        this.setState({location_2_hide: ddd});
    }
    
    removeLocation2 = (event) => {
        event.preventDefault();
        this.setState({location_2_hide: 1});
    }
    
    removeLocation3 = (event) => {
        event.preventDefault();
        this.setState({location_2_hide: 2});
    }

    profileBgUpload =  async (e) => {
        var storedNames = JSON.parse(localStorage.getItem("userlogin"));

        if(e.target.name  ==  'profile_background'){
            const file = e.target.files[0];
            this.setState({ set_profile_bg_img_url: URL.createObjectURL(file) });
        }

        e.preventDefault();
        let fileName = e.target.files[0].name;
        let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        var ImageExt  = ['png','jpeg' , 'PNG' , 'JPEG'];
        if(ImageExt.includes(extension)){
            const formData = new FormData();
            formData.append('profile_bg_img', e.target.files[0]);
            formData.append('logintoken', storedNames.login_token );
            try {
               axios.post('https://jobchatbox.com/api/profile/profile_bg_upload', formData)
                  .then((response) => {
                    console.log(response);
                    //setIsSubmitting(false);
                  })
                  .catch((error) => {
                    console.error(error);
                   // setIsSubmitting(false);
                }); 
            } catch (err) {
                console.log(err);
            }
        }
    }


    handlePasswordToggle = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    };

    

    render() {

        //console.log(this.state.fields);
        return(
            <>
            <ToastContainer />
                <Form validated={this.validated} onSubmit={this.handleSubmit} className="login-register text-start mt-20"  autoComplete="off" >
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group mb-10">
                                <label className="form-label" htmlFor="input-1">First Name *</label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="full_name"
                                    placeholder="First Name"
                                    value={this.state.fields.full_name}  
                                    onChange={this.handleChange}
                                />
                                <div className="errorMsg">{this.state.errors.full_name}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mb-10">
                                <label className="form-label" htmlFor="input-1">Last Name *</label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="last_name"
                                    placeholder="Last Name"
                                    value={this.state.fields.last_name}  
                                    onChange={this.handleChange}
                                />
                                <div className="errorMsg">{this.state.errors.full_name}</div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-10">
                                <label class="form-label">Job Profile*</label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="job_title"
                                    placeholder="Job Title"
                                    value={this.state.fields.job_title}  
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group mb-10">
                                <label className="form-label" htmlFor="input-2">Email *</label>
                                <Form.Control
                                    required
                                    type="email"
                                    readOnly = {(this.state.loginscreen_status == 'login') ?  true : false}
                                    name="email_address"
                                    placeholder="stevenjob@gmail.com"
                                    value={this.state.fields.email_address}  
                                    onChange={this.handleChange}
                                />
                                <div className="errorMsg">{this.state.errors.email_address}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mb-10">
                                <label className="form-label" htmlFor="input-6">Phone Number *</label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="phone_number"
                                    placeholder="(xxx) xxx-xxxx"
                                    value={this.state.fields.phone_number}  
                                    onChange={this.handleChange}
                                    maxLength={14}
                                />
                                <div className="errorMsg">{this.state.errors.phone_number}</div>
                            </div>
                        </div>
                    
                        {/* password */}
                        {
                            (this.state.loginscreen_status != 'login') ?
                            <>
                            <div className="col-md-6">
                                <div className="form-group mb-10 passwordwrap">
                                    <label className="form-label" htmlFor="input-4">Password *</label>
                                    <Form.Control
                                        required
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        name="password"
                                        placeholder="************"
                                        value={this.state.fields.password}  
                                        onChange={this.handleChange}
                                        className={this.state.errors.error_class}
                                    />
                                    <span class="eyebtn"  onClick={this.handlePasswordToggle} >
                                        <img src="front/assets/imgs/eye.svg" />
                                    </span>
                                    <div className="errorMsg">{this.state.errors.password}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-10 passwordwrap">
                                    <label className="form-label" htmlFor="input-5">Re-Password *</label>
                                    <Form.Control
                                        required
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        name="re_password"
                                        placeholder="************"
                                        value={this.state.fields.re_password}  
                                        onChange={this.handleChange}
                                        className={this.state.errors.re_password}
                                    />
                                    <span class="eyebtn"  onClick={this.handlePasswordToggle} >
                                        <img src="front/assets/imgs/eye.svg" />
                                    </span>
                                    <div className="errorMsg">{this.state.errors.re_password}</div>
                                </div>
                            </div>
                            </> : ''
                        }
                        
                        {/* join status */}
                        <div className="col-md-6">
                            <div className="form-group mb-10">
                            <label className="form-label" htmlFor="input-5">Join Status *</label>
                                <Form.Select 
                                    required
                                    className="form-control no-pad" 
                                    name="join_status" 
                                    id="join_status" >
                                    <option  selected={true} value="" >Select</option> 
                                    {
                                    this.state.jsStatusListData.map((item,index) => (
                                        <option  selected={this.state.fields.join_status ==  item.job_type_value ? true : false}  key={index} value={item.job_type_value}>{item.job_type_value}</option>
                                    ))
                                    }   
                                </Form.Select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group selectdesign">
                                <label className="form-label">Interested In *</label>
                                <Form.Select 
                                    className="form-control no-pad" 
                                    name="intrusted_in" 
                                    id="intrusted_in" >
                                    <option  selected={true}  value="0" >Select</option> 
                                    {
                                    this.state.industryTypeList.map((item,index) => (
                                        <option   key={index} value={item.id}>{item.industry}</option>
                                    ))
                                    }   
                                </Form.Select>
                                <div className="errorMsg">{this.state.errors.intrusted_in}</div>
                            </div>
                        </div>

                         {/* jobs skills */}
                        <div className="col-md-12">
                            <div className="form-group mb-10">
                            <label className="form-label" htmlFor="input-5">Jobs Key Skills *</label>
                                <TagsInput className="inputtypeTagsKey" value={this.state.instrustedIn} onChange={this.teghandleChange} />
                            </div>
                        </div>

                        {/* Location for address */}
                        <div className="col-md-6">
                            <div className="form-group mb-10">
                                <label className="form-label" htmlFor="input-7">Current location/State</label>
                                <Form.Select 
                                    className="form-control no-pad" 
                                    onChange={this.getCityByStateCode} 
                                    name="user_state" 
                                    id="user_state" >
                                    <option  selected={true} value="0" >Select</option> 
                                    {
                                    this.state.stateListFetchData.map((item,index) => (
                                        <option selected={this.state.fields.user_state == item.state_code ? true :false}   key={index} value={item.state_code}>{item.state_name}</option>
                                    ))
                                    }   
                                </Form.Select>
                                <div className="errorMsg">{this.state.errors.location}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mb-10">
                                <label className="form-label" htmlFor="input-7">Current City</label>
                                <Form.Select 
                                    className="form-control no-pad" 
                                    name="user_city" 
                                    id="user_city" 
                                    aria-label="Default select example">
                                    <option   value="" >Select</option> 
                                        {
                                        this.state.cityListFetchData.map((item,index) => (
                                            <option selected={this.state.fields.user_city == item.id ? true :false}   key={index} value={item.id}>{item.city_name}</option>
                                        ))
                                        }
                                </Form.Select> 
                                <div className="errorMsg">{this.state.errors.location}</div>
                            </div>
                        </div>
                      
                        {/* Location for address prefered 1 */}
                        <div className="col-md-6">
                            <div className="form-group mb-10">
                                <label className="form-label" htmlFor="input-7">Prefered State 1*</label>
                                <Form.Select 
                                    required 
                                    className="form-control no-pad" 
                                    onChange={this.getCityByStateCodeMulti} 
                                    name="user_prefered_state[]" 
                                    id="user_prefered_state" >
                                    <option  selected={true} value="" >Select</option> 
                                    {
                                    this.state.stateListFetchData.map((item,index) => (
                                        <option selected={(this.state.fields.prefered_state_1 == item.state_code) ? true : false }   key={index} value={item.state_code}>{item.state_name}</option>
                                    ))
                                    }   
                                </Form.Select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mb-10">
                                <label className="form-label" htmlFor="input-7">Prefered City 1*</label>
                                <Form.Select 
                                    required
                                    className="form-control no-pad" 
                                    name="prefered_location[]" 
                                    id="prefered_location" 
                                    aria-label="Default select example">
                                    <option  selected={true} value="" >Select</option> 
                                        {
                                        this.state.cityListFetchDataMulti.map((item,index) => (
                                            <option selected={(this.state.fields.prefered_location_1 == (item.id+'__'+item.city_name)) ? true : false }   key={index} value={item.id+'__'+item.city_name}>{item.city_name}</option>
                                        ))
                                        }
                                </Form.Select>
                            </div>
                        </div>
                        
                        {/* *************** */}
                         {/* Location for address prefered 2 */}
                        <div  className={  (this.state.location_2_hide == 1 || this.state.location_2_hide == 3) ? 'display_none' : ''  } >
                            <a onClick={this.removeLocation2} className="remove_location_2 remove_location_button">Remove Location 2</a>
                        </div>
                        <div className={  (this.state.location_2_hide == 1) ? 'display_none' : 'col-md-6'  } >
                            <div className="form-group mb-10">
                                <label className="form-label" htmlFor="input-7">Prefered State 2</label>
                                <Form.Select 
                                    className="form-control no-pad" 
                                    onChange={this.getCityByStateCodeMulti2} 
                                    name="user_prefered_state[]" 
                                    id="user_prefered_state2" >
                                    <option  selected={true} value="" >Select</option> 
                                    {
                                    this.state.stateListFetchData.map((item,index) => (
                                        <option  selected={(this.state.fields.prefered_state_2 == item.state_code) ? true : false }  key={index} value={item.state_code}>{item.state_name}</option>
                                    ))
                                    }   
                                </Form.Select>
                            </div>
                        </div>
                        <div className={  (this.state.location_2_hide == 1) ? 'display_none' : 'col-md-6'  }  >
                            <div className="form-group mb-10">
                                <label className="form-label" htmlFor="input-7">Prefered City 2</label>
                                <Form.Select 
                                    className="form-control no-pad" 
                                    name="prefered_location[]" 
                                    id="prefered_location2" 
                                    aria-label="Default select example">
                                    <option  selected={true} value="" >Select</option> 
                                        {
                                        this.state.cityListFetchDataMulti2.map((item,index) => (
                                            <option  selected={(this.state.fields.prefered_location_2 == (item.id+'__'+item.city_name)) ? true : false } key={index} value={item.id+'__'+item.city_name}>{item.city_name}</option>
                                        ))
                                        }
                                </Form.Select> 
                            </div>
                        </div>

                        {/* Location for address prefered 3 */}
                        <div className={  (this.state.location_2_hide == 1 || this.state.location_2_hide == 2 || this.state.loginscreen_status == 'login') ? 'display_none' : ''  }  >
                            <a onClick={this.removeLocation3} className="remove_location_3 remove_location_button">Remove Location 3</a>
                        </div>
                        <div className={  (this.state.location_2_hide == 1 || this.state.location_2_hide == 2) ? 'display_none' : 'col-md-6'  } >
                            <div className="form-group mb-10">
                                <label className="form-label" htmlFor="input-7">Prefered State 3</label>
                                <Form.Select 
                                    className="form-control no-pad" 
                                    onChange={this.getCityByStateCodeMulti3} 
                                    name="user_prefered_state[]" 
                                    id="user_prefered_state3" >
                                    <option  selected={true} value="" >Select</option> 
                                    {
                                    this.state.stateListFetchData.map((item,index) => (
                                        <option selected={(this.state.fields.prefered_state_3 == item.state_code) ? true : false }  key={index} value={item.state_code}>{item.state_name}</option>
                                    ))
                                    }   
                                </Form.Select>
                            </div>
                        </div>
                        <div className={  (this.state.location_2_hide == 1 || this.state.location_2_hide == 2) ? 'display_none' : 'col-md-6'  } >
                            <div className="form-group mb-10">
                                <label className="form-label" htmlFor="input-7">Prefered City 3</label>
                                <Form.Select 
                                    className="form-control no-pad" 
                                    name="prefered_location[]" 
                                    id="prefered_location3" 
                                    aria-label="Default select example">
                                    <option  selected={true} value="" >Select</option> 
                                        {
                                        this.state.cityListFetchDataMulti3.map((item,index) => (
                                            <option selected={(this.state.fields.prefered_location_3 == (item.id+'__'+item.city_name)) ? true : false }  key={index} value={item.id+'__'+item.city_name}>{item.city_name}</option>
                                        ))
                                        }
                                </Form.Select> 
                            </div>
                        </div>
                        {/* end  */}

                        <div>
                            <button  className={  (this.state.location_2_hide == 3) ? 'display_none' : 'btn btn-primary add_more_location_btn'  } onClick={this.handleClickLocation1} >Add More Location+</button>
                        </div>

                        {/* ******* total experience ******** */}
                        <div className="col-md-6">
                            <div className="form-group mb-10">
                                <label className="form-label" htmlFor="input-7">Experience Years *</label>
                                <Form.Select 
                                    required 
                                    className="form-control no-pad" 
                                    name="total_expyear" 
                                    id="total_expyear" >
                                    <option value="0" >Select</option> 
                                     
                                    {
                                      this.state.experienceYears.map((item,index) => (
                                            <option  selected={ (this.state.fields.total_expyear == item) ? true :false }  key={index} value={item}>{item} { (item == 1) ? 'Year' : 'Years' }</option>
                                        ))
                                    } 
                                    
                                </Form.Select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mb-10">
                                <label className="form-label" htmlFor="input-7">Experience Months *</label>
                                <Form.Select 
                                    required 
                                    className="form-control no-pad" 
                                    name="total_expmonths" 
                                    id="total_expmonths" >
                                    <option value="0" >Select</option> 
                                    {
                                      this.state.experienceMonths.map((item,index) => (
                                            <option  selected={ (this.state.fields.total_expmonths == item) ? true :false }  key={index} value={item}>{item} { (item == 1) ? 'Month' : 'Months' }</option>
                                        ))
                                    }   
                                </Form.Select>
                            </div>
                        </div>


                        {/* ******* Salary ******** */}
                        <div className="col-md-6">
                            <div className="form-group mb-10">
                                <label className="form-label" htmlFor="input-7">Expected Salary Hourly (in INR) *</label>
                                <Form.Control
                                    required
                                    type='number'
                                    step="0.1"
                                    min='0'
                                    name="total_salary_hourly"
                                    placeholder="Expected salary (in INR)"
                                    value={this.state.fields.total_salary_hourly}  
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mb-10">
                                <label className="form-label" htmlFor="input-7">Expected Salary Yearly (in INR) *</label>
                                <Form.Control
                                    required
                                    type='number'
                                    step="0.1"
                                    min='0'
                                    name="total_salary"
                                    placeholder="Expected salary (in INR)"
                                    value={this.state.fields.total_salary}  
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>

                        {/* ******* Description ******** */}
                        <div className="col-md-12">
                            <div className="form-group mb-10">
                                <label className="form-label">Description</label>
                                <textarea name="short_desc" className="form-control" maxLength="200"  style={{height: '65px'}} onChange={this.handleChange}  value={this.state.fields.short_desc} >{this.state.fields.short_desc}</textarea>
                                <div className="errorMsg">{this.state.errors.short_desc}</div>
                            </div>
                        </div>

                         {/* ******* file upload ******** */}
                        <div className="col-md-6">
                            <div className="form-group mb-10">
                                <label className="form-label" htmlFor="resume_doc">UPLOAD CV*</label>

                                {
                                    (this.state.loginscreen_status == 'login') ? 
                                    <>
                                    <input className="form-control" onChange={this.handleDocumentFileChange} ref={(ref) => (this.resetDocFileInput = ref)}  id="resume_doc" name="resume_doc" type="file" accept=".doc, .docx,.pdf" />
                                     {this.state.resumeErrorMessage && <p style={{ color: 'red' }}>{this.state.resumeErrorMessage}</p>}
                                    </>
                                    
                                   : <>
                                        <Form.Control
                                            type="file"
                                            required
                                            name="resume_doc"
                                            onChange={this.handleDocumentFileChange}
                                            ref={(ref) => (this.fileInput = ref)}
                                            accept=".doc, .docx,.pdf"
                                        />
                                        {this.state.resumeErrorMessage && <p style={{ color: 'red' }}>{this.state.resumeErrorMessage}</p>}

                                    </>
                                }
                                {console.log(this.state.fields.resume_doc)}
                                {  
                                (this.state.loginscreen_status == 'login' && this.state.fields.resume_doc) ? 
                                <>
                                <a href={this.state.fields.resume_doc} target="_blank"  className="text-blue-hyperlink" ><span class="fa fa-download" aria-hidden="true"></span> Download Resume</a>
                                </> 
                                : ''
                                }
                                
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group mb-10">

                                <label className="form-label" htmlFor="profile_image">Profile Image *</label>
                                
                                
                                {
                                    (this.state.loginscreen_status == 'login') ? 
                                    <>
                                        <input className="form-control" ref={(ref) => (this.fileInput = ref)} onChange={this.handleProfileFileChange}    id="profile_image" name="profile_image" type="file" accept="image/png, image/gif, image/jpeg" />
                                        {this.state.profileeErrorMessage && <p style={{ color: 'red' }}>{this.state.profileeErrorMessage}</p>}
                                    </>
                                    : 
                                    <>
                                        {/* <input className="form-control" value={this.state.fields.profile_image}  onChange={this.handleChange}  id="profile_image" name="profile_image" type="file" accept="image/png, image/gif, image/jpeg" /> */}

                                        <Form.Control
                                            type="file"
                                            required
                                            name="profile_image"
                                            ref={(ref) => (this.fileInput = ref)}
                                            onChange={this.handleProfileFileChange} 
                                            accept="image/png, image/gif, image/jpeg"
                                        />
                                        {this.state.profileeErrorMessage && <p style={{ color: 'red' }}>{this.state.profileeErrorMessage}</p>}
                                    </>
                                }
                                
                                <div className="errorMsg">{this.state.errors.profile_image}</div>
                                { 
                                (this.state.loginscreen_status == 'login') ? 
                                <>
                                <img src={this.state.set_profile_pic_url} className="profile_imgupload"></img>
                                </> 
                                : ''
                                }

                                <Form.Control
                                    type="hidden"
                                    name="username"
                                    placeholder="Username"
                                    value={this.state.fields.username}  
                                    onChange={this.handleChange}
                                />
                               
                                
                                
                            </div>
                        </div>

                        { 
                        (this.state.loginscreen_status == 'login') ? 
                        <>
                            <div className="col-md-12">
                                <div className="form-group mb-10">
                                    <label className="form-label" htmlFor="profile_background">Profile Backgound</label>
                                    <input className="form-control" onChange={this.profileBgUpload} id="profile_background" name="profile_background" type="file" accept="image/png, image/gif, image/jpeg"   />
                                </div>
                                <img src={this.state.set_profile_bg_img_url} className="bg_imgupload"></img>
                            </div>
                        </> 
                        : ''
                        }
                        
                        {/* ******* accept agreeiment ******** */}
                        <div className="col-md-12">

                       
                            <div className="login_footer form-group d-flex justify-content-between">
                                <label className="cb-container1"> 
                                    <Form.Check
                                        required
                                        className={'accept_agreeiment'}
                                        label={<><span className="text-small">By clicking, you agree with 
                                        <NavLink className='text-blue-hyperlink' target="_blank"  to="/privacy-policy"  > privacy policy</NavLink> and 
                                        <NavLink className='text-blue-hyperlink' target="_blank"  to="/terms-of-service"  > terms of use </NavLink></span>
                                        </>}
                                        feedback="You must agree before submitting."
                                        feedbackType="invalid"
                                        checked={((this.state.fields.accept_agreeiment == 'accepted' || this.state.fields.accept_agreeiment == 'on') ? true : false) } onChange={this.handleChange}  name="accept_agreeiment"
                                    />
                                    {/* <input required={true}   type="checkbox" /> */}
                                    <div className="errorMsg">{this.state.errors.accept_agreeiment}</div>
                                </label>
                                <NavLink className='text-blue-hyperlink' target="_blank" to="/aboutus"  >Lean more</NavLink> 
                            </div>
                        </div>

                        {/* ******* form submit ******** */}
                        <input className="form-control" value={this.state.fields.enter_skype_id}  onChange={this.handleChange} id="input-7" type="hidden" required="" name="enter_skype_id" placeholder="Enter Skype Id" />

                        <div className="col-md-12">
                            <div className="form-group mb-10">
                                <button  className={(this.state.fields.user_id != 0) ? 'btn btn-default btn-brand icon-tick' : 'btn btn-brand-1 hover-up w-100'} type="submit" name="login" disabled={this.state.loading}>
                                    {this.state.submitbtntext}
                                </button>
                            </div>
                        </div>
                         {/* end */}
                    </div>
                </Form>    
            </>
        );
    }
}
//export default RecruiterSignup;

export default function(props) {
    
    let loginscreen =  (props.userscrenn == 'login') ? 'profile_update' : 'signup'
    if(props.userscrenn != 'login'){
        const navigate = useNavigate();
        useEffect(() => {
            const auth = localStorage.getItem('userlogin');
            if(auth){
                navigate('/')
            }
        });
    }
   // console.log(userscrenn)
    //console.log(props.userscrenn)
    return <RecruiterSignup  loginscreen={loginscreen} logindata={props} />;
}