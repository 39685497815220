import React  , {useEffect,useState} from 'react';
//import LeftSidebar from '../../backend/include/left_sidebar';
//import RightSidebar from '../../backend/include/right_sidebar';

import SubscribeModule from './template_parts/SubscribeModule';
import PageTopHeaderSec from './template_parts/PageTopHeaderSec';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from "axios";
import { Link } from "react-router-dom";

const AboutUs = () => {

    useEffect(() => { window.scrollTo(0, 0) }, []);
    const [baseUrl, setBaseurl] = useState('');
    const [blogsData, setBlogsData] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [aboutusData, setAboutusData] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const getdata = await axios.get("https://jobchatbox.com/api/cms/blogs");
                setBlogsData(getdata.data.data.blog_data);
                setBaseurl(getdata.data.data.base_url);
            } catch (error) { console.log(error); }
        })();
        (async () => {
            try {
                const getdata = await axios.get("https://jobchatbox.com/api/cms/team");
                setTeamData(getdata.data.data.team_data);
            } catch (error) { console.log(error); }
        })();
        (async () => {
            try {
                const getdata = await axios.get("https://jobchatbox.com/api/cms/pages/key/about_us");
                setAboutusData(getdata.data.data.pages_data);
            } catch (error) { console.log(error); }
        })();
    }, []);

    return (
        <>
      
      <PageTopHeaderSec  pageTitle='About Us' PageLink='aboutus'   />
      <section className="section-box mt-50">
        <div className="post-loop-grid">
          <div className="container">
            <div className="text-center">
              <h6 className="f-18 color-text-mutted text-uppercase">Our company</h6>
              <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">{aboutusData.page_title}</h2>
              <p className="font-sm color-text-paragraph wow animate__animated animate__fadeInUp w-lg-50 mx-auto">{aboutusData.page_shortdescription}</p>
            </div>
            <div className="row mt-70 align-items-center">
              
              <div className="col-lg-6 col-md-12 col-sm-12">
                <img src={baseUrl+'/'+aboutusData.page_image} alt="jcbapp" />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 class="section-title mb-10 wow animate__animated animate__fadeInUp">Here’s more to us!</h2>
                <div className="mt-20">
                  <div dangerouslySetInnerHTML={{__html: aboutusData.page_description}} ></div>
                </div>
                {/* <div className="mt-30"><a className="btn btn-brand-1" href="#">Read More</a></div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-box mt-80">
        <div className="post-loop-grid">
          <div className="container">
            <div className="text-center">
              <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">Meet Our Team</h2>
              <p className="font-sm color-text-paragraph w-lg-50 mx-auto wow animate__animated animate__fadeInUp">Our team is the backbone of our company. Join us and be a part of a community that helps you turn your potential into reality. Let Jobchatbox be your wingman in the job search game, and watch your network soar. </p>
            </div>
            <div className="row mt-70">
              
              {
                teamData.length ?
                <>
                  {
                    teamData.map((item,index) => (
                      <>
                        <div key={item.id} className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                          <div className="card-grid-4 text-center hover-up">
                            <div className="image-top-feature">
                              <figure><img alt="jcbapp" src={baseUrl+'/'+item.image_url}   /></figure>
                            </div>
                            <div className="card-grid-4-info">
                              <h5 className="mt-10">{item.name}</h5>
                              <p className="font-xs color-text-paragraph-2 mt-5 mb-5">{item.designation}</p>
                              {item.location ? <><span className="card-location">{item.location}</span></> : ''}
                              
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  }
                </>
                : '' 
              }
              
             
            </div>
          </div>
        </div>
      </section>
      <section className="section-box mt-50 mb-50">
        <div className="container">
          <div className="text-start">
            <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">News and Blog</h2>
            <p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp">Get the latest news, updates and tips</p>
          </div>
        </div>
        <div className="container">
          <div className="">
            {
              blogsData.length  ? 
              <>

               <div className="row about_blog">

                    {
                    blogsData.map((item,index) => (
                      <>
                        <div className="col-md-4 col-sm-12 mt-20" key={item.id}>
                          <div className="card-grid-3 hover-up wow animate__animated animate__fadeIn">
                            <div className="text-center card-grid-3-image">
                              <Link href="#">
                                <figure><img alt="jcbapp" src={baseUrl+'/'+item.blog_image_url}   /></figure>
                              </Link>
                            </div>
                            <div className="card-block-info">
                              {/* <div className="tags mb-15">
                                <Link className="btn btn-tag" href="#">News</Link>
                              </div> */}
                              <h5><Link href="#">{item.blog_name}</Link></h5>
                              <p className="mt-10 color-text-paragraph font-sm">{item.blog_shortdescription}</p>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  }


               </div>
              

               
              </>
              : ''
            }
              
            <div className="text-center mt-50"><a className="btn btn-brand-1 btn-icon-load mt--30 hover-up" href="#">View More Posts</a></div>
          </div>
        </div>
      </section>
      <SubscribeModule />
        </>
    );
  };
export default AboutUs;