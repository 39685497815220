import React,{useState,useEffect} from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';
import { Link } from "react-router-dom";
import axios from "axios";

const Applied_jobs = () => {
   var storedNames = JSON.parse(localStorage.getItem("userlogin"));
   const [appliedJobs, setAppliedJobs] = useState([]);
   useEffect(() => {
      (async () => {
          try {
         
          const states_list = await axios.get("https://jobchatbox.com/api/home/api/applied_jobs_for_rec/"+storedNames.login_token);
          if(states_list.data.status == 1){
            setAppliedJobs(states_list.data.data.jobs)
          }
          } catch (error) {
            console.log(error);
          }
      })();
}, []);


   const JobsSkills  = (job_skills) => {
      let dataskilss =  [];
      try {
         dataskilss =  JSON.parse(job_skills.data);
         console.log('dataskilss')
      } catch(e) {
      }
     // dataskilss =  JSON.parse(job_skills);
      let skils_tags = '';
      dataskilss.map((skill_item,index2) => (
         skils_tags +=  `<span class="btn btn-grey-small mr-5 text-capitalize">${skill_item}</span>`   
        // skils_tags +=  <Link key={index2+1} className="btn btn-grey-small mr-5 text-capitalize" to={`?skills=${skill_item}`} >{skill_item}</Link>   
      ));
         console.log(skils_tags)
      return skils_tags;
   }

    return (
      <>
          <div className="dashboardsec pt-30 pb-30">
           <div className="container">
            
            <div className="row">
              <div className="col-lg-3">
               <LeftSidebar />
              </div>
              <div className="col-lg-9">
                  <div className="section-box jobpost-sec">
                     <div className="container">
                        <div className="head-border mb-30 d-flex justify-content-between align-items-center">
                           <h5>Applied For Jobs </h5>
                        </div>
                        <div className="row display-list">
   
                              {
                                 appliedJobs.length ? 
                                 appliedJobs.map((item,index) => (
                                    <>
                                      
                                       <div className="col-xl-6 col-12">
                                          <div className="card-grid-2 hover-up">
                                             <div className="card-block-info">
                                                <h5 className="mt-5"><Link to={`/view-applied-profile?candidate_id=${item.userdetail.id}&applied_job_id=${item.applied_job_id}`}>Applied For : {item.userdetail.job_title}</Link></h5>
                                                <h6 className="mt-5"><Link to={`/view-applied-profile?candidate_id=${item.userdetail.id}&applied_job_id=${item.applied_job_id}`}>Job Title : {item.job_title}</Link></h6>
                                                <div className="mt-5 mb-10"><span className="card-briefcase"> {item.userdetail.join_status}</span><span className="card-time">{item.create_date} </span></div>
                                                <p class="font-sm color-text-paragraph"><strong>Name :</strong> <Link to={`/view-applied-profile?candidate_id=${item.userdetail.id}&applied_job_id=${item.applied_job_id}`}>{item.userdetail.firstname+' '+item.userdetail.lastname} </Link></p>
                                                <div className="row align-items-center">
                                                   <div className="col-md-12">
                                                      <p className="font-sm color-text-paragraph"><strong>Industry :</strong> {item.userdetail.job_industry}</p>
                                                   </div>
                                                   <div className="col-md-12 mb-5">
                                                      <p className="font-sm color-text-paragraph"><strong>Location :</strong> {item.userdetail.user_city}</p>
                                                   </div>
                                                   <div className="col-md-12">
                                                      <p className="font-sm color-text-paragraph"><strong>Skills :</strong>
                                                         {
                                                            
                                                            item.job_skills.map((skill_item,index2) => (
                                                                    <a key={index2+1} className="btn btn-grey-small mr-5 mb-1" href="#">{skill_item}</a>
                                                                ))
                                                         }
                                                         {/* <a className="btn btn-grey-small mr-5 ml-5" href="#">HTML</a> 
                                                         <a className="btn btn-grey-small mr-5" href="#">CSS</a> 
                                                         <a className="btn btn-grey-small mr-5" href="#">jQuery</a> */}
                                                      </p>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </>
                                 ))  : <><p className='pl-10'>You have no applied job list.</p></>
                              }
                                 

                        </div>
                     </div>
                  </div>
               </div>

            </div>
          </div>
        </div>
      </>
    );
};
export default Applied_jobs;