import React ,{useState , useEffect} from "react";
import { Nav, NavLink, NavMenu } from "../../components/NavbarElements";
import JobsFilterSidebar from '../../components/JobsFilterSidebar';
import JobsFilterTop from '../../components/JobsFilterTop';
import axios from 'axios';
import { Link } from "react-router-dom";
//import { Dropdown } from 'react-bootstrap';

const tooglefilter = () => { 
    var toggleelem = document.querySelector('.link-siltertoggler');
    var toggleelemdiv = document.querySelector('.sidebar-filters');
    if(toggleelemdiv.classList.contains('opensidebar')){
     toggleelemdiv.classList.remove("opensidebar");
    }else{
    toggleelemdiv.classList.add("opensidebar");
    }
  }


  
const JobSeekerPageBody = () => {

    const [jobseekerdata, setJobSeekerData] = useState("");      
    const [jobseekerProfileSrc, setjobseekerProfileSrc] = useState("");
    const [searchedValue, setSearchedValue] = React.useState();
    const [headerSearchAttr, setHeaderSearchAttr] = React.useState();
    const [loading, setLoading] = useState("");      
    const [jobseekerResultData, setjobseekerResultData] = useState(""); 
    
    const [locationStateName, setLocationStateName] = useState([]);
    const [jobFilterOption, setJobFilterOption] = useState([]);
    const [jobCategoryType, setJobCategoryType] = useState([]);
    const [jobIndustryType, setjobIndustryType] = useState([]);
    const [totalExperienceYear, setTotalExperienceYear] = useState([]);
    
    //const [searchInput, setSearchInput] = useState('');

    const [totalResultCount, setTotalResultCount] = useState(0);
    const [paginationLenght, setPaginationLenght] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [startPage , setStartPage] = useState(0);
    const [endPage, setEndPage] = useState(0);


    const search = async url => {
        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                 console.log('search');
                 setJobSeekerData(json.data.results);
                 setPaginationLenght(json.total_page_lenght);
                 setTotalResultCount(json.total_count);
                 setStartPage(json.start_point);
                 setEndPage(json.end_point);
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    };


    

    useEffect(() => {
        search("https://jobchatbox.com/api/home/api/jobseeker");
        (async () => {
            try {
                const states_list = await axios.get("https://jobchatbox.com/api/home/api/get_jobs_location?data_user_type=3");
                if(states_list.data.status == 1){ 
                    setLocationStateName(states_list.data.data.stateid_and_name_rec) 
                    setJobCategoryType(states_list.data.data.job_join_status_list) 
                    setjobIndustryType(states_list.data.data.job_industry_list) 
                    setTotalExperienceYear(states_list.data.data.experience_year_list) 
                    setJobFilterOption(states_list.data.data) 
                    //experience_year_list
                }
            } catch (error) { console.log(error);}
        })();
    }, []);

    

    

    {/*search function for the sitebar jobs */}
    const [searchQuery, setSearchQuery] = useState('');
    const [shortByUser, setShortByUser] = useState('');
    const [results, setResults] = useState([]);
    const [debouncedQuery, setDebouncedQuery] = useState('noItem');
    const [jobLocationCheck, setJobLocationCheck] = useState([]);
    const [jobIndustryCheck, setJobIndustryCheck] = useState([]);
    const [jobCategoryCheck, setJobCategoryCheck] = useState([]);
    const [jobExperieceCheck, setJobExperieceCheck] = useState([]);

    const [hideAndSeekLocation, sethideAndSeekLocation] = useState('show');
    const [hideAndSeekIndustry, sethideAndSeekIndustry] = useState('not');
    const [hideAndSeekJoiningStatus, sethideAndSeekJoiningStatus] = useState('not');
    const [hideAndSeekExperience, sethideAndSeekExperience] = useState('not');

   
    useEffect(() => {
        const timerId = setTimeout(() => {
            setDebouncedQuery(searchQuery);
        }, 500);
        return () => {
        clearTimeout(timerId);
        };
    }, [searchQuery]);
    
    

    useEffect(()  => {
        const jobLocationCheckParam = Object.keys(jobLocationCheck).filter((option) => jobLocationCheck[option]);
        const jobIndustryCheckParam = Object.keys(jobIndustryCheck).filter((option) => jobIndustryCheck[option]);
        const jobCategoryCheckParam = Object.keys(jobCategoryCheck).filter((option) => jobCategoryCheck[option]);
        const jobExperieceCheckParam = Object.keys(jobExperieceCheck).filter((option) => jobExperieceCheck[option]);
       // const shortByUserParam = Object.keys(shortByUser).filter((option) => shortByUser[option]);
        var url = `https://jobchatbox.com/api/home/api/jobseeker?user_name=${debouncedQuery}&job_location=${jobLocationCheckParam}&job_category=${jobCategoryCheckParam}&job_industry=${jobIndustryCheckParam}&job_experience=${jobExperieceCheckParam}&short_by=${shortByUser}&currentpage=${currentPage}`;
        search(url);
        //search("https://jobchatbox.com/api/home/api/jobseeker")
    }, [debouncedQuery, jobLocationCheck , jobIndustryCheck,jobCategoryCheck,shortByUser,jobExperieceCheck,currentPage]);


    const  PaginationLenghtCompnent = () => {
        const pageNumbers = [];
            for (let i = 1; i <= paginationLenght; i++) {
                pageNumbers.push(
                    <li className="ml-1"><a onClick={() => setCurrentPage(i)}  className={currentPage === i ? 'pager-number active' : 'pager-number'} href="#"  >{i}</a></li>
            );
        }
        return pageNumbers;
    }
 

    const jobLocationChange = (event) => {
        const item = event.target.value;
        const isChecked = event.target.checked;
        setJobLocationCheck({ ...jobLocationCheck, [item]: isChecked });
    };

    const jobIndustryHandleChange = (event) => {
        const item = event.target.value;
        const isChecked = event.target.checked;
        setJobIndustryCheck({ ...jobIndustryCheck, [item]: isChecked });
    };

    const jobCategoryChange = (event) => {
        const item = event.target.value;
        const isChecked = event.target.checked;
        setJobCategoryCheck({ ...jobCategoryCheck, [item]: isChecked });
    };
    
    const jobExperieceChange = (event) => {
        const item = event.target.value;
        const isChecked = event.target.checked;
        setJobExperieceCheck({ ...jobExperieceCheck, [item]: isChecked });
    };


    const  JobSeekerHtmlLoop = (jobseeker_attr) => {
        let image_path  = `https://jobchatbox.com/api/uploads/users/${jobseeker_attr.image_path}`;


        //console.log(jobseeker_attr.data)

        let jobseekerKeyList = (jobseeker_attr.key_skills).length > 0
            && (jobseeker_attr.key_skills).map((item, i) => {
            if(i < 8){
                return (
                    <span key={i} className="roundtab mr-5">{item}</span>
                    )
            }
        }, this); 
        const placeholderImage = 'logobr.png'
        const onImageError = (e) => {
            e.target.src = placeholderImage
        }
        let candidate_detail_url =  `/jobseeker-details?candidate=${jobseeker_attr.unique_id}`;
            return (
                    <div className="col-xl-4 col-md-6 col-sm-12"  key={jobseeker_attr.image_id} >
                        <div className="card-grid-2 hover-up menblock">
                            <div className="card-grid-2-image-left">
                                <div className="card-grid-2-image-rd online">
                                    <NavLink  to={candidate_detail_url}  >
                                    <figure>
                                        <img alt="jcbapp" src={image_path ? image_path : placeholderImage }  onError={onImageError} />
                                    </figure>
                                    </NavLink> 
                                </div>
                                <div className="card-profile pt-10">
                                    <NavLink   to={candidate_detail_url}  >
                                        <h5 className="text-capitalize">{jobseeker_attr.firstname}</h5>
                                    </NavLink>
                                    <span className="font-xs color-text-mutted">{(jobseeker_attr.job_title) ? jobseeker_attr.job_title : '...'}</span>
                                    <p className="font-xs color-text-mutted">Viewed  ({jobseeker_attr.data.get_total_visitors})</p>
                                </div>
                            </div>

                            <div className="card-block-info">
                                <div className="font-xs color-text-mutted mb-5 overflowelips"><i className="fa fa-envelope-o" aria-hidden="true"></i>{jobseeker_attr.data.email}</div>
                                <div className="d-flex info2col">
                                    <div className="font-xs color-text-mutted mb-5 mr-10 gendercol"><i className="fa fa-user-o" aria-hidden="true"></i>{jobseeker_attr.data.gender}</div>
                                    <div className="font-xs color-text-mutted mb-5 locationcall d-flex overflowelips"><i className="fa fa-map-marker" aria-hidden="true"></i> {(jobseeker_attr.data.user_city) ? jobseeker_attr.data.user_city : '...'} {(jobseeker_attr.data.user_state) ? jobseeker_attr.data.user_state : '...'}</div>
                                </div>
                                <div className="font-xs color-text-mutted mb-5"><strong>Join: </strong> {jobseeker_attr.data.join_status}</div> 
                                <div className="font-xs color-text-mutted mb-5"><strong>Experience :</strong>{jobseeker_attr.data.total_experience_year_range}</div>
                                <div className="font-xs color-text-mutted overflowelips2"><strong>Preferred Locations :</strong>{jobseeker_attr.data.jobsprefered_state_location}</div>
                                
                                <div className="employers-info align-items-center justify-content-center mt-15">
                                <div className="card-2-bottom card-2-bottom-candidate">
                                <div className="text-start">
                                     {jobseekerKeyList}
                                </div>
                                </div>
                                </div>
                            </div>


                        </div>
                    </div>
            );          
    }

    var jobseekerdata_array  = jobseekerdata;
    if (jobseekerdata == null || jobseekerdata == "") {
        jobseekerdata_array = [];
    }

    const searchByNameTitle = async (title) => {
        //search_and_get_results(title)
        setSearchQuery(title)
    }

    //searchByNameTitle

    const sortByPost  = async (type) => {
        setShortByUser(type)
        // var url = `https://jobchatbox.com/api/home/api/jobseeker?shortby=${debouncedQuery}&job_location=${jobLocationCheckParam}&job_category=${jobCategoryCheckParam}&job_industry=${jobIndustryCheckParam}`;
        // search(url);
    } 



    
    return(
            <>
            <section className="section-box mt-30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                           <div className="sidebar-shadow none-shadow mb-30">
                           <div className="filter-block head-border mb-30 mobilefilter">
                                <h5><span className="link-siltertoggler" onClick={tooglefilter}><i className="fa fa-filter" aria-hidden="true"></i> Filter</span></h5>
                            </div>
                            <div className="sidebar-filters">
                                <div className="filter-block head-border mb-30">
                                    <h5>Advance Filter 
                                        <a className="link-reset" href="/jobseeker">Reset</a>
                                    </h5>
                                </div>
                                <div className="filter-block mb-0">
                                    <div className="form-group select-style select-style-icon">
                                    <input   name="search_input" onChange={(e) => searchByNameTitle(e.target.value) }   placeholder="Search.." />
                                    </div>
                                </div>


                                <div className="filter-block mb-0">
                                    <h5 onClick={() => sethideAndSeekLocation( hideAndSeekLocation == 'show' ? 'not' : 'show')} className="cursor_pointer medium-heading mb-10">
                                        By Locations <i class={`fa fa-chevron-${hideAndSeekLocation == 'show' ?'down' : 'right'}`} aria-hidden="true"></i>
                                    </h5>
                                    
                                    { hideAndSeekLocation == 'show' && <div className="form-group">
                                        <ul className="list-checkbox">
                                            {
                                                
                                                locationStateName.map((item,index) => (
                                                    item.user_state_name ? 
                                                    <li>
                                                        <label className="cb-container">
                                                        <input name="jobseeker_state[]" onChange={jobLocationChange} type="checkbox" value={item.user_state}  />
                                                        <span className="text-small">{item.user_state_name}</span>
                                                        <span className="checkmark"></span>
                                                        </label><span className="number-item">{item.total_count}</span>
                                                    </li> : ''
                                                ))
                                            }
                                        </ul>
                                    </div> }
                                </div> 
                               
                                <div className="filter-block mb-0">
                                    <h5 onClick={() => sethideAndSeekIndustry( hideAndSeekIndustry == 'show' ? 'not' : 'show')}  className=" cursor_pointer medium-heading mb-10">
                                        By Industry <i class={`fa fa-chevron-${hideAndSeekIndustry == 'show' ?'down' : 'right'}`} aria-hidden="true"></i>
                                    </h5>
                                    { hideAndSeekIndustry == 'show' &&   <div className="form-group">
                                            <ul className="list-checkbox">
                                                {
                                                    
                                                    jobIndustryType.map((item,index) => (
                                                        <li>
                                                            <label className="cb-container">
                                                            <input name="job_industry[]" onChange={jobIndustryHandleChange} type="checkbox" value={item.job_industry}  />
                                                            <span className="text-small">{item.industry_title}</span>
                                                            <span className="checkmark"></span>
                                                            </label><span className="number-item">{item.total_count}</span>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    }
                                </div>

                                <div className="filter-block mb-0">
                                    <h5 onClick={() => sethideAndSeekJoiningStatus( hideAndSeekJoiningStatus == 'show' ? 'hide' : 'show')}  className=" cursor_pointer medium-heading mb-15">
                                        By Joining Status <i class={`fa fa-chevron-${hideAndSeekJoiningStatus == 'show' ?'down' : 'right'}`} aria-hidden="true"></i>
                                    </h5>
                                    {hideAndSeekJoiningStatus == 'show' &&    <div className="form-group">
                                            <ul className="list-checkbox">
                                                {
                                                    jobCategoryType.map((item,index) => (

                                                        <li>
                                                            <label className="cb-container">
                                                            <input name="employment_type[]" onChange={jobCategoryChange}  type="checkbox" value={item.join_status}  />
                                                            <span className="text-small">{item.join_status}</span>
                                                            <span className="checkmark"></span>
                                                            </label><span className="number-item">{item.total_count}</span>
                                                        </li>

                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    }
                                </div>

                                <div className="filter-block mb-0">
                                    <h5 onClick={() => sethideAndSeekExperience( hideAndSeekExperience == 'show' ? 'hide' : 'show')}  className=" cursor_pointer medium-heading mb-15">
                                        By Experience <i class={`fa fa-chevron-${hideAndSeekExperience == 'show' ?'down' : 'right'}`} aria-hidden="true"></i>
                                    </h5>
                                    {  hideAndSeekExperience == 'show' && <div className="form-group">
                                        <ul className="list-checkbox">
                                            {
                                                totalExperienceYear.map((item,index) => (

                                                    <li>
                                                        <label className="cb-container">
                                                        <input name="employment_type[]" onChange={jobExperieceChange}  type="checkbox" value={item.total_expyear}  />
                                                        <span className="text-small">{item.total_expyear} Year+</span>
                                                        <span className="checkmark"></span>
                                                        </label><span className="number-item">{item.total_count}</span>
                                                    </li>

                                                ))
                                            }
                                        </ul>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        </div>

                        <div className="col-lg-9 col-md-12 col-sm-12 col-12 ">
                            <div className="content-page"> 
                                {/* Top filter strat */}
                                <div className="box-filters-job">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-5"><span className="text-small text-showing">Showing <span className="" ><strong>{startPage}-{endPage}</strong>of</span> <strong>{totalResultCount} </strong>Jobseeker</span></div>
                                        <div className="col-xl-6 col-lg-7 text-lg-end mt-sm-15">
                                        <div className="display-flex2">
                                            
                                            <select onChange={(e) => sortByPost(e.target.value)} className="form-select box-border short_by_filter" aria-label="Default select example">
                                                <option value="all">All</option>
                                                <option value="3months">3 Months</option>
                                                <option value="2months">2 Months</option>
                                                <option value="1months">1 Months</option>
                                                <option value="15days">15 Days</option>
                                                <option value="7days">1 Week</option>
                                                <option value="today">Today</option>
                                            </select>
                                            <div className="box-border display_none"><span className="text-sortby">Sort by:</span>
                                                <div className="dropdown dropdown-sort">
                                                    <button className="btn dropdown-toggle" id="dropdownSort2" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static"><span>Newest Post</span><i className="fi-rr-angle-small-down"></i></button>
                                                    <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownSort2">
                                                        <li><Link onClick={(e) => sortByPost('all')  } className="dropdown-item active" href="#">All</Link></li>
                                                        <li><Link onClick={(e) => sortByPost('3months')  } className="dropdown-item active" href="#">3 Months</Link></li>
                                                        <li><Link onClick={(e) => sortByPost('2months')  } className="dropdown-item active" href="#">2 Months</Link></li>
                                                        <li><Link onClick={(e) => sortByPost('1months')  } className="dropdown-item active" href="#">1 Months</Link></li>
                                                        <li><Link onClick={(e) => sortByPost('15days')  } className="dropdown-item active" href="#">15 Days</Link></li>
                                                        <li><Link onClick={(e) => sortByPost('7days')  } className="dropdown-item active" href="#">1 Week</Link></li>
                                                        <li><Link onClick={(e) => sortByPost('today')  } className="dropdown-item active" href="#">Today</Link></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="box-view-type display_none">
                                                <a className="view-type" href="jobs-list.html">
                                                <img src="front/assets/imgs/icon-list.svg" alt="jcbapp"  />
                                                </a>
                                                <a className="view-type" href="jobs-grid.html">
                                                    <img src="front/assets/imgs/icon-grid-hover.svg" alt="jcbapp"  />
                                                </a>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Top filter end */}
                                <div className="row">
                                    {
                                        (jobseekerdata_array.length > 0)  ?      
                                        
                                        jobseekerdata_array.map((jonseekerdata,index) => 
                                            <JobSeekerHtmlLoop 
                                            key={index}  
                                            image_path={jonseekerdata.profile_pic} 
                                            firstname={jonseekerdata.firstname} 
                                            job_title={jonseekerdata.job_title} 
                                            key_skills ={jonseekerdata.key_skills}  
                                            locationInfo ={jonseekerdata.locationInfo}  
                                            short_desc ={jonseekerdata.short_desc}  
                                            image_id={index+1} 
                                            company_name= {jonseekerdata.company_name} 
                                            jobsCount= {jonseekerdata.jobsCount} 
                                            user_location= {jonseekerdata.user_location} 
                                            unique_id= {jonseekerdata.unique_id} 
                                            data= {jonseekerdata}                 
                                            /> ) 
                                        : <div>No Data avaliable</div>
                                    }
                                </div>

                                {
                                    (jobseekerdata_array.length > 0)  ?  
                                    <>
                                    <div class="paginations">
                                        <ul class="pager">
                                        <li><a   onClick={() => setCurrentPage(currentPage - 1)}   className={ (currentPage === 1) ? 'pager-prev disabled_page_link active' : 'pager-prev'} href="#"   ></a></li>
                                        <PaginationLenghtCompnent />
                                        <li><a onClick={() => setCurrentPage(currentPage + 1)}  className={ (currentPage === paginationLenght) ? 'disabled_page_link pager-next active' : 'pager-next' }  href="#"  ></a></li>
                                        </ul>
                                    </div>
                                    </>
                                    :''
                                }
                                


                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            </>
        );
}
export default JobSeekerPageBody;