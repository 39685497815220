import React, { useState ,useEffect } from 'react';
import { Nav, NavLink, NavMenu } from "../../components/NavbarElements";
import {useNavigate} from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link , useLocation } from "react-router-dom";
const Reset_Password = () => {
  
  const location = useLocation();
     const searchParams = new URLSearchParams(location.search);
     const action = searchParams.get('action');


    const [forgotOtp , setForgotOtp] = useState("");
    const [resetPwd , setResetPwd] = useState("");
    const [resetcnpwd , setResetCnpwd] = useState("");
    const [genered_id , setGeneredid] = useState("");
    const [responceMessgae , setResponceMessgae] = useState("");
    const [responceStatus , setResponceStatus] = useState(0);
    //setForgotOtp , setResetPwd , setResetCnpwd
  const navigate = useNavigate();
  useEffect(() => {
    const auth = localStorage.getItem('userlogin');
    if(auth){
        navigate('/')
    }
    const queryParameters = new URLSearchParams(window.location.search)
    const genrated_id = queryParameters.get("genrate_id");
    if(genrated_id){
        setGeneredid(genrated_id);
    } else {
        navigate('/')
    }
    //alert(genrated_id)
    //genrate_id
  });
  


  
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const loginFormData = {
      forgot_otp : forgotOtp ,
      reset_pwd : resetPwd ,
      reset_cnpwd : resetcnpwd ,
      genered_email_id : genered_id ,
    }

    try {
      let res = await fetch("https://jobchatbox.com/api/home/signup/update_password", {
          method: "POST",
          body: JSON.stringify(loginFormData),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        if(resJson.status == 1){
            setResponceMessgae(resJson.message);
            setResponceStatus('success');
            setTimeout(function() {
              navigate(action == 'placement_login' ? '/placement-login' :   '/login');
            }, 1000);
           } else {
            notify('warning','Email Id or Password is wrong.');
            setResponceStatus('failed');
            setResponceMessgae(resJson.message);
                setTimeout(function() {
                    setResponceMessgae('');
                }, 1000);
          }
      } else {
          notify('error','Some error occured.');
      }
      } catch (err) {
      console.log(err);
      }
  }


  const notify = (status_type,message) => {
    switch (status_type) {
        case 'warning':
            toast.warning(message)  
        break;
  
        case 'success':
            toast.success(message)  
        break;
  
        case 'info':
            toast.info(message)  
        break;
  
        case 'error':
            toast.error(message)  
        break;
    }
  };


  return (
      <>
      <ToastContainer />
      <section className="pt-70 login-register">
        <div className="container"> 
            <div className="row login-register-cover pb-70">
            <div className="col-lg-4 col-md-4 col-sm-12">
           </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="text-center">
                {/* <p className="font-sm text-brand-2">Forgot Password</p> */}
                <h2 className="mt-10 mb-5 text-brand-1">Reset Password</h2>
                {/* <p className="font-sm text-muted mb-30">Enter the email address associated with your account and we will send you a link to reset your password. </p> */}
                
                <div class="">Please check your email to reset password for Reference Code (Please Check in SPAM Also)</div>

                {
                    responceStatus == 'success' ? 
                    <><div class="alert alert-success">{responceMessgae}</div></>
                    : 
                    <></>
                }
                {
                    responceStatus == 'failed' ? 
                    <><div class="alert alert-warning">{responceMessgae}</div></>
                    : 
                    <></>
                }
              </div>
              <form onSubmit={handleSubmit} className="login-register text-start mt-20" action="#">
                
                <div className="form-group">
                  <label className="form-label" for="input-2">Reference Code *</label>
                  <input type="text" class="form-control login-ctrl" id="forgot_otp" name="forgot_otp" placeholder="Please Enter Reference Code" autocomplete="off" onChange = { (e) => setForgotOtp(e.target.value)}   ></input>
                </div>

                <div className="form-group">
                  <label className="form-label" for="input-2">New Password *</label>
                  <input type="password" class="form-control login-ctrl" id="reset_pwd" name="reset_pwd" placeholder="Please Enter New Password" autocomplete="off" onChange = { (e) => setResetPwd(e.target.value)}  ></input>
                </div>

                <div className="form-group">
                  <label className="form-label" for="input-2">Confirm Password *</label>
                  <input type="password" class="form-control login-ctrl" id="reset_cnpwd" name="reset_cnpwd" placeholder="Please Confirm The Password" autocomplete="off" onChange = { (e) => setResetCnpwd(e.target.value)}  ></input>
                </div>
                
                <div className="form-group">
                  <button className="btn btn-brand-1 hover-up w-100" type="submit" name="login">Continue</button>
                </div>
                <div className="text-muted text-center">Do not have an account? 
                <Link to="/register"> Sign Up</Link>
                </div>
              </form>
            </div>
           </div>
           <div className="col-lg-4 col-md-4 col-sm-12">
           </div>

         
        </div>
      </section>
           
      </>
    );
  };
export default Reset_Password;