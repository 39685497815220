import React, { useState, useEffect } from "react";
import { Nav, NavLink, NavMenu } from "./NavbarElements";
import { useNavigate ,useLocation  } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

export default function NavBar() {
  const location = useLocation();
  const auth = localStorage.getItem('userlogin');
  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    notify('success', 'Logout successfully.');
    googleLogout();
    setTimeout(function () {
      navigate('/login');
    }, 1000);

  };

  const [totalNotification, setTotalNotification] = useState(0);
  var storedNames = JSON.parse(localStorage.getItem("userlogin"));
  useEffect(() => {
    if(storedNames){

      const notificationCounter = () => {
        (async () => {
          try {
            const addlist = await axios.get(`https://jobchatbox.com/api/user/api/notifications_number_counter/${storedNames.login_token}`);
            if (addlist.data.status == 1) {
              setTotalNotification(addlist.data.data.unread_notification);
            }
          } catch (error) { console.log(error); }
        })();
      }
      notificationCounter();
      const interval = setInterval(() => { notificationCounter() }, 60000);
      return () => clearInterval(interval);
    }
  }, []);

  const notify = (status_type, message) => {
    switch (status_type) {
      case 'warning':
        toast.warning(message)
        break;

      case 'success':
        toast.success(message)
        break;

      case 'info':
        toast.info(message)
        break;

      case 'error':
        toast.error(message)
        break;
    }
  };

  const userdata = JSON.parse(localStorage.getItem("userloginSession"));
  let profile_pic = (userdata && userdata.profile_pic) ? `https://jobchatbox.com/api/uploads/users/${userdata.profile_pic}` : 'front/assets/imgs/default_image.png';



    //console.log(location.pathname);
  return (
    <>
      <header className="header sticky-bar">
        <div className="container">






          <div className="main-header">
            <div className="header-left">
              <div className="header-logo">
                <NavLink className="d-flex jobbox_logo" to="/"  >
                  Jobchatbox
                </NavLink></div>
            </div>
            <div className="header-nav">



              <nav className="navbar navbar-expand-lg nav-main-menu">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                  <i className="fa fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="collapsibleNavbar">
                  <ul className="navbar-nav main-menu">
                    <li className=""><NavLink to="/"  >Home</NavLink></li>
                    <li className=""><NavLink to="/jobseeker" >Available job seeker</NavLink></li>
                    <li className=""><NavLink to="/recruiters"  >Recruiters</NavLink></li>
                    <li className=""><NavLink to="/jobs"  >Jobs</NavLink></li>
                    <li className=""><NavLink to="/training-and-placements"  >Training & placements</NavLink></li>
                    {
                      (auth) ?
                        (<>
                          <li className=""><NavLink to="/connections"  >Connections</NavLink></li>
                          <li className=""><NavLink to="/dashboard"  >Dashboard</NavLink></li>
                        </>) : ''
                    }
                  </ul>
                </div>
              </nav>





            </div>
            <div className="header-right">
              <div className="block-signin">
                {
                  (auth) ?
                    (<>
                      <div className="dropdown">
                        <span className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                          <div className="topprimg"><i class="arrow down"></i> <img src={profile_pic}></img> </div>
                        </span>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                          {
                            storedNames.usertype == 3 ?
                            <>
                              <li className="dropdown-item"><NavLink to="/profile-edit" ><i className="fa fa-user" aria-hidden="true"></i>Edit Profile</NavLink></li>
                              <li className="dropdown-item"><NavLink to="/sectors"  ><i className="fa fa-commenting-o" aria-hidden="true"></i>Sectors/Skills</NavLink></li>
                              <li className="dropdown-item"><NavLink to="/work-experience"  ><i className="fa fa-user" aria-hidden="true"></i>My Work Experience</NavLink></li>
                              <li className="dropdown-item"><NavLink to="/login" ><i className="fa fa-sign-out" aria-hidden="true"></i>Logout</NavLink></li>
                            </>
                            :
                            storedNames.usertype == 6 ?
                            <>
                              <li className="dropdown-item"><NavLink to="/profile-edit" ><i className="fa fa-user" aria-hidden="true"></i>Edit Profile</NavLink></li>
                              <li className="dropdown-item"><NavLink to="/sectors"  ><i className="fa fa-commenting-o" aria-hidden="true"></i>Sectors/Skills</NavLink></li>
                              <li className="dropdown-item"><NavLink to="/work-experience"  ><i className="fa fa-user" aria-hidden="true"></i>My Work Experience</NavLink></li>
                              <li className="dropdown-item"><NavLink to="/login" ><i className="fa fa-sign-out" aria-hidden="true"></i>Logout</NavLink></li>
                            </>
                            :
                            storedNames.usertype == 7 ?
                            <>
                              <li className="dropdown-item"><NavLink to="/myprofile-edit" ><i className="fa fa-user" aria-hidden="true"></i>Edit Profile</NavLink></li>
                              <li className="dropdown-item"><NavLink to="/login" ><i className="fa fa-sign-out" aria-hidden="true"></i>Logout</NavLink></li>
                            </>
                            :
                            <></>
                          }
                          
                        </ul>
                      </div>
                      <NavLink to="/notifications" className="notificationsh ml-20" >
                        <i className="fa fa-bell-o" aria-hidden="true"></i><span className="badge" id="ttlNotification">{totalNotification}</span>
                      </NavLink>
                      <NavLink onClick={logout} to="/login" className="btn btn-default btn-shadow ml-30 hover-up" > Logout</NavLink>
                    </>)
                    :
                    
                    (
                      location.pathname === '/training-and-placement-details' || 
                      location.pathname === '/training-and-placements' ||  
                      location.pathname === '/placement-signup' ||  
                      location.pathname === '/placement-login'
                    ) ?
                    <NavLink to="/placement-login" className="btn btn-default btn-shadow ml-30 hover-up" >Placement Login/Register </NavLink>
                    :
                    <NavLink to="/login" className="btn btn-default btn-shadow ml-30 hover-up" > Login / Register</NavLink>
                }

              </div>

            </div>
          </div>
        </div>
      </header>

      {/* <div className="mobile-header-active mobile-header-wrapper-style perfect-scrollbar">
        <div className="mobile-header-wrapper-inner">
          <div className="mobile-header-content-area">
            <div className="perfect-scroll">
              <div className="mobile-search mobile-header-border mb-30">
                <form action="#">
                  <input type="text" placeholder="Search…" /><i className="fi-rr-search"></i>
                </form>
              </div>
              <div className="mobile-menu-wrap mobile-header-border">
                <nav>
                  <ul className="mobile-menu font-heading">
                    <li className=""><a href="Job-seeker.html">Available job seeker</a></li>
                    <li className=""><a href="recruiters.html">Recruiters</a></li>
                    <li className=""><a href="jobs.html">Jobs</a></li>
                    <li className=""><a href="connections.html">Connections</a></li>
                    <li className=""><a href="notifications.html">Notifications</a>    </li>
                  </ul>
                </nav>
              </div>
              <div className="site-copyright pt-40">Copyright© 2022, Jobchatbox | Powered by <a href="https://www.macrocosms.in/">Macrocosm IT Services</a></div>
            </div>
          </div>
        </div>
      </div> */}
    </>

  )
}