import React,{useState,useEffect} from "react";
import axios from "axios";
import SubscribeModule from './template_parts/SubscribeModule';
import PageTopHeaderSec from './template_parts/PageTopHeaderSec';




const TermsOfSerive  = () => {


    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

      const [allPostData, setAllPostData] = useState([]);
   const [baseUrl, setBaseurl] = useState('');
   useEffect(() => { 
      (async () => {
         try {
               const getdata = await axios.get("https://jobchatbox.com/api/cms/pages/key/terms_and_conditions");
               setAllPostData(getdata.data.data.pages_data);
               setBaseurl(getdata.data.data.base_url);
         } catch (error) { console.log(error); }
      })();
   }, []);

    return (
        <>
      <PageTopHeaderSec pageTitle='Terms of Service' PageLink='terms-of-service'  />
      
         <>
         {/* <h4>Terms &amp; Conditions</h4> */}
         <div className="mb-20" dangerouslySetInnerHTML={{__html: allPostData.page_description}} ></div>
         </>

      <SubscribeModule />
        </>
    );
  };
export default TermsOfSerive;