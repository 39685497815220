import React ,{useState , useEffect} from "react";
import { Nav, NavLink, NavMenu } from "../../components/NavbarElements";
import SubscribeModule from './SubscribeModule';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from "axios";
import { Link } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap' ;
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RecruiterDetails = () => {
   useEffect(() => {
      window.scrollTo(0, 0)
  }, []) 

    const [recruiterAllDetail, setRecruiterAllDetail] = useState([]);
    const [jobAllDetail, setJobAllDetail] = useState([]);
    const [recruiterLoadStatus, setRecruiterLoadStatus] = useState(0);
    const [isResumeModalShow, setIsResumeModalShow] = useState(false);
    const [isResumeShareBtn, setIsResumeShareBtn] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isSetPostId, setIsSetPostId] = useState(false);
    //const [closeResumeModal, setCloseResumeModal] = useState(false);
     
   useEffect(() => {

         var storedNames = JSON.parse(localStorage.getItem("userlogin"));

         if(storedNames){
            setIsLoggedIn(true);
         }

         const queryParameters = new URLSearchParams(window.location.search)
         const candidate = queryParameters.get("candidate");
         // get_userdata("https://jobchatbox.com/api/userdetails/"+candidate)
         window.scrollTo(0, 0);
         (async () => {
            try {
               const responceData = await axios.get("https://jobchatbox.com/api/home/api/recruiter_details/"+candidate);
               if(responceData.data.status == 1){
                     setRecruiterAllDetail(responceData.data.data);
                     setRecruiterLoadStatus(1)
                  }
               } catch (error) {
                  console.log(error);
               }
            })();
            
         const jobid = queryParameters.get("jobid");
         (async () => {
            try {
               const responceData = await axios.get("https://jobchatbox.com/api/home/api/job_details/"+candidate+'/'+jobid);
                  if(responceData.data.status == 1){
                     setJobAllDetail(responceData.data.data.job_detail);
                    // setRecruiterLoadStatus(1)
                  }
               } catch (error) {
                  console.log(error);
               }
         })();
   }, []);



    const closeResumeModal = () => { setIsResumeModalShow(false) }
    const shareResumeToRecruiter = (id) => { setIsResumeModalShow(true) ;setIsSetPostId(id)  }
    const postResumeSubmit = async (event) => {
      event.preventDefault();
      const formData = new FormData();
      var storedNames = JSON.parse(localStorage.getItem("userlogin"));
      //let uploadvalue =   this.state.resumeFormUpload ;
      //isResumeShareBtn
      setIsResumeShareBtn(true);
      formData.append('upload_resume', event.target.upload_resume.files[0]);
      formData.append('login_token', storedNames.login_token);
      formData.append('post_id', isSetPostId);
      formData.append('post_type', 'job');
      try {
          let res = await fetch("https://jobchatbox.com/api/user/api/post_upload_resume", {
              method: "POST",
              body: formData ,
          });
          let resJson = await res.json();
          if (res.status === 200) {
               setIsResumeShareBtn(false);
              if(resJson.status){ 
                  toast.success(resJson.data.message)
                  setIsResumeModalShow(false)
              }  else {  
               toast.warning(resJson.data.message)
                     // notify('warning',resJson.message) ;
                }
          } else {
          }
      } catch (err) { 
          console.log(err);
      }
    }
    
//     const shareResumeToRec = async (jobid) => {
//       //alert(jobid)
//       let userdata  = JSON.parse(localStorage.getItem("userloginSession"));
//       //console.log(userdata)
//       setShareResumeJobId(jobid);
//       if(userdata && userdata.usertype == 3){
//         const  API =  'https://jobchatbox.com/api/user/api/shared_resume_to_job';
//         const formData = new FormData();
//         formData.append('job_id', jobid );
//         formData.append('login_token', userdata.login_token );
//         formData.append('user_id', userdata.id );
//         (async () => {
//             try {
//                 let res = await fetch(API , { method: "POST", body: formData ,});
//                 let resJson = await res.json();
//                 (resJson.status == 1) ? toast.success(resJson.message) : toast.warning(resJson.message) ;
//                 if(resJson.status == 0){
//                   setIsResumeModalShow(true)
//                 } else if(resJson.status == 1) {
//                   setSimilerJobsAppliedId([...similerJobsAppliedId, jobid]);
//                 }
//             } catch (err) { console.log(err);}
//         })();
//       } else if(userdata && userdata.usertype == 6){
//          toast.warning('Recruiter Can\'t Share Resume to Jobs')
//       } else {
//         toast.warning('Please Login for share resume')
//         setTimeout( function() {
//           navigate('/login')
//         } ,2000)
//       }
//   }  
    

   const RecruiterDetails =  (getdata)  => {
      //console.log(getdata.userdata)
      let userAttr  = getdata.userdata ;
      return  (
            <>
            
               <div className="recrutersidebar ml-auto">
                  <div className="contactbox">
                     <div className="">
                        <ul className="">
                           <li><i className="fa fa-user mr-10" aria-hidden="true"></i> Name : <span className="text-capitalize">{userAttr.firstname}</span></li>
                           <li><i className="fa fa-envelope-o mr-10" aria-hidden="true"></i> Email: {userAttr.email}</li>
                           <li><i className="fa fa-phone mr-10" aria-hidden="true"></i> Phone: {userAttr.mobile}</li>
                           <li><i className="fa fa-map-marker mr-10" aria-hidden="true"></i>Location : {userAttr.user_city+' '+userAttr.user_state}</li>
                           <li><NavLink to={`/dashboard?candidate_id=${recruiterAllDetail.userdata.unique_id}`} className="btn btn btn-default mt-20" >My Timeline</NavLink></li>
                        </ul>
                        <div className="mt-20">
                           
                           <a className="btn btn-default mr-10 btn-small" href="#">View</a>
                           <a className="btn btn-default btn-small" href="#">Connect</a>

                        </div>
                     </div>
                  </div>
               </div>
            </>
         )
   }

    return(

            <>
                <ToastContainer />
               <div className="recruterprofilesec">
                 
                  {
                     recruiterLoadStatus  ? 
                     <>
                        <section className="section-box-2">
                           <div className="container">
                              <div className="banner-hero banner-image-single"><img src={recruiterAllDetail.userdata.profile_bg_img_url} alt="jcbapp" /></div>
                           </div>
                        </section>
                        <section className="section-box">
                           <div className="container">
                              <div className="row recuruterbottom">
                                 <div className="col-lg-12 col-md-12 col-sm-12 col-12 recrutersingleleft">
                                    <div className="content-single">
                                       <div className="box-recruter-profile">
                                          <div className="image-compay "><img className="jobseeker_img" src={recruiterAllDetail.userdata.profile_pic_url} alt="jcbapp" /></div>
                                          <div className="d-flex tecrutertop">
                                             <div className="col-lg-7 col-md-7">
                                                <h5 className="f-18 text-capitalize"> 
                                                   {recruiterAllDetail.userdata.firstname}
                                                   <span className="card-location font-regular ml-20">
                                                   {recruiterAllDetail.userdata.user_city+' '+recruiterAllDetail.userdata.user_state}
                                                   </span>
                                                </h5> 
                                                <p className=" font-xs color-text-paragraph-2 mb-0"><strong>Hire for :</strong> {recruiterAllDetail.userdata.key_skills_string} </p>
                                                <p className=" font-xs color-text-paragraph-2 mb-0"><strong>Active Jobs :</strong> {recruiterAllDetail.total_active_jobs}</p> 
                                             </div>
                                             <div className="col-lg-5 col-md-5">
                                             <h5 className="f-18">Sectors Hire In<span className="font-sm ml-20">{recruiterAllDetail.userdata.key_skills_string} </span></h5>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="row recuruteropnew mt-20">
                                          <div className="col-lg-7">
                                             <div className="box-nav-tabs mb-5">
                                                <ul className="nav" role="tablist">
                                                   <li><a className="btn btn-border aboutus-icon mr-15 mb-5 active" href="#tab-about" data-bs-toggle="tab" role="tab" aria-controls="tab-about" aria-selected="true">About us</a></li>
                                                   <li><a className="btn btn-border recruitment-icon mr-15 mb-5" href="#tab-recruitments" data-bs-toggle="tab" role="tab" aria-controls="tab-recruitments" aria-selected="false">Current job </a></li>
                                                   <li><a className="btn btn-border people-icon mr-15 mb-5" href="#tab-people" data-bs-toggle="tab" role="tab" aria-controls="tab-people" aria-selected="false">Active jobs</a></li>
                                                   <li><NavLink to={`/mytimeline?candidate_id=${recruiterAllDetail.userdata.unique_id}`} className="btn btn-border people-icon mb-5" >My Timeline</NavLink></li>

                                                </ul>
                                             </div>
                                          </div>
                                          <div className="col-lg-5">
                                             <div className="d-flex justify-content-end align-items-center">
                                                <div className="companylogoright">
                                                   <div className="image-compay"><img  src={recruiterAllDetail.userdata.profile_pic_url} alt="jcbapp" /></div>
                                                   <div className="compnyright"> 
                                                      <h6 className="sidebar-company mt-0">{recruiterAllDetail.userdata.company_name}</h6>
                                                      <span className="card-location">{recruiterAllDetail.userdata.user_city+' '+recruiterAllDetail.userdata.user_state} </span>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       
                                       <div className="tab-content">
                                         
                                          <div className="tab-pane fade active show" id="tab-about" role="tabpanel" aria-labelledby="tab-about">
                                             <div className="row align-items-end">
                                                <div className="col-lg-8">
                                                   <h5>Welcome to {recruiterAllDetail.userdata.company_name}</h5>
                                                   <p dangerouslySetInnerHTML={{ __html: recruiterAllDetail.userdata.short_desc }}></p>
                                                </div>
                                                <div className="col-lg-4">
                                                      <RecruiterDetails userdata={recruiterAllDetail.userdata} />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="tab-pane fade" id="tab-recruitments" role="tabpanel" aria-labelledby="tab-recruitments">
                                             <div className="row align-items-end">
                                                <div className="col-lg-8">
                                                   <div className="d-flex align-items-center">
                                                      <h5>{recruiterAllDetail.userdata ?  'Current Job' : 'No jobs avaliable at the moment.'}</h5>

                                                      <button class="btn btn-default mr-10 btn-small ml-auto "  onClick={(e) => shareResumeToRecruiter(0)}>
                                                         <i class="fa fa-file-text" aria-hidden="true"></i>
                                                         <span>Share Resume</span>
                                                      </button>
                                                      {/* <a className="btn btn-default mr-10 btn-small ml-auto" href="#"><i className="fa fa-file-text-o mr-10" aria-hidden="true"></i>Share Resume</a> */}
                                                   </div>
                                                   <h5>{jobAllDetail.job_title}</h5>
                                                   <div dangerouslySetInnerHTML={{__html: jobAllDetail.description}} ></div>
                                                </div>
                                                <div className="col-lg-4">
                                                   <RecruiterDetails userdata={recruiterAllDetail.userdata} />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="tab-pane fade" id="tab-people" role="tabpanel" aria-labelledby="tab-people">
                                             <div className="row">
                                                <div className="col-lg-8">
                                               
                                                   <h5> {recruiterAllDetail.active_jobs.length ? 'Active Jobs' : 'No active jobs avaliable at the moment.'}    </h5>
                                                   <div className="row display-list recuruteractivejobs">
                                                      
                                                      {
                                                         recruiterAllDetail.active_jobs.map((jobItem,index) => (
                                                               <>
                                                                  <div key={index+1} className="col-xl-12 col-12">
                                                                     <div className="card-grid-2 hover-up"><span className="flash"></span>
                                                                           <div className="row">
                                                                              <div className="col-lg-6 col-md-6 col-sm-12">
                                                                                 <div className="card-grid-2-image-left">
                                                                                       <h4><Link to={`/job-details?jobid=${jobItem.id}`} className='text-capitalize'>{jobItem.job_title}</Link></h4>
                                                                                 </div>
                                                                              </div>
                                                                              <div className="col-lg-6 text-start text-md-end pr-60 col-md-6 col-sm-12">
                                                                                 <div className="mt-10"> 
                                                                                       {
                                                                                          jobItem.applied_candidate ? 
                                                                                          <><Link className="btn btn-grey-small mr-5" to={`/appliedjobs?jobid=${jobItem.id}`}>Applied Candidates ( {jobItem.applied_candidate} )</Link></> 
                                                                                          : <><span className="btn btn-grey-small mr-5" href="#">Applied Candidates ( 0 )</span></>
                                                                                       }
                                                                                       <a className="btn btn-grey-small mr-5" href="#">Active</a>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                           <div className="card-block-info">
                                                                              <div className="mt-5 mb-10"><span className="card-briefcase">Part time</span><span className="card-time"><span>5</span><span> days ago</span></span></div>
                                                                              <div className="row"><div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>Location :</strong>{jobItem.city_data+' ('+jobItem.state_data+')'}</p></div>
                                                                                 <div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>No.of Vacancies :</strong> {jobItem.no_of_posts}</p></div> 
                                                                                 <div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>Job Industry :</strong> {jobItem.industry_data}</p></div>
                                                                                 <div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>Job Function :</strong> {jobItem.job_function} </p></div>
                                                                                 <div className="col-md-12 mt-5">
                                                                                       <p className="font-sm color-text-paragraph">
                                                                                       <strong>Skills :</strong>
                                                                                       {
                                                                                          jobItem.job_skills.map((skill_item,index2) => (
                                                                                             <a key={index2+1} className="btn btn-grey-small mr-5" href="#">{skill_item}</a>
                                                                                          ))
                                                                                       }
                                                                                       </p>
                                                                                 </div>
                                                                              </div>
                                                                              <div className="card-2-bottom mt-20">
                                                                                 <div className="row">
                                                                                    <div className="col-lg-7 col-7">
                                                                                       <span className="card-text-price">{jobItem.salary_range}</span>
                                                                                       {/* <span className="text-muted">/Hour</span> */}
                                                                                    </div>
                                                                                    <div className="col-lg-5 col-5 text-end">
                                                                                       <div className="btn btn-apply-now" data-bs-toggle="modal"  onClick={(e) => shareResumeToRecruiter(jobItem.id)} >
                                                                                          share Resume
                                                                                       </div>
                                                                                    </div>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                     </div>
                                                                  </div>
                                                               </>
                                                         ))
                                                      }
                                                   </div>
                                                </div>
                                                <div className="col-lg-4 recrutersimmiler">
                                                   <h5>Similar jobs</h5>
                                                   <div className="sidebar-border mb-20">
                                                      <div className="sidebar-list-job similarjobs">
                                                         <ul>
                                                            
                                                            {
                                                               recruiterAllDetail.similer_jobs.length ?
                                                               <>
                                                                  {
                                                                     recruiterAllDetail.similer_jobs.map((item,index) => (
                                                                        <li>
                                                                           <div className="card-list-4 hover-up" >
                                                                              <div className="image">
                                                                                 <Link to={`/job-details?jobid=${item.id}`}>
                                                                                    <img src={item.company_data.profile_pic_url} alt="jcbapp" className="similer_jobsImage" />
                                                                                 </Link>
                                                                              </div>
                                                                              <div className="info-text">
                                                                                 <h5 className="font-md font-bold color-brand-1 m-0">
                                                                                    <Link to={`/job-details?jobid=${item.id}`}>{item.job_title}</Link> 
                                                                                    <div className="simijoblocationl">
                                                                                       <i className="fa fa-map-marker mr-10" aria-hidden="true"></i><span className="location-small">{item.location_id ? item.location_id : '.......'}</span>
                                                                                    </div>
                                                                                 </h5>
                                                                                 <div className="mt-0"><span className="card-briefcase">{item.jobtype}</span><span className="card-time"><span>{item.timeago_duration}</span></span></div>
                                                                                 <div className="mt-5">
                                                                                    <div className="row">
{/*                                                                                    
                                                                                       <div className="col-612text-end"><a className="btn btn-default mr-10 btn-small" href="#">Share Resume</a></div>
                                                                                    */}
                                                                                    </div>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                        </li>
                                                                     ))
                                                                  }
                                                               </>
                                                               : ''
                                                            }
                                                            

                                                         </ul>
                                                      </div>
                                                   </div>
                                                      <RecruiterDetails userdata={recruiterAllDetail.userdata} />
                                                </div>
                                             </div>
                                          </div>

                                       </div>
                                    </div>
                                 </div>

                                 
                                 <div className="col-lg-12 col-md-12 col-sm-12 col-12 recruterslider">
                                    <div className="row clientslider">
                                       <div className="col-md-12">
                                          <h5>Our Clients</h5>
                                       </div>
                                       <div className="col-md-12">
                                          <div className="clientsliderwrap client_logo_slider">
                                             <OwlCarousel className='owl-theme' loop margin={30} items={5} autoplay={true} autoplayTimeout={2000}>
                                                {
                                                   recruiterAllDetail.clientlogo_data ? 
                                                   <>
                                                      {
                                                         recruiterAllDetail.clientlogo_data.map((item,index) => (
                                                            <div className='item'>
                                                               <a href="#"><img src={recruiterAllDetail.client_logo_baseurl+'/'+item.brand_image} /></a>
                                                            </div>
                                                         ))
                                                      }
                                                      
                                                   </> : ''
                                                }
                                             </OwlCarousel>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </section>  
                     </>
                     : ''
                  }
                  
               </div>
                <SubscribeModule />
                
                <Modal show={isResumeModalShow} onHide={closeResumeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Resume Upload</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {
                        isLoggedIn ? 
                        <> 
                        <form onSubmit={postResumeSubmit} className="form-horizontal login-frm" name="resetForm" id="resetForm" method="POST" autocomplete="off" enctype="multipart/form-data" novalidate="novalidate">
                              <div className="col-md-12">
                                 <div className="form-group login-grp">
                                       <label>Upload Resume</label>
                                       <input type="file" required={true} className="form-control login-ctrl" id="upload_resume" name="upload_resume" accept=".doc, .docx,.pdf"  />
                                 </div>
                              </div>
                              <div className="col-md-12">
                                 <div className="form-group mt-2">
                                       <input type="hidden"  id="ru_post_id" name="ru_post_id" value="0" />
                                       <input type="hidden"  id="ru_post_type" name="ru_post_type" value="0" />
                                       <input type="hidden"  id="ru_user_id" name="ru_user_id" value="0" />
                                       <button type="submit" disabled={isResumeShareBtn} className="btn btn-brand-1 hover-up w-100">Submit</button>
                                 </div>
                              </div>
                           </form>
                        </>
                        : 
                        <>
                           <p>
                              You can't upload resume without login in application
                           </p>
                        </>
                      }
                       
                    </Modal.Body>
                </Modal> 
            </>
        );
} 
export default RecruiterDetails;