import React,{useState,useEffect} from "react";
import { Link , Navigate , useNavigate} from "react-router-dom";
import { Modal, Button , Col, Row, Form } from 'react-bootstrap';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from "axios";
//const MyStyle = {visibility: visible , animationDelay:' 0.1s' , animationName: fadeInUp }
const MyStyle = '';

const LetsTalk = () => {
   const [isResumeModalShow , isSetResumeModalShow] = useState(false);

   
  /* Resume upload by modal*/
  const openLetsConnectModel = (id)   => {
   isSetResumeModalShow(true);
 }
 
 const  closeResumeModal = () => {
   isSetResumeModalShow(false)
 };
 /* Resume upload by modal*/


   const navigate = useNavigate();
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const formData = new FormData();
      //name contact_number  company_name email service_type service_need group_desc
      formData.append('name', event.target.name.value);
      formData.append('phone', event.target.contact_number.value);
      formData.append('company', event.target.company_name.value);
      formData.append('email_id', event.target.email.value);
      formData.append('message', event.target.group_desc.value);
      formData.append('contact_type_action', 'lets_connect');
      try {
        let res = await fetch("https://jobchatbox.com/api/cms/contact_form_action", {
          method: "POST",
          body: formData ,
        });
        let resJson = await res.json();
        if (res.status === 200) {
            (resJson.status) ? toast.success(resJson.message) :  toast.warning(resJson.message)   ; 
            closeResumeModal()
            // setTimeout(function(){
            //   navigate("/thank-you");
            // },1000)
          // <Navigate to="/thank-you"/>
        } else {
           // notify('error','Some error occured.');
        }
      } catch (err) {
          console.log(err);
      }
    }
    setValidated(true);  
  }


  const [contactNumber, setContactNumber] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleContactNumberChange = (e) => {
    const { value } = e.target;
    // Replace all non-numeric characters in the input
    const formattedNumber = value.replace(/\D/g, "");
    setContactNumber(formattedNumber);
  };


    return(
        <section className="section-box bg-border-3 pt-100 pb-100 lets_talk">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-6">
                    <img className="bdrd-10" src="front/assets/imgs/contacthomeimg.png" alt="jcbapp" />
                </div>
                <div className="col-lg-6">
                    <div className="pl-30">
                    <h5 className="color-brand-2 mb-15 mt-15">DIAL-A-TONE! </h5>
                    <h2 className="color-brand-1 mt-0 mb-15">Jobchatbox got you covered for top-notch customer service.</h2>
                    <p className="font-lg color-text-paragraph-2"><strong>Let’s connect!</strong></p>
                    <p className="font-lg color-text-paragraph-2">We are here and dedicated to answer your questions</p>
                    <div className="mt-20"><a className="btn btn-default" onClick={(e) => openLetsConnectModel()} >Connect Now</a></div>
                    </div>
                </div>
                </div>
            </div>


            <Modal show={isResumeModalShow} onHide={closeResumeModal}>
                  <Modal.Body>
                     <>
                     <button className="btn-close" type="button"  onClick={closeResumeModal} data-bs-dismiss="modal" aria-label="Close"></button>
                     <div className="modal-body pl-10 pr-10 pt-20">
                        <div className="text-center">
                           <p className="font-sm text-brand-2">Let's Connect!</p>
                           <h3 className="mt-10 text-brand-1 text-capitalize mb-20">Please fill the below fileds to connect with us.</h3>
                        </div>
                        <Form className="login-register popupconnect text-start mt-20 pb-30"  noValidate validated={validated} onSubmit={handleSubmit}   autocomplete="off" >
                           <div className="row">
                              <div className="col-lg-6 col-md-6">
                                 <div className="input-style mb-1">
                                    <label className="form-label">Name *</label>
                                    <Form.Control
                                       required
                                       type="text"
                                       name="name"
                                       placeholder="Enter your name"
                                    />
                                 </div>
                              </div>
                              <div className="col-lg-6 col-md-6">
                                 <div className="input-style mb-1">
                                       <label className="form-label">Company Name / Website url</label>
                                       <Form.Control
                                          type="text"
                                          name="company_name"
                                          placeholder="Company Name / Website url"
                                          
                                       />
                                 </div>
                              </div>
                              <div className="col-lg-6 col-md-6">
                                 <div className="input-style mb-1">
                                    <label className="form-label">Email *</label>
                                    <Form.Control
                                    required
                                    type="email"
                                    name="email"
                                    placeholder="Enter your email address"
                                    className="form-control"
                                    />
                                 </div>
                              </div>
                              <div className="col-lg-6 col-md-6">
                                 <div className="input-style mb-1">
                                       <label className="form-label">Phone Number *</label>
                                       <Form.Control
                                             required
                                             type="tel"
                                             name="contact_number"
                                             placeholder="Enter your contact number"
                                             maxLength={10}
                                             value={contactNumber}
                                             onChange={handleContactNumberChange}
                                             isInvalid={!isValid}
                                       />
                                 </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                 <div className="form-group">
                                 <label className="form-label">Description</label>
                                 <textarea required="" rows="3" name="group_desc" id="group_action_form" className="form-control"></textarea>
                                 </div>
                              </div>

                              <div className="col-lg-12 col-md-12">
                                 <button className="submit btn btn-send-message" type="submit">Send message</button>
                              </div>
                           </div>
                        </Form>
                     </div>
                     </>
                  </Modal.Body>
            </Modal>


            </section>

        );
}

export default LetsTalk;