import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Feedback from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Form';
import { Nav, NavLink, NavMenu } from "../components/NavbarElements";
// /import axios from "axios";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import PlacementSignuForm from './forms_temp/PlacementSignuForm';

const Placement_signup = () => {

  

  return (
    <>
      <section className="pt-70 login-register">
        <div className="container">
          <div className="row login-register-cover pb-70">
            <div className="col-lg-2 col-md-2 col-sm-12">
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 registrationcol">
              <div className="formwraper">
                <div className="text-center">
                  <p className="font-sm text-brand-2">Register </p>
                  <h3 className="mt-10 mb-5 text-brand-1">Start for free Today</h3>
                  <p className="font-sm text-muted mb-30">Access to all features. No credit card required.</p>
                </div>
                <div className="tab-content registrationtabcon" id="myTabContent-1">
                  <div className="tab-pane fade show active" id="tab-job-1" role="tabpanel" aria-labelledby="tab-job-1">
                      <PlacementSignuForm loggedin={false}  />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Placement_signup;