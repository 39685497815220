import React,{useState,useEffect} from 'react';
import LeftSidebar from '../include/left_sidebar';
import PlacementSignuForm from '../../front/pages/forms_temp/PlacementSignuForm';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MyProfile = () => {
   
    return (
      <>
         <ToastContainer />
          <div className="dashboardsec pt-70 pb-70">
           <div className="container">
            
            <div className="row">
              <div className="col-lg-3">
               <LeftSidebar />
              </div>
              
              <div className="col-lg-9">
                  <div className="notificationwraper">
                        <div class="head-border mb-30 d-flex justify-content-between align-items-center"><h5>Personal Detail</h5></div>
                     <div class="grayborderbox mb-30">
                        <div class="box-padding">
                            <div class="row">
                                <div class="col-lg-12">
                                    <PlacementSignuForm  loggedin={true} />
                                </div>
                            </div>
                        </div>
                    </div>


                  </div>

              </div>
 
            </div>
          </div>
        </div>
      </>
    );
  };
export default MyProfile;