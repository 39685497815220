import React,{useEffect,useState} from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';
import { Link } from "react-router-dom";
import { Col, Form } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const My_jobs_post = () => {
    var storedNames = JSON.parse(localStorage.getItem("userlogin"));
    const [allPostList, setAllPostList] = useState([]);
    const [totalJobs, setTotalJobs] = useState(0);
    useEffect(() => {
        (async () => {
            try {
            const states_list = await axios.get("https://jobchatbox.com/api/user/api/myjobs/"+storedNames.login_token);
            if(states_list.data.status == 1){
                setAllPostList(states_list.data.data.jobs)
                setTotalJobs(states_list.data.data.total_jobs)
            }
            } catch (error) {
            console.log(error);
            }
        })();

    }, []);

    const removeJobFromList = (id) => {
        if (window.confirm('Do you want to remove job?') == true) {
            (async () => {
                try {
                const responceData = await axios.get("https://jobchatbox.com/api/user/api/delete_job/"+storedNames.login_token+'/'+id);
                if(responceData.data.status == 1){
                    toast.success(responceData.data.message);
                    setAllPostList(responceData.data.data.jobs)
                }
                } catch (error) {
                console.log(error);
                }
            })();
        }
    }

    const jobStatusChange = (id) => {
        if (window.confirm('Do you want to change status ?') == true) {
            (async () => {
                try {
                const responceData = await axios.get("https://jobchatbox.com/api/user/api/job_status_change/"+storedNames.login_token+'/'+id);
                if(responceData.data.status == 1){
                   // alert(responceData.data.message)
                    toast.success(responceData.data.message);
                    setAllPostList(responceData.data.data.jobs)
                }
                } catch (error) {
                console.log(error);
                }
            })();
        }
    }

    return (
      <>
         <ToastContainer />
          <div className="dashboardsec pt-30 pb-30">
           <div className="container">
            
            <div className="row">
              <div className="col-lg-3">
               <LeftSidebar />
              </div>
              <div className="col-lg-9">
                <div className="section-box jobpost-sec">
                        <div className="head-border mb-30 d-flex justify-content-between align-items-center">
                        <h5>Posted {totalJobs} jobs</h5>
                        <Link to="/post-create?backpage=mypost" className="btn btn-default btn-small ml-auto">+ Post A Job</Link>
                        </div>
                        <div className="row display-list">
                            {
                                allPostList.length  ?
                                <>
                                    {
                                        allPostList.map((jobItem,index) => (
                                            <>
                                                <div key={index+1} className="col-xl-12 col-12">
                                                    <div className="card-grid-2 hover-up myjobpostingblock"><span className="flash"></span>
                                                        <div className="row">
                                                            <div className="col-lg-5 col-md-5 col-sm-12">
                                                                <div className="card-grid-2-image-left pb-0">
                                                                    <h5><Link to={`/job-details?jobid=${jobItem.id}`} className='text-capitalize'>{jobItem.job_title}</Link></h5>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-7 text-start text-md-end pr-60 col-md-7 col-sm-12 myjobpostright">
                                                                <div className="pl-15 mt-15"> 
                                                                    {
                                                                        jobItem.applied_candidate ? 
                                                                        <><Link className="btn btn-grey-small mr-5 mb-1" to={`/appliedjobs?jobid=${jobItem.id}`}>Applied Candidates ( {jobItem.applied_candidate} )</Link></> 
                                                                        : <><span className="btn btn-grey-small mr-5 mb-1" href="#">Applied Candidates ( 0 )</span></>
                                                                    }
                                                                    <a className="btn btn-grey-small mr-5 mb-1" href="#">Active</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-block-info">
                                                            <div className="mt-5 mb-10"><span className="card-briefcase">Part time</span><span className="card-time"><span>5</span><span> days ago</span></span></div>
                                                            <div className="row"><div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>Location :</strong>{jobItem.city_data+' ('+jobItem.state_data+')'}</p></div>
                                                                <div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>No.of Vacancies :</strong> {jobItem.no_of_posts}</p></div> 
                                                                <div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>Job Industry :</strong> {jobItem.industry_data}</p></div>
                                                                <div className="col-md-6 mb-5"><p className="font-sm color-text-paragraph"><strong>Job Function :</strong> {jobItem.job_function} </p></div>
                                                                <div className="col-md-12 mt-5">
                                                                    <p className="font-sm color-text-paragraph">
                                                                    <strong>Skills :</strong>
                                                                    {
                                                                        jobItem.job_skills.map((skill_item,index2) => (
                                                                            <a key={index2+1} className="btn btn-grey-small mr-5 mb-1" href="#">{skill_item}</a>
                                                                        ))
                                                                    }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex mt-20 myjobbtns flex-wrap work-experiencebtn">
                                                                <Link onClick={(e) => jobStatusChange(jobItem.id)}  className="btn btn-default btn-small mr-10 mb-5"><i class="fa fa-wrench" aria-hidden="true"></i>Make It {(jobItem.status == 1)  ? `Disable` : `Enable`} </Link>
                                                                <Link target="_blank"  to={`/job-details?jobid=${jobItem.id}`} className="btn btn-default btn-small mr-10 mb-5"><i class="fa fa-file-text-o" aria-hidden="true"></i>Job Detail</Link>
                                                                <Link to={`/post-create?backpage=mypost&jobid=${jobItem.id}`} className="btn btn-default btn-small mr-10 mb-5"><i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                                Edit Job</Link>
                                                                <Link onClick={(e) => removeJobFromList(jobItem.id)} className="btn btn-default btn-small mb-5"><i className="fa fa-trash-o" aria-hidden="true"></i>
                                                                Delete Job</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ))
                                    }
                                </>
                                :'You have no created any Job Post'
                            }
                            
                            
                        </div>
                    
                </div>
            </div>
              


            </div>
          </div>
        </div>
      </>
    );
  };
export default My_jobs_post;