import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Feedback from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Form';
// /import axios from "axios";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PlacementSignuForm = (prop) => {

    const [formData, setFormData] = useState({
        fullName: '',
        companyName: '',
        email: '',
        password: '',
        confirmPassword: '',
        website_link: '',
        companyLogo: null,
        acceptAgreement: false,
    });

    const [errors, setErrors] = useState({});
    const [checkPasswordSame, setCheckPasswordSame] = useState('');
    const [checkPhoneNumber, setCheckPhoneNumber] = useState('');

    const handleChange = (e) => {
        setCheckPhoneNumber('');
        const { name, value, type, checked } = e.target;
        const newValue = type === 'file' ? e.target.files[0] : type === 'checkbox' ? checked : value;
        if (name == 'contactNumber') {
            if (/^\d{0,10}$/.test(newValue)) {
                setFormData({ ...formData, [name]: newValue, });
            }

        } else {
            setFormData({ ...formData, [name]: newValue, });
        }
    };

    const [validated, setValidated] = useState(false);
    const [submitBtnDisable, setsubmitBtnDisable] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        setCheckPasswordSame('');
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else if (formData.password != formData.confirmPassword) {
            event.stopPropagation();
            setCheckPasswordSame('Passwords do not match');
        } else {
            setsubmitBtnDisable(true)
            const formData_send = new FormData();
            formData_send.append('fullName', formData.fullName);
            formData_send.append('companyName', formData.companyName);
            formData_send.append('email', formData.email);
            formData_send.append('phone_number', formData.contactNumber);
            formData_send.append('password', formData.password);
            formData_send.append('re_password', formData.confirmPassword);
            formData_send.append('profile_image', formData.companyLogo);
            formData_send.append('website_link', formData.website_link);
            formData_send.append('accept_agreeiment', formData.acceptAgreement);

            try {
                let res = await fetch("https://jobchatbox.com/api/signup/training_placement", {
                    method: "POST",
                    body: formData_send,
                });
                let resJson = await res.json();
                if (res.status === 200) {
                    setsubmitBtnDisable(false)
                    console.log(res)
                    if (resJson.status == 1) {
                        setTimeout(function () { window.location.href = "/thank-you"; }, 1000);
                    } else {
                        toast.success(resJson.message)
                    }
                }
            } catch (err) {
                console.log(err);
            }
        }
        setValidated(true);
    };


    const [showPassword, setShowPassword] = useState(false);
    const hideAndShowPass = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    }

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit} className="login-register text-start mt-20" action="#">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="input-1">Full Name *</label>
                            <input
                                className="form-control"
                                type="text"
                                name="fullName"
                                value={formData.fullName}
                                onChange={handleChange}
                                required={true}
                                placeholder="Steven Job"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="input-1">Company Name *</label>
                            <Form.Control
                                className="form-control"
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleChange}
                                required={true}
                                placeholder="Steven Job"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="input-2">Email *</label>
                            <Form.Control
                                className="form-control"
                                type="email"
                                name="email"
                                required={true}
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="stevenjob@gmail.com"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="form-label" htmlFor="input-6">Phone Number *</label>
                            <Form.Control
                                className="form-control"
                                id="input-6" type="text"
                                required={true}
                                placeholder="Phone Number"
                                name="contactNumber"
                                value={formData.contactNumber}
                                onChange={handleChange}
                                max={10}
                            />
                        </div>
                        {checkPhoneNumber && <div className="form-group passcheck">
                            {checkPhoneNumber}
                        </div>}
                    </div>

                    <div className="col-md-6">
                        <div className="form-group passwordwrap">
                            <label className="form-label" htmlFor="input-4">Password *</label>
                            <Form.Control
                                className="form-control"
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                required={true}
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                placeholder="************"
                            />
                            <span class="eyebtn" onClick={hideAndShowPass} ><img src="front/assets/imgs/eye.svg" /></span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group passwordwrap">
                            <label className="form-label" htmlFor="input-5">Re-Password *</label>
                            <Form.Control
                                className="form-control"
                                id="re_password"
                                type={showPassword ? 'text' : 'password'}
                                required={true}
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                placeholder="************" />
                            <span class="eyebtn" onClick={hideAndShowPass} ><img src="front/assets/imgs/eye.svg" /></span>
                        </div>
                    </div>
                    {checkPasswordSame && <div className="col-md-12 passcheck">
                        {checkPasswordSame}
                    </div>}

                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-label" htmlFor="ProfileImage">Profile Image *</label>
                            <Form.Control
                                className="form-control"
                                type="file"
                                name="companyLogo"
                                required={true}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    
                    
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-label" htmlFor="ProfileImage">Website *</label>
                            <Form.Control
                                className="form-control"
                                type="text"
                                name="website_link"
                                required={true}
                                onChange={handleChange}
                                placeholder={'Website Link'}
                            />
                        </div>
                    </div>

 
                    { !prop.loggedin  && <div className="col-md-12">
                        <div className="login_footer form-group d-flex justify-content-between">
                            <Form.Check
                                label="By clicking, you agree with privacy policy and terms of use"
                                feedback="You must agree before submitting."
                                feedbackType="invalid"
                                type="checkbox"
                                required={true}
                                name="acceptAgreement"
                                checked={formData.acceptAgreement}
                                onChange={handleChange}
                            />

                            <Link className="text-muted" target='_blank' to="/aboutus">Lean more</Link>
                        </div>
                    </div> }
                    <div className="col-md-12">
                        <div className="form-group">
                            <button 
                            disabled={submitBtnDisable} 
                            className = {`${prop.loggedin ? 'btn btn-default btn-brand icon-tick' : 'btn btn-brand-1 hover-up w-100'} `} 
                            type="submit" 
                            name="login">
                                Register Now
                            </button>
                        </div>
                    </div>

                    {
                        !prop.loggedin &&
                        <>
                            <div className="col-md-12">
                                <div className="text-muted text-center">Already have an account?
                                    <Link to="/login"  > Sign in</Link>
                                </div>
                            </div>
                        </>
                    }
                    
                </div>
            </Form>
        </>
    );
};
export default PlacementSignuForm;