import React,{useState,useEffect} from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';
 
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Profile_performance  = () => {

    useEffect(() => {  window.scrollTo(0, 0) }, [])
    
    const [performaceData, setPerformaceData] = useState([{
                                                            "total_applied_jobs": 0,
                                                            "total_company_viewed": 0,
                                                            "total_favourites_jobs": 0,
                                                            "total_shared_post": 0,
                                                            "total_connections": 0,
                                                            "total_groups": 0
                                                        }]);
    let userdata  = JSON.parse(localStorage.getItem("userloginSession"));

    useEffect(() => {
        (async () => {
            try {
           
            const states_list = await axios.get("https://jobchatbox.com/api/user/api/profile_performance/"+userdata.login_token);
            if(states_list.data.status == 1){
                console.log(states_list.data.data)
                setPerformaceData(states_list.data.data)
            }
            } catch (error) {
            console.log(error);
            }
        })();
     }, []);

    return (
      <>
        <div className="dashboardsec pt-30 pb-30">
            <div className="container">
               <div className="row">
                  <div className="col-lg-3">
                        <LeftSidebar />
                  </div>
                  <div className="col-lg-6">
                  <div className="profileperformancewrap">
                    <div className="jobsickerdashboard_btm profileperformance">
                    <div className="row"> 
                        <div className="col-12">
                            <div className="head-border mb-30"><h5>Profile Performance</h5></div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-sm-6">
                            <Link to={'/applied-jobs'} className="card-style-1 hover-up">
                                <div className="card-image"> 
                                <img src="front/assets/imgs/applyjobs.png" alt="jcbapp" /></div>
                                <div className="card-info"> 
                                <div className="card-title">
                                    <h3>{performaceData.total_applied_jobs}</h3>
                                </div>
                                <p className="color-text-paragraph-2">Applied Jobs</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-sm-6">
                            <Link to={'#'} className="card-style-1 hover-up">
                                <div className="card-image"> 
                                <img src="front/assets/imgs/companyview.png" alt="jcbapp" /></div>
                                <div className="card-info"> 
                                <div className="card-title">
                                <h3>{performaceData.total_company_viewed}</h3>
                                </div>
                                <p className="color-text-paragraph-2">Companies viewed</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-sm-6"> 
                            <Link to={'/favorite-jobs'} className="card-style-1 hover-up">
                                <div className="card-image"> 
                                <img src="front/assets/imgs/favjob.png" alt="jcbapp" /></div>
                                <div className="card-info"> 
                                <div className="card-title">
                                    <h3>{performaceData.total_favourites_jobs}</h3>
                                </div>
                                <p className="color-text-paragraph-2">Favourite jobs</p>
                                </div>
                            </Link>
                        </div>
                        
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-sm-6">
                            <Link to={'/mytimeline?candidate_id='+userdata.unique_id} className="card-style-1 hover-up">
                                <div className="card-image"> 
                                <img src="front/assets/imgs/share-post.png" alt="jcbapp" /></div>
                                <div className="card-info"> 
                                <div className="card-title">
                                <h3>{performaceData.total_shared_post}</h3>
                                </div>
                                <p className="color-text-paragraph-2">Shared Posts</p>
                                </div>
                            </Link>
                        </div>
                        
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-sm-6">
                            <Link to={'/connections'} className="card-style-1 hover-up">
                                <div className="card-image"> 
                                <img src="front/assets/imgs/connection.png" alt="jcbapp" /></div>
                                <div className="card-info"> 
                                <div className="card-title">
                                <h3>{performaceData.total_connections}</h3>
                                </div>
                                <p className="color-text-paragraph-2">Connections</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-sm-6">
                            <Link to={'/groups'} className="card-style-1 hover-up">
                                <div className="card-image"> 
                                <img src="front/assets/imgs/meeting.png" alt="jcbapp" /></div>
                                <div className="card-info"> 
                                <div className="card-title">
                                <h3>{performaceData.total_groups}</h3>
                                </div>
                                <p className="color-text-paragraph-2">Groups</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    </div>
                </div>
                  </div>
                  <div className="col-lg-3">
                        <RightSidebar />
                  </div>
                </div>
            </div>
        </div>
    </>
    )
}
export default Profile_performance ;
