import React,{useState,useEffect} from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from "axios";

const Notifications = () => {
   const [notificationData, setNotificationData] = useState([]);
   var storedNames = JSON.parse(localStorage.getItem("userlogin"));

   useEffect(() => {
      (async () => {
          try {
            const resdata = await axios.get("https://jobchatbox.com/api/user/api/notifications/"+storedNames.login_token);
            if(resdata.data.status == 1){
               setNotificationData(resdata.data.data.results)
            }
          } catch (error) {
          console.log(error);
          }
      })();
   }, []);

   const notify = (status_type,message) => {
      switch (status_type) {
          case 'warning':
              toast.warning(message)  
          break;

          case 'success':
              toast.success(message)  
          break;

          case 'info':
              toast.info(message)  
          break;

          case 'error':
              toast.error(message)  
          break;
      }
   };

   const renderSwitch  =  (param,groupId) => {
      switch(param) {
        case 'save_group_request':
            return <><button type="button" onClick={acceptGroupRequest} id={groupId} className="btn btn-outline-primary btn-sm mr-10">Accept</button>
                     <button type="button" onClick={declineGroupRequest} id={groupId}   className="btn btn-outline-primary btn-sm ">Decline</button></>;
     
         default:
            return '';
      }
   }

   const renderSwitchTitle  =  (param) => {
      switch(param) { 
         case 'save_group_request':
          return 'Group Requested' ;
         case 'accept_group_request':
            return 'Group Request Accepted'   ; 
         case 'cancel_group_request':
            return 'Group Request Cancelled'   ;  
         case 'decline_group_request':
             return 'Group Declined'   ;      
         case 'save_connection':
             return 'Connection Requested'   ;      
         case 'accept_connection':
             return 'Connection Connected'   ;      
         default: 
            return '';
      }
   }

   const acceptGroupRequest = (e) => {
      let notificationID  = e.target.id ;
      (async () => {
         try {
            const resdata = await axios.get("https://jobchatbox.com/api/user/api/accept_group_request/"+storedNames.login_token+"/"+notificationID);
            // console.log(resdata.data.data.notifications);
            if(resdata.data.status == 1){
               setNotificationData(resdata.data.data.results) ;
               notify('info',resdata.data.message)
            } else  if(resdata.data.status == 2){
               notify('warning',resdata.data.message)
            } else {
               notify('error',resdata.data.message)
            }
         } catch (err) {
               console.log(err);
         }
      })();
   }

   const declineGroupRequest = (e) => {
      let notificationID  = e.target.id ;
      (async () => {
         try {
            const resdata = await axios.get("https://jobchatbox.com/api/user/api/decline_group_request/"+storedNames.login_token+"/"+notificationID);
            if(resdata.data.status == 1){
               setNotificationData(resdata.data.data.results) ;
               notify('info',resdata.data.message)
            } else  if(resdata.data.status == 2){
               notify('warning',resdata.data.message)
            } else {
               notify('error',resdata.data.message)
            }
         } catch (err) {
               console.log(err);
         }
      })();
   }

   const deleteNotification = (e) => {
      let notificationID  = e.target.id ;
      if (window.confirm('Do you want to remove notification from list ?') == true) {
         (async () => {
               try {
               const resdata = await axios.get("https://jobchatbox.com/api/user/api/notification_remove/"+storedNames.login_token+"/"+notificationID);
               if(resdata.data.status == 1){
                  setNotificationData(resdata.data.data.results);
                  notify('info',resdata.data.message)
               }
               } catch (error) {
                  console.log(error);
               }
         })();
      }
   }

   // const checkRequested = (val) => {
   //    return requestGroups.some(item => val === item.groupid);
   // }

    return (
      <>
         <ToastContainer />
          <div className="dashboardsec pt-70 pb-70">
           <div className="container">
            
            <div className="row">
              <div className="col-lg-3">
               <LeftSidebar />
              </div>
              
              <div className="col-lg-9">
                  <div className="notificationwraper">
                     <div className="box shadow-sm rounded bg-white mb-3">
                        <div className="box-title border-bottom p-3">
                           <h6 className="m-0">Recent</h6>
                        </div>
                        <div className="box-body p-0 notification_loop_list">
                           {

                           notificationData.length  ? 
                              notificationData.map((notiItem,index) => (
                                 <>
                                 <div key={notiItem.unique_key} id={notiItem.unique_key} className="p-3 d-flex align-items-center bg-light border-bottom osahan-post-header">
                                    <div className="dropdown-list-image mr-3"> 
                                       <img className="rounded-circle imgsrc" src={notiItem.userdata.profile_pic} alt="" /></div>
                                    <div className="font-weight-bold mr-3">
                                       <div className="text-truncate">{ renderSwitchTitle(notiItem.notificationFor) }</div>
                                       <div className="small mb-2" dangerouslySetInnerHTML={{__html: notiItem.nMessage}}></div>
                                       {
                                          renderSwitch(notiItem.notificationFor,notiItem.id)
                                       }   
                                    </div>
                                    <span className="ml-auto notificationlast"> 
                                       <div className="btn-group">
                                          <button type="button" className="btn btn-light btn-sm rounded notificationddbtn"> <i className="fa fa-ellipsis-v" aria-hidden="true"></i> </button>
                                          <div className="dropdown-menu dropdown-menu-right"> 
                                             <button className="dropdown-item" onClick={deleteNotification} id={notiItem.id} type="button"><i className="fa fa-trash" aria-hidden="true"></i> Delete</button> 
                                             <button className="dropdown-item display_none" type="button"><i className="fa fa-power-off" aria-hidden="true"></i> Turn Off</button>
                                          </div>
                                       </div>
                                       <br/>
                                       <div className="text-right text-muted pt-1">{notiItem.dayago}</div>
                                    </span>
                                 </div>
                                 </>
                              )) : 
                              <>
                                 <div className='no_notification_data'>
                                    You have no notifications
                                 </div>
                              </>
                           }
                           
                          
                        </div>
                     </div>


                     <div className="display_none  box shadow-sm rounded bg-white mb-3">
                        <div className="box-title border-bottom p-3">
                           <h6 className="m-0">Earlier</h6>
                        </div>
                        <div className="box-body p-0">
                           <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                              <div className="dropdown-list-image mr-3 d-flex align-items-center bg-danger justify-content-center rounded-circle text-white">DRM</div>
                              <div className="font-weight-bold mr-3">
                                 <div className="text-truncate">DAILY RUNDOWN: MONDAY</div>
                                 <div className="small">Nunc purus metus, aliquam vitae venenatis sit amet, porta non est.</div>
                              </div>
                              <span className="ml-auto notificationlast">
                                 <div className="btn-group">
                                    <button type="button" className="btn btn-light btn-sm rounded notificationddbtn"> <i className="fa fa-ellipsis-v" aria-hidden="true"></i> </button>
                                    <div className="dropdown-menu dropdown-menu-right" > <button className="dropdown-item" type="button"><i className="fa fa-trash" aria-hidden="true"></i> Delete</button> <button className="dropdown-item" type="button"><i className="fa fa-power-off" aria-hidden="true"></i> Turn Off</button></div>
                                 </div>
                                 <br/>
                                 <div className="text-right text-muted pt-1">3d</div>
                              </span>
                           </div>
                        </div>
                     </div>

                  </div>

              </div>
 
            </div>
          </div>
        </div>
      </>
    );
  };
export default Notifications;