import React, { useState, useEffect } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import {useNavigate} from 'react-router-dom';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';

function GmailLoginApp() {
    const navigate = useNavigate();
    const [ user, setUser ] = useState([]);
    const [ profile, setProfile ] = useState([]);

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });


    const search_and_get_results2 = async (gemail='',token='') => {

        (async () => {
            const loginFormData = {
                email : gemail ,
                password : token ,
                logintype : 'gmail' ,
            }
            let getres = await fetch("https://jobchatbox.com/api/home/api/login", {
                method: "POST",
                body: JSON.stringify(loginFormData),
            });
            let resJson = await getres.json();
            console.log(getres)
            if(resJson.status == 1){
                notify('success','Loggedin successfully.');
                if(resJson.data.user_data.usertype  == 3){
                    localStorage.setItem('userlogin',JSON.stringify(resJson.data.user_data));
                    localStorage.setItem('logintype','gmail');
                    localStorage.setItem('userloginSession',JSON.stringify(resJson.data.user_data))
                    setTimeout(function() {
                    navigate('/dashboard');
                    }, 1000);
                } else if(resJson.data.user_data.usertype  == 6){
                    localStorage.setItem('userlogin',JSON.stringify(resJson.data.user_data))
                    localStorage.setItem('userloginSession',JSON.stringify(resJson.data.user_data))
                    setTimeout(function() {
                        navigate('/dashboard');
                    }, 1000);
                } else {
                    notify('warning','This email id is not exist for jobseeker and recruiter.');
                    //navigate('/')
                }
                
            } else {
                notify('warning','Email Id or Password is wrong.');
            }
            
        })();
        
    }

    useEffect(
        () => {
            if (user && user.access_token) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        setProfile(res.data);
                        search_and_get_results2(res.data.email,user.access_token)
                    })
                    .catch((err) => console.log(err));
            }
        },
        [ user ]
    );

    // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
        googleLogout();
        setProfile(null);
    };

    const notify = (status_type,message) => {
        switch (status_type) {
            case 'warning':
                toast.warning(message)  
            break;
      
            case 'success':
                toast.success(message)  
            break;
      
            case 'info':
                toast.info(message)  
            break;
      
            case 'error':
                toast.error(message)  
            break;
        }
      };

    return (
        <div>
            <ToastContainer />
            {/* {profile ? (
                <div>
                    <button onClick={logOut}>Log out</button>
                </div>
            ) : (
                <button onClick={() => login()} className="btn social-login hover-up mb-20"  >
                    <img src="front/assets/imgs/icon-google.svg" alt="jcbapp" /><strong>Sign in with Google</strong>
                </button>
            )} */}
            <button onClick={() => login()} className="btn social-login hover-up mb-20"  >
                    <img src="front/assets/imgs/icon-google.svg" alt="jcbapp" /><strong>Sign in with Google</strong>
                </button>
        </div>
    )
}
export default GmailLoginApp;