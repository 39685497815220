import React,{useState,useEffect} from "react";
import axios from "axios";
import {  NavLink  , useLocation  } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

const FrontFooter = (getparam) => {

    const location = useLocation();
    const isDashboardPage = location.pathname === '/dashboard';
    const isDashboardPage2 = location.pathname === '/jobs';
    // if (isDashboardPage) {
    //     return null; // Hide the footer on the dashboard page
    //   }

    console.log(isDashboardPage)

   // console.log(location.pathname)

    const [secialMediaLinks, setSecialMediaLinks] = useState([]);
    const [footerAddress, setFooterAddress] = useState([]);
    const [baseUrl, setBaseurl] = useState('');
    const [isApiLoad, setIsApiLoad] = useState(0);
    useEffect(() => { 
        (async () => {
            try {
                const getdata = await axios.get("https://jobchatbox.com/api/cms/contact_and_address/1");
                setFooterAddress(getdata.data.data.cms_option);
                setBaseurl(getdata.data.data.base_url);
                const getdata2 = await axios.get("https://jobchatbox.com/api/cms/social_medial");
                setSecialMediaLinks(getdata2.data.data.cms_option);
                setIsApiLoad(1);

            } catch (error) { console.log(error); }
        })();
    }, []);

    return(
           <>

            {
                (isDashboardPage) ? 
                <div className="mt-5"></div> 
                :    
                <footer className="footer mt-50">
                    <div className="container">
                        <div className="row">
                            <div className="footer-col-1 col-md-3 col-sm-12">
                                <Link to="/" className="jobbox_logo">
                                    Jobchatbox
                                    {/* <img className="display_none" alt="jcbapp" src="front/assets/imgs/jobchat_box.png" /> */}
                                </Link>
                                <div className="mt-20 mb-20 font-xs color-text-paragraph-2">Boom through the clutter with Jobchatbox - your shortcut to the right job!</div>
                                <div className="footer-social">
                                {
                                    isApiLoad == 1 ?
                                    <>
                                    <Link className="icon-socials" target="_blank" to={secialMediaLinks.facebook.link}><i className="fa fa-facebook" aria-hidden="true"></i></Link>
                                    <Link className="icon-socials" target="_blank" to={secialMediaLinks.twitter.link}><i className="fa fa-twitter" aria-hidden="true"></i></Link>
                                    <Link className="icon-socials" target="_blank" to={secialMediaLinks.linkedin.link}><i className="fa fa-linkedin" aria-hidden="true"></i></Link>
                                    </>
                                    :''
                                }
                                
                                </div>
                            </div>
                            <div className="footer-col-2 col-md-2 col-xs-6">
                                <h6 className="mb-20 text-capitalize">Quick Links</h6>
                                <ul className="menu-footer">
                                <li><Link smooth  to="/aboutus#top">About us</Link></li>
                                <li><Link smooth to="contactus#top">Contact Us</Link></li>
                                <li><Link smooth to="faq#top">FAQ</Link></li>
                                {/* <li><Link to="#">Blogs</Link></li> */}
                                {/* <li><Link to="blogs">Blogs</Link></li> */}
                                </ul>
                            </div>
                            <div className="footer-col-3 col-md-2 col-xs-6">
                                <h6 className="mb-20">Privacy Policies</h6>
                                <ul className="menu-footer privacylinks">
                                <li> <Link smooth to="refund-policy#top"> Refund &amp; Refund Policy</Link> </li>
                                <li> <Link smooth to="cookies-policy#top"> Cookies Policy</Link> </li>
                                <li> <Link smooth to="disclaimer#top"> Disclaimer</Link> </li>
                                <li> <Link smooth to="terms-of-service#top"> Terms Of Service</Link> </li>
                                <li> <Link smooth to="end-user-license-agreement-eula-of-jobchatbox#top"> End-User License Agreement (EULA) of Jobchatbox</Link> </li>
                                </ul>
                            </div>
                            <div className="footer-col-4 col-md-2 col-xs-6">
                                <h6 className="mb-20">Contact Us</h6>
                             
                                <ul className="menu-footer fcontactmenu">
                                {
                                    isApiLoad == 1 ?
                                    <>
                                    <li>
                                        <span className="contactsicon"><i className="fa fa-map-marker" aria-hidden="true"></i></span>
                                        <span>{footerAddress.address}</span>
                                    </li>
                                    <li>
                                        <span className="contactsicon"><i className="fa fa-volume-control-phone" aria-hidden="true"></i></span>
                                        <a href={"tel:"+footerAddress.contact} >{footerAddress.contact}</a>
                                    </li>
                                    <li>
                                        <span className="contactsicon"><i className="fa fa-envelope-o" aria-hidden="true"></i></span>
                                        <a href={"email:"+footerAddress.email} >{footerAddress.email}</a>
                                    </li>
                                    </>
                                    :''
                                }
                                
                                </ul>
                            </div>
                        </div>
                        <div className="footer-bottom mt-50">
                            <div className="row">
                                <div className="col-md-6"><span className="font-xs color-text-paragraph">Copyright© 2022, Jobchatbox | Powered by <a href="https://www.macrocosms.in/">Macrocosm IT Services</a></span></div>
                                <div className="col-md-6 text-md-end text-start">
                                <div className="footer-social"><Link smooth to="privacy-policy#top" className="font-xs color-text-paragraph">Privacy Policy</Link><Link smooth className="font-xs color-text-paragraph mr-30 ml-30" to="terms-and-conditions#top">Terms & Conditions</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            }
           </>
        );
}

export default FrontFooter;