import React,{useState,useEffect} from "react";
import axios from "axios";
import SubscribeModule from './template_parts/SubscribeModule';
import PageTopHeaderSec from './template_parts/PageTopHeaderSec';

const TermsAndConditions  = () => {

   useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

   const [allPostData, setAllPostData] = useState([]);
   const [baseUrl, setBaseurl] = useState('');
   useEffect(() => { 
      (async () => {
         try {
               const getdata = await axios.get("https://jobchatbox.com/api/cms/pages/key/terms_and_conditions");
               setAllPostData(getdata.data.data.pages_data);
               setBaseurl(getdata.data.data.base_url);
         } catch (error) { console.log(error); }
      })();
   }, []);
    
    return (
        <>
      <PageTopHeaderSec pageTitle='Terms & Conditions' PageLink='terms-and-conditions'  />
      
         <>
         {/* <h4>Terms &amp; Conditions</h4> */}
         <div className="mb-20" dangerouslySetInnerHTML={{__html: allPostData.page_description}} ></div>

         {/* <section className="privacypolicysec">
            <div className="container">
               <div className="">
                  <h4>Terms &amp; Conditions</h4>
                  terms-of-service
                  <p>This platform intends to serve the audience by exchanging information for various services relating to careers, jobs, internships, and others. It is meant to help users, professionals, visitors, and members in the very sense of employment, providing opportunities. Hereinafter referred to as “we,” “us,” or “our platform.” </p>
                  <p>To maintain authenticity, our platform might ask you for birth proof or any other legal proof, as we only allow those with a bona fide intention to contact you and be contacted for purposes related to existing job vacancies and other career enhancement services. Our platform does not ask for any extra charge, advertising or otherwise. </p>
                  <p>Copying or downloading or recreating, sharing passwords, or sublicensing or transferring in any manner which does not follow these terms is a misuse of the platform or service. Hence, you can be legally charged for misconduct. </p>
                  <p>To assist the users of our platforms, we provide you with the truthful information and expect you to share the same. Also, to maintain the generosity please address your recruiter, clients, employees in  professional manner so that the decorum and the motive of the platform is fulfilled.</p>
               </div>
            </div>
         </section> */}
         </>

      <SubscribeModule />
        </>
    );
  };
export default TermsAndConditions;