import React, { useState, useEffect } from 'react';
import LeftSidebar from '../include/left_sidebar';
import PlacementSignuForm from '../../front/pages/forms_temp/PlacementSignuForm';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import { Link, useLocation } from 'react-router-dom';


const Addvertisement = () => {
    var storedNames = JSON.parse(localStorage.getItem("userlogin"));
    const [allAddvertisements, setAllAddvertisements] = useState([]);
    // const [allPlacementCities, setAllPlacementCities] = useState([]);
    const [allPlacementList, setAllPlacementList] = useState([]);

    const getALLPost = () => {
        (async () => {
            try {
                const getdata = await axios.get("https://jobchatbox.com/api/placement/placement_addvertisement/" + storedNames.login_token);
                if (getdata.data.status) {

                    setAllAddvertisements(getdata.data.data);
                    // setAllPlacementCities(getdata.data.data.cities);
                }
            } catch (error) { console.log(error); }
        })();
    }

    const [actionFormShow, setactionFormShow] = useState(false);
    const actionFormShowFun = () => {
        setactionFormShow(!actionFormShow)
    }
   
    useEffect(() => {
        getALLPost();
        (async () => {
            try {
                const getdata = await axios.get(`https://jobchatbox.com/api/placement/training_and_placements/${storedNames.login_token}`);
                // console.log(getdata.data.data)
                if (getdata.data.status) {

                    setAllPlacementList(getdata.data.data);
                }
            } catch (error) { console.log(error); }
        })();
    }, []);


    const validationSchema = Yup.object().shape({
        course: Yup.string().required('Select a course'),
        title: Yup.string().required('Title is required'),
        // image: Yup.mixed().required('Image is required'),
        url: Yup.string().url('Invalid URL').required('URL is required'),
    });
    const [submitBtnDisable, setsubmitBtnDisable] = useState(false);

    const [uploadImage, setUploadImage] = useState(null);
    const handleFileChange = (e) => {
        setUploadImage(e.target.files[0]);
    };

    const onSubmit = async (values) => {
        // Send the form data to your server using a POST request here.
        console.log(values);

        //e.target.files[0]
        // Create a FormData object to send the file data.
        const formData = new FormData();
        formData.append('course', values.course);
        formData.append('title', values.title);
        formData.append('image', uploadImage);
        formData.append('url', values.url);
        formData.append('login_token', storedNames.login_token);

        try {
            let res = await fetch("https://jobchatbox.com/api/placement/placement_addvertisement_action", {
                method: "POST",
                body: formData,
            });
            let resJson = await res.json();
            if (res.status === 200) {
                setsubmitBtnDisable(false)
                console.log(res)
                if (resJson.status == 1) {
                    toast.success(resJson.message);
                    setTimeout(function () { window.location.href = "/addvertisements"; }, 2000);
                } else {
                    toast.success(resJson.message)
                }
            }
        } catch (err) {
            console.log(err);
        }

    };



    /*status changes*/ 
    const handleStatusChange = (id,status) => {
        // Display a confirmation dialog
        const confirmed = window.confirm(`Are you sure you want to ${ status == 1 ? 'disable' : 'enable'} this item?`);
    
        if (confirmed) {
            (async () => {
                try {
                    const getdata = await axios.get(`https://jobchatbox.com/api/placement/placement_addvertisement_status_action/${storedNames.login_token}/${id}/${status}`);
                    //console.log(getdata.data.data)
                    if (getdata.data.status) {
                        toast.success(getdata.data.message)
                        setTimeout(function () { 
                            getALLPost()
                         }, 2000);
                        //setAllPlacementList(getdata.data.data);
                    }
                } catch (error) { console.log(error); }
            })();
        } else {
          // User canceled the delete action
          console.log('Delete canceled');
        }
    };


    /*status changes*/ 
    const handleDelete = (id) => {
        // Display a confirmation dialog
        const confirmed = window.confirm(`Are you sure you want to delete this row?`);
    
        if (confirmed) {
            (async () => {
                try {
                    const getdata = await axios.get(`https://jobchatbox.com/api/placement/placement_addvertisement_delete/${storedNames.login_token}/${id}`);
                    //console.log(getdata.data.data)
                    if (getdata.data.status) {
                        toast.success(getdata.data.message)
                        setTimeout(function () { 
                            getALLPost()
                         }, 2000);
                        //setAllPlacementList(getdata.data.data);
                    }
                } catch (error) { console.log(error); }
            })();
        } else {
          // User canceled the delete action
          console.log('Delete canceled');
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="dashboardsec pt-70 pb-70">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-3">
                            <LeftSidebar />
                        </div>

                        <div className="col-lg-9">
                            <div className="notificationwraper">
                                <div className="head-border mb-30 d-flex justify-content-between align-items-center">
                                    <h5>Addvertisement</h5>
                                    <Link   onClick={actionFormShowFun} className="btn btn-default btn-small mr-10 mb-5">
                                        
                                        {actionFormShow ? 'Back' : <><i className="fa fa-plus" aria-hidden="true"></i>Add new</>}
                                        
                                    </Link>
                                </div>

                                {
                                    !actionFormShow && <>
                                    {allAddvertisements.length ?
                                        <>
                                            {
                                                allAddvertisements.map((item, index) => (
                                                    <>
                                                        <div className="card-grid-2 hover-up">
                                                            <span className="flash"></span>
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="card-grid-2-image-left pb-5">
                                                                        <div className="image-box">
                                                                            <img className="profile_pic" src={item.image} alt="jcbapp" /></div>
                                                                        <div className="right-info">
                                                                            <Link className="name-job text-capitalize" >{item.title}</Link>
                                                                            <span >{item.created_date}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-block-info">
                                                                <div className="row">
                                                                    <div className="col-md-6 mb-5">
                                                                        <p className="font-sm color-text-paragraph"><strong>Course :</strong> {item.course}</p>
                                                                    </div>
                                                                    <div className="col-md-6 mb-5">
                                                                        <p className="font-sm color-text-paragraph"><strong>Status :</strong> {(item.status == 1) ? 'Enable' :'Disable'} </p>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="d-flex mt-20 myjobbtns flex-wrap work-experiencebtn">
                                                                        <Link  onClick={() =>handleStatusChange(item.id ,item.status)}  className="btn btn-default btn-small mr-10 mb-5" href="/my-placements"><i className="fa fa-wrench" aria-hidden="true"></i>Make It {(item.status == 1) ? 'Disable' :'Enable'} </Link>
                                                                        {/* <a className="btn btn-default btn-small mr-10 mb-5" href="/training-and-placement-details?id=10" target="_blank"><i className="fa fa-file-text-o" aria-hidden="true"></i>View Detail</a> */}
                                                                        <Link onClick={actionFormShowFun} className="btn btn-default btn-small mr-10 mb-5" ><i className="fa fa-pencil-square-o" aria-hidden="true"></i>Edit Job</Link>
                                                                        <Link onClick={() =>handleDelete(item.id)} className="btn btn-default btn-small mb-5" ><i className="fa fa-trash-o" aria-hidden="true"></i>Delete Job</Link></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))
                                            }
                                        </>
                                        :
                                        <>
                                        <div>
                                            <p>
                                                There are no  placement created by you for addvertisement
                                            </p>
                                        </div>
                                        </>}
                                    </>
                                }


                                { actionFormShow && <div className="grayborderbox mb-30">
                                    <div className="box-padding">
                                        <div className="row">
                                            <div className="col-lg-12">


                                                {
                                                    allPlacementList.length ?
                                                        <>
                                                            <Formik
                                                                initialValues={{ course: '', title: '', image: '', url: '' }}
                                                                validationSchema={validationSchema}
                                                                onSubmit={onSubmit}
                                                            >
                                                                <Form>
                                                                    <div className="form-group">
                                                                        <label htmlFor="course">Select Course *</label>
                                                                        <Field as="select" id="course" name="course" className="form-control">
                                                                            <option value=''>{'Select Category'}</option>
                                                                            {
                                                                                allPlacementList.map((item, index) => (
                                                                                    <>
                                                                                        <option value={item.id}>{item.title}</option>
                                                                                    </>
                                                                                ))
                                                                            }
                                                                        </Field>
                                                                        <ErrorMessage name="course" component="div" className="text-danger" />
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label htmlFor="title">Advertisement Title *</label>
                                                                        <Field type="text" id="title" name="title" className="form-control" />
                                                                        <ErrorMessage name="title" component="div" className="text-danger" />
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label htmlFor="image">Upload Advertisement Image *</label>
                                                                        <input onChange={handleFileChange} type="file" id="image" name="image" className="form-control" />
                                                                        <ErrorMessage name="image" component="div" className="text-danger" />
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label htmlFor="url">Advertisement URL *</label>
                                                                        <Field type="text" id="url" name="url" className="form-control" />
                                                                        <ErrorMessage name="url" component="div" className="text-danger" />
                                                                    </div>

                                                                    <button type="submit" className="btn btn-primary">
                                                                        Submit
                                                                    </button>
                                                                </Form>
                                                            </Formik>
                                                        </>
                                                        :
                                                        <>
                                                            <div>
                                                                <p>
                                                                    There are no  placement created by you for addvertisement
                                                                </p>
                                                            </div>
                                                        </>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div> }


                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};
export default Addvertisement;