import React , {useEffect } from 'react';
import { Nav, NavLink, NavMenu } from "../../front/components/NavbarElements";
import {useNavigate} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";




const tooglefilter = () => { 
    var toggleelem = document.querySelector('.sidebartgl');
    var toggleelemdiv = document.querySelector('.leftsidebarmain');
    if(toggleelemdiv.classList.contains('opensidebarleft')){
     toggleelemdiv.classList.remove("opensidebarleft");
    }else{
    toggleelemdiv.classList.add("opensidebarleft");
    }
  }

class LeftSidebar extends React.Component {

    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            fields: {} ,
            isScrollClassAdd : false ,
            userdata: JSON.parse(localStorage.getItem("userloginSession")) ,
            errors : {}
        };
    }


    componentDidMount() {
        var storedNames = JSON.parse(localStorage.getItem("userlogin"));
        this.setState({ userdata : JSON.parse(localStorage.getItem("userloginSession") ) });
        (async () => {
            try {
                const resData = await axios.get("https://jobchatbox.com/api/user/api/leftsidebar/"+storedNames.login_token);
                if(resData.data.status == 1){
                    console.log(resData.data);
                    //ttlAppliedJobs ttlFavoriteJobs
                    document.getElementById("ttlConnection").innerHTML = resData.data.data.total_connection;
                    document.getElementById("ttlGroups").innerHTML = resData.data.data.total_connected_group;
                    document.getElementById("ttlNotification").innerHTML = resData.data.data.total_notification;
                    document.getElementById("ttlAppliedJobs").innerHTML = resData.data.data.total_applied;
                    document.getElementById("ttlFavoriteJobs").innerHTML = resData.data.data.total_favorite;
                }
            } catch (error) { console.log(error); }
        })();
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
       
        const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
        this.setState({ isScrollClassAdd: false });
        
        // if(scrollTop  >  480){
        //     this.setState({ isScrollClassAdd: true });
        // }
        
    };
    logout = () => {
        localStorage.clear();
        this.notify('success','Logout successfully.');
    };
    
    notify = (status_type,message) => {
      switch (status_type) {
          case 'warning':
              toast.warning(message)  
          break;
    
          case 'success':
              toast.success(message)  
          break;
    
          case 'info':
              toast.info(message)  
          break;
    
          case 'error':
              toast.error(message)  
          break;
      }
    };
    
    render() {

        //var storedNames = JSON.parse(localStorage.getItem("userloginSession"));
        var storedNames = this.state.userdata;
        //https://jobchatbox.com/api/uploads/users/
        let profile_pic =  (this.state.userdata.profile_pic) ? `https://jobchatbox.com/api/uploads/users/${this.state.userdata.profile_pic}` : 'front/assets/imgs/default_image.png';
       
        var  key_skills = [];
        if(storedNames.key_skills){
            try {
                key_skills = JSON.parse(storedNames.key_skills);
            } catch(e) {
            }
        }

        return(
            <>
                

                <div id="showScroll"></div>
                <div className="mobile_sedebarfilter d-flex align-items-center">
                    <div className="mside_left">
                        <figure>
                        <img alt="jcbapp" src={profile_pic} />
                        </figure>
                        <div className="msileleftinner">
                            <h6 className="text-capitalize">{storedNames.firstname}</h6>
                            <div className="font-xs ">{(storedNames.usertype == 6) ? storedNames.company_name : '' }</div>
                        </div>
                    </div>
                    <div className="mside_right ml-auto">
                        <span className="sidebartgl" onClick={tooglefilter}><i className="fa fa-gear"></i></span>
                    </div>
                </div>

                {/* <div className="leftsidebarmain"> */}
                    <div className="card-grid-2 profiletop">
                        <div className="text-center p-20 primary-bg">
                            <div className="card-grid-2-image-rd online">
                                <NavLink to={'/profile-edit'}>
                                    <figure><img id="sidebar_profile_pic_url" alt="jcbapp" src={profile_pic} /></figure>
                                </NavLink>
                            </div>
                            <div className="card-profile pt-10">
                                <h6 className="clr-white text-capitalize">{storedNames.firstname}</h6>
                                <div className="mb-10 font-xs clr-gray">{(storedNames.usertype == 6) ? storedNames.company_name : '' }</div>
                                <div className="font-xs clr-gray text-left mt-5">
                                    <i className="fa fa-envelope-o" aria-hidden="true"></i>{storedNames.email}
                                </div>

                            {
                                storedNames.usertype != 7 ?
                                <>
                                    <div className="font-xs clr-gray text-left mt-5"><i className="fa fa-user-o" aria-hidden="true"></i> Male</div>
                                    <div className="font-xs clr-gray text-left mt-5"><i className="fa fa-map-marker" aria-hidden="true"></i> {storedNames.user_city_name} {storedNames.user_state_name} </div>
                                    <div className="font-xs clr-gray text-left mt-10">
                                        {
                                            (storedNames.usertype == 3) ? 
                                            <><strong>Joining Status :</strong> : {storedNames.join_status} </>
                                            : 
                                            <> <strong>Designation :</strong> : {storedNames.designation} </>
                                        }
                                        
                                    </div>
                                    <div className="font-xs clr-gray text-left mt-10">
                                        <strong>Last Updated :</strong> { storedNames.lastUpdateDate ? storedNames.lastUpdateDate : storedNames.regDate }
                                    </div>
                                    {
                                        <div className="d-flex justify-content-center dashboardskill mt-20">
                                            { (key_skills) ?  <>{ key_skills.map((item,index) => (<h6 className="roundtab">{item}</h6>)) } </>  : '' }
                                        </div>
                                    }
                                </>
                                :
                                <></>
                            }
                                


                            </div>
                        </div>
                    </div>
                    <div className={this.state.isScrollClassAdd == true ? 'card-grid-2 profilemtnu scroll_left_class sticky' : 'card-grid-2 profilemtnu not_scroll sticky'} >

                        <div className="p-20">
                            <ul className="dashboardmenusec">

                            {
                                (storedNames.usertype == 6) ? 
                                <>
                                <li key={1} ><NavLink  to="/dashboard" ><i className="fa fa-tachometer" aria-hidden="true"></i>Dashboard</NavLink></li>
                                <li key={101} ><NavLink  to={`/mytimeline?candidate_id=${storedNames.unique_id}`} ><i className="fa fa-tachometer" aria-hidden="true"></i>My Timeline</NavLink></li>
                                <li key={2}><NavLink  to="/profile-edit" ><i className="fa fa-user" aria-hidden="true"></i>Edit Profile</NavLink></li>
                                {/* <li key={3}><NavLink  to="/sectors"  ><i className="fa fa-commenting-o" aria-hidden="true"></i>Sectors/Skills<span className="menucount" id="ttlSectorsSkills" >0</span></NavLink></li> */}
                                <li key={4}><NavLink  to="/work-experience"  ><i className="fa fa-user" aria-hidden="true"></i>My Work Experience<span className="menucount" id="ttlWorkexperience" >0</span></NavLink></li>
                                <li key={5}><NavLink  to="/connections"  ><i className="fa fa-commenting-o" aria-hidden="true"></i>Connections<span className="menucount" id="ttlConnection">0</span></NavLink></li>
                                <li key={6}><NavLink  to="/groups"  ><i className="fa fa-users" aria-hidden="true"></i>Groups  <span className="menucount"  id="ttlGroups">0</span></NavLink></li>
                                <li className='display_none' key={7}><NavLink  to="/create-recruiter"  ><i className="fa fa-user-plus" aria-hidden="true"></i>Create Recruiter</NavLink></li>

                                <li key={8}><NavLink  to="/my-job-post"  ><i className="fa fa-pencil-square-o" aria-hidden="true"></i>My Job Postings</NavLink></li>
                                <li key={9}><NavLink  to="/appliedjobs"  ><i className="fa fa-check-square-o" aria-hidden="true"></i>Applied Jobs</NavLink></li>
                                
                                <li key={10}><NavLink  to="/resume-list"  ><i className="fa fa-file-word-o" aria-hidden="true"></i>Resumes</NavLink></li>
                                <li key={11}><NavLink  to="/resume-folder"  ><i className="fa fa-folder-o" aria-hidden="true"></i>Resume Folders</NavLink></li>
                                <li className='display_none' key={12}><NavLink  to="/selected-resumes"  ><i className="fa fa-file-word-o" aria-hidden="true"></i>Selected Resumes</NavLink></li>
                                <li className='display_none' key={13}><NavLink  to="/email-templates"  ><i className="fa fa-envelope-o" aria-hidden="true"></i>Email Templates</NavLink></li>
                                <li className='display_none' key={14}><NavLink  to="/downloaded-resumes"  ><i className="fa fa-file-word-o" aria-hidden="true"></i>Downloaded Resumes</NavLink></li>
                                
                                
                                <li key={15}><NavLink onClick={this.logout}  to="/login" ><i className="fa fa-sign-out" aria-hidden="true"></i>Logout</NavLink></li>
                                </>

                                : (storedNames.usertype == 7) ?
                                <>
                                    <li key={30}><NavLink  to="/dashboard" ><i className="fa fa-tachometer" aria-hidden="true"></i>Dashboard</NavLink></li> 
                                    <li key={31} ><NavLink  to={`/mytimeline?candidate_id=${storedNames.unique_id}`} ><i className="fa fa-tachometer" aria-hidden="true"></i>My Timeline</NavLink></li>
                                    <li key={32}><NavLink  to="/my-placements" ><i className="fa fa-user" aria-hidden="true"></i>Placement</NavLink></li>
                                    <li key={33}><NavLink  to="/add_new_placement" ><i className="fa fa-plus" aria-hidden="true"></i>Create Your Placement</NavLink></li>
                                    <li key={34}><NavLink  to="/addvertisements" ><i className="fa fa-user" aria-hidden="true"></i>Addvertisement</NavLink></li>
                                    <li key={35}><NavLink  to="/myprofile-edit" ><i className="fa fa-user" aria-hidden="true"></i>Edit Profile</NavLink></li>
                                    <li key={36}><NavLink onClick={this.logout}  to="/login" ><i className="fa fa-sign-out" aria-hidden="true"></i>Logout</NavLink></li>
                                </>
                                : (storedNames.usertype == 3) ?
                                <>
                                <li key={16}><NavLink  to="/dashboard" ><i className="fa fa-tachometer" aria-hidden="true"></i>Dashboard</NavLink></li> 
                                    <li key={101} ><NavLink  to={`/mytimeline?candidate_id=${storedNames.unique_id}`} ><i className="fa fa-tachometer" aria-hidden="true"></i>My Timeline</NavLink></li>
                                    <li key={17}><NavLink  to="/profile-edit" ><i className="fa fa-user" aria-hidden="true"></i>Edit Profile</NavLink></li>
                                    <li key={18}><NavLink  to="/profile-performance" ><i className="fa fa-user" aria-hidden="true"></i>Profile Performance</NavLink></li>
                                    <li key={19}><NavLink  to="/connections"  ><i className="fa fa-commenting-o" aria-hidden="true"></i>Connections<span className="menucount" id="ttlConnection">0</span></NavLink></li>
                                    <li key={20}><NavLink  to="/groups"  ><i className="fa fa-users" aria-hidden="true"></i>Groups <span className="menucount"  id="ttlGroups">0</span></NavLink></li>
                                    <li key={22}><NavLink  to="/applied-jobs"  ><i className="fa fa-paper-plane-o" aria-hidden="true"></i>Applied Jobs<span className="menucount" id="ttlAppliedJobs">0</span></NavLink></li>
                                    <li key={23}><NavLink  to="/favorite-jobs"  ><i className="fa fa-briefcase" aria-hidden="true"></i>Favorite Jobs<span className="menucount" id="ttlFavoriteJobs">0</span></NavLink></li>
                                    <li key={21}><NavLink  to="/resume"  ><i className="fa fa-cogs" aria-hidden="true"></i>Profile Settings</NavLink></li>
                                    <li key={24}><NavLink onClick={this.logout}  to="/login" ><i className="fa fa-sign-out" aria-hidden="true"></i>Logout</NavLink></li>
                                </>
                                :
                                <></>
                            }
                            
                            
                            </ul>
                        </div>
                    </div>
                {/* </div> */}
            </>
        )
    }
}
export default LeftSidebar;

