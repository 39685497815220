import React,{useState,useEffect} from "react";
import axios from "axios";

const HomeServices = () => {
    const [allPostList, setAllPostList] = useState([]);
    const [baseUrl, setBaseurl] = useState('');
    useEffect(() => {
        (async () => {
            try {
                const getdata = await axios.get("https://jobchatbox.com/api/cms/what_we_do");
                setAllPostList(getdata.data.data.blog_data);
                setBaseurl(getdata.data.data.base_url);
            } catch (error) { console.log(error); }
        })();
    }, []);

    return(
        <section className="section-box overflow-visible pt-100 pb-100 homeservice">
            <div className="container">
            <div className="row">
                <div className="offset-md-1 col-md-10 text-center">
                    <h6 className="headtop clr-primary mb-10">WHAT WE DO?</h6>
                    <h2 className="section-title mb-40">Connect, Share, Grow: Boomerang your Network!</h2>             
                </div>
            </div> 
            <div className="row">
                {
                    allPostList.length > 0 ?
                    <>
                    {
                       allPostList.map((itmeData,index) => (
                         <>
                           {
                            <>
                            <div className="col-lg-3 col-md-6 col-12 mt-30">
                                <div className="whatwebox text-center">
                                    <img src={baseUrl+'/'+itmeData.blog_image_url} />
                                    <h5 className="mb-10">{itmeData.blog_name}</h5>
                                    <p className="mb-20" dangerouslySetInnerHTML={{__html: itmeData.blog_shortdescription}} ></p>
                                    {/* <a href="#" className="customlink">Learn More <i className="fa fa-arrow-right" aria-hidden="true"></i></a> */}
                                </div>
                            </div>
                            </>
                           }
                         </>
                       ))
                    }
                    </>
                    :''
                }
            </div>
            </div>
        </section>
        );
}

export default HomeServices;