import React , {useState ,useEffect} from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

const Resume_folder_list = () => {

  const [resumeList, setResumeList] = useState([]); 
  const [resumeListId, setResumeListId] = useState([]); 
  var storedNames = JSON.parse(localStorage.getItem("userlogin"));
    useEffect(() => {
      window.scrollTo(0, 0);
        const queryParameters = new URLSearchParams(window.location.search)
        const itemid = queryParameters.get("itemid");
        const setResumeListId = queryParameters.get("id");
        var storedNames = JSON.parse(localStorage.getItem("userlogin"));
        (async () => {
            try {
              const responceData = await axios.get("https://jobchatbox.com/api/user/api/get_resumelist_by_folderid/"+storedNames.login_token+"/"+itemid);
                if(responceData.data.status == 1){
                  //console.log(responceData.data.data)
                  setResumeList(responceData.data.data.resumelist)
                }
              } catch (error) {
                console.log(error);
              }
        })();

    }, [])

    //deleteResume
    const deleteResume  = (itemID) => {
      if (window.confirm('Do you want to remove folder?') == true) {
        (async () => {
           try {
              const responceData = await axios.get("https://jobchatbox.com/api/user/api/remove_folder_from_resume/"+storedNames.login_token+'/'+itemID);
              if(responceData.data.status == 1){
                 toast.success(responceData.data.message);
                 setResumeList(responceData.data.data.resumelist);
              } else {
                 toast.warning(responceData.data.message);
              }
           } catch (error) {
           console.log(error);
           }
        })();
     }
    }
    return (
      <> 
       
       <ToastContainer />
        <div className="dashboardsec pt-30 pb-30">
           <div className="container">
            
            <div className="row">
              <div className="col-lg-3">
               <LeftSidebar />
              </div>
              <div className="col-lg-9">
              <div className="section-box jobpost-sec">
                     <div className="container">
                        <div className="head-border mb-30 d-flex justify-content-between align-items-center">
                           <h5>Resumes</h5> 
                           
                           <Link to="/resume-folder"  className="btn btn-default btn-small ml-auto"><i className="fa fa-arrow-left" aria-hidden="true"></i> Back to resume</Link><a href="#" data-bs-toggle="modal" data-bs-target="#createfolder" className="btn btn-default btn-small ml-10"><i className="fa fa-plus" aria-hidden="true"></i> Create Folder </a>
                        </div>
                        <div className="row">
                            {
                              resumeList.length ? 
                              resumeList.map((item,index) => (
                                <>
                                  <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12">
                                    <div className="card-grid-1 py-4 px-2">
                                      <div className="image-box mb-5">
                                      
                                        <img src={(item.document_ext == 'pdf' ? 'front/assets/imgs/d_pdf.png' : 'front/assets/imgs/word.png') } alt="jcbapp" />
                                      </div>
                                      <h6>
                                        <a  href={item.candidate_details.user_resume_url ? item.candidate_details.user_resume_url : '#'} download target="_blank"  >
                                        {item.resume_doc}
                                        </a>
                                      </h6>
                                      <Link className="btn btn-default btn-small mt-10"  onClick={ () => deleteResume(item.id)}  ><i className="fa fa-trash-o" aria-hidden="true"></i> Delete</Link>
                                    </div>
                                  </div> 
                                </>
                             ))  : <><p className='pl-10'>Resume list not Found for this folder.</p></>
                            }
                            


                        </div>
                     </div>
                  </div>
                </div>


            </div>
          </div>
        </div>
    
      </>
    );
  };
export default Resume_folder_list;