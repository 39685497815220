import React,{useState,useEffect} from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';
import { Link } from "react-router-dom";

import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const My_work_experience = () => {
    useEffect(() => {  window.scrollTo(0, 0) }, [])

    var storedNames = JSON.parse(localStorage.getItem("userlogin"));

    const [sectorAndSkills, setSectorAndSkills] = useState([]);
    const [sectorAndSkillsLoad, setSectorAndSkillsLoad] = useState(0);

    useEffect(() => {
        (async () => {
            try {
                const responceData = await axios.get("https://jobchatbox.com/api/user/api/sector_and_skills/"+storedNames.login_token+'/workexperience');
                if(responceData.data.status == 1){
                    setSectorAndSkillsLoad(1);
                    setSectorAndSkills(responceData.data.data.sectors)
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    //statusChangeSector editSector deleteSector

    const editSector = (id) => {
        (async () => {
            try {
              const responceData = await axios.get("https://jobchatbox.com/api/user/api/sector_status_change/"+storedNames.login_token+'/'+id);
              if(responceData.data.status == 1){
                setSectorAndSkillsLoad(1);
                setSectorAndSkills(responceData.data.data.sectors)
              }
            } catch (error) {
              console.log(error);
            }
        })();
    }

    const deleteSector = (id) => {
        if (window.confirm('Do you want to remove sector?') == true) {
            (async () => {
                try {
                const responceData = await axios.get("https://jobchatbox.com/api/user/api/delete_sector_and_skills/"+storedNames.login_token+'/'+id);
                if(responceData.data.status == 1){
                    toast.success(responceData.data.message);
                    setSectorAndSkillsLoad(1);
                    setSectorAndSkills(responceData.data.data.sectors)
                }
                } catch (error) {
                console.log(error);
                }
            })();
        }
    }

    const statusChangeSector = (id) => {
        if (window.confirm('Do you want to change status ?') == true) {
            (async () => {
                try {
                const responceData = await axios.get("https://jobchatbox.com/api/user/api/sector_status_change/"+storedNames.login_token+'/'+id);
                if(responceData.data.status == 1){
                   // alert(responceData.data.message)
                    toast.success(responceData.data.message);
                    setSectorAndSkillsLoad(1);
                    setSectorAndSkills(responceData.data.data.sectors)
                }
                } catch (error) {
                console.log(error);
                }
            })();
        }
    }

    return (
      <>
          <div className="dashboardsec pt-30 pb-30">
           <div className="container">
            
            <div className="row">
              <div className="col-lg-3">
               <LeftSidebar />
              </div>
              <div className="col-lg-9">
                <div className="section-box jobpost-sec">
                    
                        <div className="head-border mb-30 d-flex justify-content-between align-items-center">
                            <h5>My Work Experience</h5>
                            <Link to="/add-work-experience" className="btn btn-default btn-small ml-auto">+ Add Content</Link>
                        </div>
                      
                        <div className="">
                            {
                                (sectorAndSkills.length > 0) ?
                                <>
                                {
                                    sectorAndSkills.map((item,index) => (
                                        <>
                                        <div className="grayborderbox mb-20 box-padding">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 mb-10">
                                                        <h6>{item.main_title}</h6>
                                                        <p>{item.short_desc}</p>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12">
                                                        <div className="d-flex flex-wrap work-experiencebtn"> 
                                                            <button onClick={ (event) => statusChangeSector(item.id) } className="btn btn-default btn-small mr-10 mb-5"><i class="fa fa-wrench" aria-hidden="true"></i>Make It {item.status == 1 ? 'Disable' : 'Enable' }</button>
                                                            <button onClick={ (event) => editSector(item.id) } className="display_none btn btn-default btn-small mr-10 mb-5"><i className="fa fa-pencil-square-o" aria-hidden="true"></i>Edit Sector</button>
                                                            <Link to={'/add-work-experience?id='+item.id} className="btn btn-default btn-small mr-10 mb-5"><i className="fa fa-pencil-square-o" aria-hidden="true"></i>Edit Sector</Link>
                                                            <button onClick={ (event) => deleteSector(item.id) } className="btn btn-default btn-small mb-5"><i className="fa fa-trash-o" aria-hidden="true"></i>Delete Sector</button>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                    ))
                                }
                                
                                </> : 'Data List Not Found'
                            }
                            
                            
                        </div>
                    
                </div>
                </div>
 
            </div>
          </div>
        </div>
      </>
    );
  };
export default My_work_experience;