import React, { useState, useEffect } from 'react';
import LeftSidebar from '../include/left_sidebar';
import PlacementSignuForm from '../../front/pages/forms_temp/PlacementSignuForm';
import { Form, Button, Row, Col } from 'react-bootstrap';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useLocation } from 'react-router-dom';
import axios from "axios";

/*textara*/
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Add_new_placement = () => {

    const location = useLocation();
     const searchParams = new URLSearchParams(location.search);
     const id = searchParams.get('id');

    // const queryParams = new URLSearchParams(window.location.search);
    // const id = queryParams.get('id');


    const default_placement_content = `<div class="placement-assistance">
                                        <p>Placement Assistance is an integral part of our commitment to our students' success. We offer a comprehensive placement program to help you launch your career in your chosen field. Our placement services include:</p>
                                        <ul>
                                        <li>Live Project Training: Gain real-world experience by working on live projects during your training.</li>
                                        <li>Resume Building: Our experts will help you create a professional resume that highlights your skills and achievements.</li>
                                        <li>Interview Preparation: Get ready for interviews with our mock interview sessions and tips from industry professionals.</li>
                                        <li>Job Alerts: Stay updated with the latest job openings in your field through our job alert service.</li>
                                        <li>Career Guidance: Our experienced counselors will provide you with guidance and advice to help you make informed career decisions.</li>
                                        <li>100% Placement Assurance: We are committed to helping you secure a job, and we offer 100% placement assistance to achieve that goal.</li>
                                        </ul>
                                        <p>With our placement assistance, you can be confident in your job search and career prospects. Your success is our priority, and we are here to support you every step of the way.</p>
                                        </div>`;

    

    // const you_will_learn_default = `<div class="you-will-learn">
    //                                 <p>OOPS concepts (Data Abstraction, Encapsulation, Inheritance, Polymorphism)</p>
    //                                 <p>Basic Java constructs like loops and data types.</p>
    //                                 <p>String handling.</p>
    //                                 <p>Collection framework.</p>
    //                                 <p>Multithreading.</p>
    //                                 <p>Exception handling.</p>
    //                                 <p>Generics.</p>
    //                                 <p>Synchronization.</p>
    //                                 </div>`;

    // const whyThisCourse_default = `<div class="you-will-learn">
    //                                 <ul>
    //                                 <li class="chk-container">High success rate</li>
    //                                 <li class="chk-container">Get Admission into Top Universities</li>
    //                                 <li class="chk-container">Student Bank Loan Assistance</li>
    //                                 <li class="chk-container">Accommodation Assistance</li>
    //                                 <li class="chk-container">Part Time Job Assistance.</li>
    //                                 <li class="chk-container">We can guide on Travel Insurance</li>
    //                                 <li class="chk-container">Airport Pick up</li>
    //                                 <li class="chk-container">Air Ticketing for better price</li>
    //                                 <li class="chk-container">Foreign Exchange / Travelers Cheques</li>
    //                                 <li class="chk-container">Mock Visa Interviews / GRE / IELTS Coaching Support.</li>
    //                                 <li class="chk-container">Quick processing </li>
    //                                 <li class="chk-container">A-Z List of Items and things to Carry</li>
    //                                 <li class="chk-container">Graduation: Parents Visa Processing - FREE</li>
    //                                 </ul>
    //                                 </div>`;


    // const default_placement_content = '';
    const you_will_learn_default = '';
    const whyThisCourse_default = '';

    const [formData, setFormData] = useState({
        title: '',
        category: '',
        logo: null,
        originalPrice: '',
        finalPrice: '',
        coursesIncluded: [],
        location: '',
        no_off_visa_approved: '',
        membersPlaced: '',
        brochure1: null,
        brochure2: null,
        brochure3: null,
        placementAssistance: default_placement_content,
        youWillLearn: you_will_learn_default,
        upcomingDemoDetails: '',
        whyThisCourse: whyThisCourse_default,
        courseDescription: '',
    });

    const [editStatus, setEditStatus] = useState(false);
    useEffect(() => {
            if(id){
            
                    (async () => {
                        try {
                            const getdata2 = await axios.get("https://jobchatbox.com/api/placement/get_training_and_placements/" + id);
                            if (getdata2.data.status) {
                                let getdata = (getdata2.data.data);
                                setEditStatus(getdata.id);
                                console.log(getdata);
                                setFormData({
                                    title: getdata.title,
                                    category: getdata.category,
                                    logo: getdata.logo,
                                    originalPrice: getdata.original_price,
                                    finalPrice: getdata.final_price,
                                    coursesIncluded: getdata.courses_included,
                                    location: getdata.location,
                                    membersPlaced: getdata.members_placed,
                                    brochure1: getdata.brochure1,
                                    brochure2: getdata.brochure2,
                                    brochure3: getdata.brochure3,
                                    placementAssistance: getdata.placement_assistance,
                                    no_off_visa_approved: getdata.no_off_visa_approved,
                                    youWillLearn: getdata.you_will_learn,
                                    upcomingDemoDetails: getdata.upcoming_demo_details,
                                    whyThisCourse: getdata.upcoming_demo_details,
                                    courseDescription: getdata.course_description,
                                });
                            }
                        } catch (error) { console.log(error); }
                    })();
                
        }  
    }, []);

    const [errors, setErrors] = useState({});


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            logo: file, // Update the logo field with the selected file
        }));
    };

    const formlabelscons  = {
        course_included : 'Courses Included',
        placement_assisment : 'Placement Assistance',
        course_included_bottom : 'Separate courses with commas (e.g., Course 1, Course 2)',
        you_will_learn : 'You Will Learn',
        upcoming_demo_details : 'Upcoming Demo Details',
        why_this_course : 'Why This Course',
        course_description : 'Course Description',
    } ;
    const [formLabels, setformLabels] = useState(formlabelscons);

    
    const handleInputChange = (e) => {
        const { name, value, type, files } = e.target;
        const inputValue = type === 'file' ? files[0] : value;
        if (name === 'category') {
           
            setformLabels(formlabelscons);
            
            if(value == 3){
                setformLabels({
                    course_included : 'We train on other tests',
                    placement_assisment : 'Services',
                    course_included_bottom : 'Separate courses with commas (e.g., Course 1, Course 2)',
                    you_will_learn : 'You Will Learn',
                    upcoming_demo_details : 'Upcoming Demo Details',
                    why_this_course : 'Why This Course',
                    course_description : 'Course Description',
                });
            }
            
            if(value == 2){
                setformLabels({
                    course_included : 'Other country visas',
                    placement_assisment : 'Top Universities',
                    course_included_bottom : 'Separate country with commas (e.g., UK , US , CANADA )',
                    you_will_learn : 'Why You Choose Us',
                    upcoming_demo_details : 'Upcoming Intakes',
                    why_this_course : 'Upcoming Intakes',
                    course_description : 'About Us',
                });

                handleRichTextChange(whyThisCourse_default , 'you_will_learn' );
            }
        }
        
        //3

        if (name === 'coursesIncluded') {
            const coursesArray = value.split(',');
            setFormData({ ...formData, [name]: coursesArray });
        } else {
            setFormData({ ...formData, [name]: inputValue });
        }
    };


    


    //const [isDisableBtn, setIsDisableBtn] = useState(false);
    const [validated, setValidated] = useState(false);
    const [submitBtnDisable, setsubmitBtnDisable] = useState(false);
    var storedNames = JSON.parse(localStorage.getItem("userlogin"));
    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setsubmitBtnDisable(true);
            event.preventDefault();
            const formData_send = new FormData();
            formData_send.append('title', formData.title);
            formData_send.append('category', formData.category);
            formData_send.append('logo', formData.logo);
            formData_send.append('original_price', formData.originalPrice);
            formData_send.append('final_price', formData.finalPrice);
            formData_send.append('courses_included', formData.coursesIncluded);
            formData_send.append('location', formData.location);
            formData_send.append('members_placed', formData.membersPlaced);
            formData_send.append('brochure1', formData.brochure1);
            formData_send.append('brochure2', formData.brochure2);
            formData_send.append('brochure3', formData.brochure3);
            formData_send.append('placement_assistance', formData.placementAssistance);
            formData_send.append('no_off_visa_approved', formData.no_off_visa_approved);
            formData_send.append('you_will_learn', formData.youWillLearn);
            formData_send.append('upcoming_demo_details', formData.upcomingDemoDetails);
            formData_send.append('why_this_course', formData.whyThisCourse);
            formData_send.append('course_description', formData.courseDescription);
            formData_send.append('login_token', storedNames.login_token);
            if (editStatus) {
                formData_send.append('edit_id', editStatus);
            }

            try {
                let res = await fetch("https://jobchatbox.com/api/placement/placement_add_edit_action", {
                    method: "POST",
                    body: formData_send,
                });
                let resJson = await res.json();
                if (res.status === 200) {
                    setsubmitBtnDisable(false)
                    console.log(res)
                    if (resJson.status == 1) {
                        toast.success(resJson.message);
                        setTimeout(function () { window.location.href = "/my-placements"; }, 2000);
                    } else {
                        toast.success(resJson.message)
                    }
                }
            } catch (err) {
                console.log(err);
            }
        }
        setValidated(true);
    };

    const handleRichTextChange = (value, field) => {
        setFormData({ ...formData, [field]: value });
        console.log(field);
        console.log(value);
        console.log(formData);
    };


    /*-----tags-------*/



    const [allPlacementCategory, setAllPlacementCategory] = useState([]);
    const [allPlacementCities, setAllPlacementCities] = useState([]);
    useEffect(() => {
        (async () => {
            try {
                const getdata = await axios.get("https://jobchatbox.com/api/placement/categories_and_cities");
                if (getdata.data.status) {

                    setAllPlacementCategory(getdata.data.data.categories);
                    setAllPlacementCities(getdata.data.data.cities);
                }
            } catch (error) { console.log(error); }
        })();
    }, []);



    const [selectedValue, setSelectedValue] = useState('option1'); // Initialize the selected value
    const [editorContent, setEditorContent] = useState(''); // Initialize the content for the editor
  
    // Function to handle dropdown change
    const handleSelectChange = (event) => {
      const newValue = event.target.value;
  
      // Update the selected value
      setSelectedValue(newValue);
  
      // Update the content based on the selected value
      if (newValue === 'option1') {
        setEditorContent('Content for Option 1');
      } else if (newValue === 'option2') {
        setEditorContent('Content for Option 2');
      } // Add more conditions as needed
    };

    return (
        <>
            <ToastContainer />
            <div className="dashboardsec pt-70 pb-70">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-3">
                            <LeftSidebar />
                        </div>

                        <div className="col-lg-9">
                            <div className="notificationwraper">
                                <div class="head-border mb-30 d-flex justify-content-between align-items-center">
                                    <h5>Action Placements</h5>

                                    <Link class="btn btn-default btn-small mr-10 mb-5" to={"/my-placements"}>
                                        Back
                                    </Link>
                                </div>
                                <div class="grayborderbox mb-30">
                                    <div class="box-padding">
                                        <div class="row">
                                            {/* ----------- */}
                                                    {/* <div>
                                                    <select value={selectedValue} onChange={handleSelectChange}>
                                                    <option value="option1">Option 1</option>
                                                    <option value="option2">Option 2</option>
                                                    </select>

                                                    <ReactQuill
                                                    value={editorContent}
                                                    onChange={(content) => setEditorContent(content)}
                                                    />
                                                    </div> */}
                                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group controlId="category">
                                                            <Form.Label>Category *</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                name="category"
                                                                value={formData.category}
                                                                onChange={handleInputChange}
                                                                required={true}
                                                            >
                                                                <option value=''>{'Select Category'}</option>
                                                                {
                                                                    allPlacementCategory.map((item, index) => (
                                                                        <>
                                                                            <option value={item.id}>{item.category_name}</option>
                                                                        </>
                                                                    ))
                                                                }


                                                                {/* Add other category options */}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md={6}>
                                                        <Form.Group controlId="title">
                                                            <Form.Label>Title *</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="title"
                                                                value={formData.title}
                                                                onChange={handleInputChange}
                                                                isInvalid={errors.title}
                                                                required={true}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.title}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md={6}>
                                                        <Form.Group controlId="originalPrice">
                                                            <Form.Label>Original Price *</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="originalPrice"
                                                                value={formData.originalPrice}
                                                                onChange={handleInputChange}
                                                                required={true}
                                                            />
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md={6}>
                                                        <Form.Group controlId="finalPrice">
                                                            <Form.Label>Final Price (After Discount) *</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="finalPrice"
                                                                value={formData.finalPrice}
                                                                onChange={handleInputChange}
                                                                required={true}
                                                            />
                                                        </Form.Group>
                                                    </Col>



                                                    <Col md={12}>
                                                        <Form.Group controlId="coursesIncluded">
                                                            <Form.Label>{formLabels.course_included} *</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="coursesIncluded"
                                                                value={formData.coursesIncluded}
                                                                onChange={handleInputChange}
                                                                required={true}
                                                            />
                                                            <Form.Text className="text-muted">
                                                                {formLabels.course_included_bottom}
                                                                {/* Separate courses with commas (e.g., Course 1, Course 2) */}
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </Col>

                                                    {
                                                        (formData.category ==  2) &&
                                                        <>
                                                        <Col md={12}>
                                                            <Form.Group controlId="no_off_visa_approved">
                                                                <Form.Label>No of visas approved</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    name="no_off_visa_approved"
                                                                    value={formData.no_off_visa_approved}
                                                                    onChange={handleInputChange}
                                                                />
                                                               
                                                            </Form.Group>
                                                        </Col>
                                                        </>
                                                    }
                                                </Row>

                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group controlId="location">
                                                            <Form.Label>Location *</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                name="location"
                                                                value={formData.location}
                                                                onChange={handleInputChange}
                                                                required={true}
                                                            >
                                                                <option value=''>{'Select Location'}</option>
                                                                {
                                                                    allPlacementCities.map((item, index) => (
                                                                        <>
                                                                        <option value={item.city_name}>{item.city_name}</option>
                                                                        </>
                                                                    ))
                                                                }
                                                                {/* Add other location options */}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md={6}>
                                                        <Form.Group controlId="membersPlaced">
                                                            <Form.Label>Members Placed *</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="membersPlaced"
                                                                value={formData.membersPlaced}
                                                                onChange={handleInputChange}
                                                                required={true}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group controlId="logo">
                                                            <Form.Label>Logo *</Form.Label>
                                                            <Form.Control
                                                                type="file"
                                                                name="logo"
                                                                onChange={handleFileChange}
                                                                required={formData.logo ? false : true}
                                                            />
                                                        </Form.Group>
                                                        {
                                                            formData.logo &&
                                                            <img className="form_images" src={formData.logo}></img>
                                                        }
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group controlId="brochure1">
                                                            <Form.Label>Brochure 1</Form.Label>
                                                            <Form.Control
                                                                type="file"
                                                                name="brochure1"
                                                                onChange={handleInputChange}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group controlId="brochure2">
                                                            <Form.Label>Brochure 2</Form.Label>
                                                            <Form.Control
                                                                type="file"
                                                                name="brochure2"
                                                                onChange={handleInputChange}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group controlId="brochure3">
                                                            <Form.Label>Brochure 3</Form.Label>
                                                            <Form.Control
                                                                type="file"
                                                                name="brochure3"
                                                                onChange={handleInputChange}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>

                                                    
                                                    <Col md={12}>
                                                        <Form.Group controlId="placementAssistance">
                                                            <Form.Label>{formLabels.placement_assisment} *</Form.Label>
                                                            <ReactQuill
                                                                required={true}
                                                                value={formData.placementAssistance}
                                                                onChange={(value) => handleRichTextChange(value, 'placementAssistance')}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group controlId="youWillLearn">
                                                            <Form.Label>{formLabels.you_will_learn}</Form.Label>
                                                            <ReactQuill
                                                                value={formData.youWillLearn}
                                                                onChange={(value) => handleRichTextChange(value, 'youWillLearn')}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    
        
        
                                                    <Col md={12}>
                                                        <Form.Group controlId="upcomingDemoDetails">
                                                            <Form.Label>{formLabels.upcoming_demo_details}</Form.Label>
                                                            <ReactQuill
                                                                value={formData.upcomingDemoDetails}
                                                                onChange={(value) => handleRichTextChange(value, 'upcomingDemoDetails')}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                
                                                    <Col md={12}>
                                                        <Form.Group controlId="whyThisCourse">
                                                            <Form.Label>{formLabels.why_this_course}</Form.Label>
                                                            <ReactQuill
                                                                value={formData.whyThisCourse}
                                                                onChange={(value) => handleRichTextChange(value, 'whyThisCourse')}
                                                            />
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md={12}>
                                                        <Form.Group controlId="courseDescription">
                                                            <Form.Label>{formLabels.course_description}</Form.Label>
                                                            <ReactQuill
                                                                value={formData.courseDescription}
                                                                onChange={(value) => handleRichTextChange(value, 'courseDescription')}
                                                            />
                                                        </Form.Group>
                                                    </Col>

                                                   
                                                </Row>

                                                <Button disabled={submitBtnDisable} className='mt-2' variant="primary" type="submit">
                                                    {id ? 'Update Placement' :  'Create Placement'}
                                                </Button>
                                            </Form>
                                            {/* ----------- */}
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};
export default Add_new_placement;