import React,{useState,useEffect} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
 
const TopBanner = () => {

  const [locationStateName, setLocationStateName] = useState([]);

  

  useEffect(() => {
      (async () => {
          try {
              const states_list = await axios.get("https://jobchatbox.com/api/home/api/get_jobs_location");
              if(states_list.data.status == 1){ 
                  setLocationStateName(states_list.data.data.stateid_and_name) 
                
              }
          } catch (error) { console.log(error);}
      })();
  }, []);
  const navigate = useNavigate();
  const redirectForm = (action) => {
    var skills = document.getElementById("jobsearch_form_skills").value;  
    var recruiter_by_location = document.getElementById("recruiter_by_location").value;  
    //if(skills){
      var location = document.getElementById("jobsearch_form_location").value;  
      (action == 'jobs') ?  navigate(`/jobs-search?skills=${skills}&location=${location}&recruiter_location=${recruiter_by_location}`) : navigate(`/recruiter-search?skills=${skills}&location=${location}&recruiter_location=${recruiter_by_location}`)
    //} else {
      //error_class
      //document.getElementById("jobsearch_form_skills").className = "form-input input-keysearch mr-10 error_class";  

    //}
  } 

    return(

<section className="section-box pt-120 pb-120 graybg mainbanner">
        <div className="banner-hero bannerhomepage">
          <div className="container banner-inner">
            <div className="row align-items-center">
              <div className="col-md-6">
                  <h1 className="heading-banner wow animate__animated animate__fadeInUp">
                  Job seeker and Recruiter <span class="clr-primary">Lets Connect – Job done!</span>
               </h1>
                  <div className="banner-description mt-20 wow animate__animated animate__fadeInUp" data-wow-delay=".1s">We have made it easy. Jobseekers connect with the Elite recruiters for FREE. Build rapport, develop you connection and let the recruiter be your PATH FINDER</div>
                  <div className="box-search-2">
              <div className="block-banner bannerform">
                <form action="/jobs" method="get" id="jobsearch_form">
                <div className="form-find mt-30 wow animate__animated animate__fadeIn d-flex" data-wow-delay=".2s">
                  
                    <input className="form-input input-keysearch mr-10" id="jobsearch_form_skills" type="text" name="skills" placeholder="Job Title" />
                    <div className="select2 banner_location">
                    <select className="form-input mr-10 select-active" id="jobsearch_form_location" name="location">
                      <option value="">Select</option>
                      {
                          locationStateName.map((item,index) => (
                              <option value={item.state_id}>{item.city_name}</option>
                          ))
                      }
                    </select>
                    </div>
                    <div className="box-industry">
                      <select className="form-input mr-10 select-active input-industry" id="recruiter_by_location" name="recruiter_by_location">
                        <option value="0" selected="selected">Recruiters by Location</option>
                        {
                            locationStateName.map((item,index) => (
                                <option value={item.state_id}>{item.city_name}</option>
                            ))
                        }
                      </select>
                    </div>
                    
                </div>
                  <div className="formbtns mt-10">
                    <button type="button"  onClick={(e) => redirectForm('jobs')}  className="btn btn-default btn-find font-sm mr-10">Search Job</button>
                    <button type="button"  onClick={(e) => redirectForm('recruiter')} className="btn btn-default btn-find font-sm">Search (Job + Recruter)</button>
                  </div>
                </form>
              </div>
            </div>

               
                  
                  
              </div>
              <div className=" col-md-6">
                  <img src="front/assets/imgs/bannerimglady.png" />
              </div>
            </div>
            
          </div>
        </div>
      </section>


        );
}

export default TopBanner;